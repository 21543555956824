import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';

import { get, split, isEmpty, trim } from 'lodash';
import { withStyles } from '@material-ui/core';
import Visible from '../Visible';
import FileFontLoadorFactory from '../../page/ContentColumns/viinimaa/FileFontLoadorFactory';

let HeadingFontFace = {};

const styles = () => HeadingFontFace;
function FontLoader({ classes, className, themeStylesData }) {
  const fontFamilyText = get(themeStylesData, 'fontFamilyText');
  const fontFamilyTitle = get(themeStylesData, 'fontFamilyTitle');

  // body font
  const bodyFontFamily = get(fontFamilyText, 'cssFontFamilyIdentifier');
  const bodyFontEmbedLink = get(fontFamilyText, 'fontEmbedLink.fontEmbedLink');
  const bodyFontFamilyLink = get(fontFamilyText, 'bodyFontFamily.fontFamilyLink'); // depricated
  const bodyFontFileUrl = get(fontFamilyText, 'fontFile.0.secure_url');
  const bodyFontLink = !isEmpty(bodyFontEmbedLink) ? bodyFontEmbedLink : bodyFontFamilyLink;

  const bodyFontFaceName = useMemo(() => {
    const mainFont = split(bodyFontFamily, ',')[0];
    return trim(mainFont, "'");
  }, [bodyFontFamily]);

  // heading font
  const headingFontFamily = get(fontFamilyTitle, 'cssFontFamilyIdentifier');
  const headingFontEmbedLink = get(fontFamilyTitle, 'fontEmbedLink.fontEmbedLink');
  const headingFontFamilyLink = get(fontFamilyTitle, 'bodyFontFamily.fontFamilyLink'); // depricated
  const headingFontFileUrl = get(fontFamilyTitle, 'fontFile.0.secure_url');
  const headingFontLink = !isEmpty(headingFontEmbedLink) ? headingFontEmbedLink : headingFontFamilyLink;

  const headingFontFaceName = useMemo(() => {
    const mainFont = split(headingFontFamily, ',')[0];
    return trim(mainFont, "'");
  }, [headingFontFamily]);

  HeadingFontFace = useMemo(() => {
    let _fontFace = {};
    if (isEmpty(headingFontFileUrl)) {
      _fontFace = {
        '@font-face': {
          fontFamily: headingFontFaceName,
          fontStyle: 'normal',
          fontWeight: '400',
          src: `url(${headingFontFileUrl})`,
        },
      };
    }

    return _fontFace;
  }, [headingFontFaceName, headingFontFileUrl]);

  if (isEmpty(themeStylesData)) {
    return null;
  }

  const BodyFileFontLoador = FileFontLoadorFactory(bodyFontFaceName, bodyFontFileUrl);
  const HeadingFileFontLoador = FileFontLoadorFactory(headingFontFaceName, headingFontFileUrl);

  return (
    <div className={clsx(classes.root, className)}>
      <Visible hidden={!isEmpty(bodyFontFileUrl) || isEmpty(bodyFontLink)}>
        <Helmet>
          <link href={bodyFontLink} rel="preload" as="font" crossOrigin="anonymous" />
        </Helmet>
      </Visible>

      <Visible hidden={!isEmpty(headingFontFileUrl) || isEmpty(headingFontLink)}>
        <Helmet>
          <link href={headingFontLink} rel="preload" as="font" crossOrigin="anonymous" />
        </Helmet>
      </Visible>

      <Visible hidden={isEmpty(bodyFontFileUrl)}>
        <BodyFileFontLoador />
      </Visible>
      <Visible hidden={isEmpty(headingFontFileUrl)}>
        <HeadingFileFontLoador />
      </Visible>
    </div>
  );
}
FontLoader.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  columnData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  themeStylesData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

FontLoader.defaultProps = {
  classes: {},
  className: null,
  columnData: null,
  themeStylesData: null,
};

export default withStyles(styles)(FontLoader);
