import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

function ShowAllButton({ classes, searchPage, searchTerm, tab, ...otherProps }) {
  const { t } = useTranslation();

  if (!searchTerm) return null;

  return (
    <Button
      href={`${searchPage}?q=${searchTerm}&tab=${tab}`}
      className={classes.root}
      variant="contained"
      size="small"
      {...otherProps}
      endIcon={<ArrowForwardIcon />}>
      {t('KlevuSearch.viewAllButton.label')}
    </Button>
  );
}

ShowAllButton.propTypes = {
  classes: PropTypes.object,
  searchPage: PropTypes.string,
  searchTerm: PropTypes.string,
  tab: PropTypes.oneOf(['content', 'products']),
};

ShowAllButton.defaultProps = {
  classes: {},
  searchPage: '',
  searchTerm: '',
  tab: 'products',
};

export default ShowAllButton;
