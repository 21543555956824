import React, { useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import { includes, isEqual, get, split } from 'lodash';

import MartiniIcon from '../../../../images/folkofolk/Martini.svg';
import MartiniIconWhite from '../../../../images/folkofolk/Martini-white.svg';

import WineIcon from '../../../../images/folkofolk/Wine.svg';
import WineIconWhite from '../../../../images/folkofolk/Wine-white.svg';
import { fofNavigationTitle, fofNavigationBreakpoint } from '../../../../constants';
import { useActiveNavigation } from '../../../context/ActiveNavigationContext';
import Link from '../../Link';
import { getWindowPathName } from '../../../../utils/windowUtils';

const styles = theme => ({
  root: {
    display: 'inline-flex',
    width: '50%',
    [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: {
      width: 'auto',
    },
  },
  link1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 25px',
    height: '45px',
    width: '100%',
    ...theme.typography.navigationLink,
    [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: {
      height: '80px',
      minWidth: '146px',
    },
  },

  activeLink1: {
    color: theme.palette.common.white,
  },
  spirits: {
    backgroundColor: theme.palette.common.lightBrown,
  },
  activeSpirits: {
    backgroundColor: theme.palette.common.darkBrown,
    color: theme.palette.common.white,
    textDecoration: 'underline',
  },
  wines: {
    backgroundColor: theme.palette.common.lighterRed,
  },
  activeWines: {
    backgroundColor: theme.palette.common.darkPurple,
    color: theme.palette.common.white,
    textDecoration: 'underline',
  },
  image: { marginRight: '8px' },
});
function NavIcon({ classes, title, to, data }) {
  const wines = get(fofNavigationTitle, 'wines');
  const spirits = get(fofNavigationTitle, 'spirits');
  const { activeNav, updateActiveNav } = useActiveNavigation();
  const location = getWindowPathName();

  const [active, setActive] = React.useState(false);
  const [activeNavData, setActiveNavData] = React.useState({});

  useEffect(() => {
    setActiveNavData(activeNav);
  }, [activeNav]);

  useEffect(() => {
    if (!isEqual(get(activeNavData, 'fullPath'), to)) {
      setActive(false);
    }
    if (isEqual(get(activeNavData, 'fullPath'), to)) {
      setActive(true);
    }
    if (includes(location, to) || includes(location, split(to, '-', 1))) {
      setActive(true);
      if (!isEqual(get(activeNavData, 'fullPath'), to)) {
        updateActiveNav(data);
      }
    }
  }, [activeNavData, location]);

  const handleOnLinkClick = useCallback(() => {
    updateActiveNav(data);
  }, [data, updateActiveNav]);

  return (
    <div className={classes.root}>
      {isEqual(get(data, 'title'), spirits) && (
        <Link
          to={to}
          className={clsx(classes.link1, classes.spirits, active ? classes.activeSpirits : null)}
          activeClassName={classes.activeLink1}
          partiallyActive
          onClick={handleOnLinkClick}>
          <img
            src={active ? MartiniIconWhite : MartiniIcon}
            alt="icon"
            width="24"
            height="24"
            className={classes.image}
            loading="lazy"
          />
          {title}
        </Link>
      )}
      {isEqual(get(data, 'title'), wines) && (
        <Link
          to={to}
          className={clsx(classes.link1, classes.wines, active ? classes.activeWines : null)}
          activeClassName={classes.activeLink1}
          partiallyActive
          onClick={handleOnLinkClick}>
          <img
            src={active ? WineIconWhite : WineIcon}
            alt="icon"
            width="24"
            height="24"
            className={classes.image}
            loading="lazy"
          />
          {title}
        </Link>
      )}
    </div>
  );
}

NavIcon.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.object,
};

NavIcon.defaultProps = {
  classes: {},
  className: null,
  to: null,
  title: null,
  data: {},
};
export default memo(withStyles(styles)(NavIcon));
