import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEqual, size } from 'lodash';
import { withStyles } from '@material-ui/core';

import CloudinaryImage from '../../../shared/CloudinaryImage';
import { breakPoints } from '../../../../constants';

const styles = () => ({
  root: {
    position: 'absolute',
    zIndex: '-1',

    height: ({ columnData }) => {
      const backgroundImagePosition = get(columnData, 'backgroundImagePosition');
      if (
        isEqual(backgroundImagePosition, 'Middle') ||
        isEqual(backgroundImagePosition, 'Top') ||
        isEqual(backgroundImagePosition, 'Bottom')
      ) {
        return null;
      }
      return '100%';
    },

    top: ({ columnData }) => {
      const backgroundImagePosition = get(columnData, 'backgroundImagePosition');
      if (isEqual(backgroundImagePosition, 'Middle')) {
        return '50%';
      }
      if (isEqual(backgroundImagePosition, 'Bottom')) {
        return 'auto';
      }
      return '0';
    },
    bottom: ({ columnData }) => {
      const backgroundImagePosition = get(columnData, 'backgroundImagePosition');

      if (isEqual(backgroundImagePosition, 'Bottom')) {
        return '0';
      }
      return 'auto';
    },
    left: ({ columnData }) => {
      const backgroundImagePosition = get(columnData, 'backgroundImagePosition');
      if (isEqual(backgroundImagePosition, 'Middle')) {
        return '50%';
      }
      return '0';
    },

    transform: ({ columnData }) => {
      const backgroundImagePosition = get(columnData, 'backgroundImagePosition');
      if (isEqual(backgroundImagePosition, 'Middle')) {
        return 'translate(-50%, -50%)';
      }
      return 'none';
    },
  },
  cloudinaryImage: {},
});

function BackgroundImage({ classes, className, rowData, columnData }) {
  const altText = get(columnData, 'backgroundImage.imageAltText');
  const titleText = get(columnData, 'backgroundImage.imageTitleText');
  const publicId = get(columnData, 'backgroundImage.cloudinaryImage.0.public_id');

  const transformation = useMemo(() => {
    const totalColumns = size(get(rowData, 'columns'));
    if (totalColumns < 2) {
      return 'ColumnImage-LG-SM';
    }
    return 'ColumnImage-MD-SM';
  }, [rowData]);

  return (
    <CloudinaryImage
      className={clsx(classes.root, className)}
      classes={{ root: classes.cloudinaryImage }}
      publicId={publicId}
      transformation={transformation}
      alt={altText}
      title={titleText}
      width={breakPoints.lg}
      height="480"
    />
  );
}

BackgroundImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  columnData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rowData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

BackgroundImage.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  columnData: null,
  rowData: null,
};

export default withStyles(styles)(BackgroundImage);
