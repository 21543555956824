import React from 'react';
import PropTypes from 'prop-types';

import { isEqual } from 'lodash';
import { withStyles } from '@material-ui/core';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import CommonHeroImageModule from './common';

const styles = theme => ({
  descriptionLeft: {
    marginTop: '280px',
    backgroundColor: 'rgba(237, 245, 248, 0.64)',
    maxHeight: '600px',
    [theme.breakpoints.up('md')]: {
      width: '750px',
    },
  },
  descriptionRoot: {
    top: 'auto',
    left: '0',
    right: '0',
    transform: 'translate(-0%, -100%)',
  },
});

function HeroImageModule({ classes, ...props }) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <CommonHeroImageModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <CommonHeroImageModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Blossa)) {
    return <CommonHeroImageModule {...props} descriptionPosition="center" />;
  }

  if (isEqual(siteId, siteIds.Skagerrak)) {
    return <CommonHeroImageModule {...props} classes={{ descriptionLeft: classes.descriptionLeft }} />;
  }
  if (isEqual(siteId, siteIds.LinieAquavit)) {
    return (
      <CommonHeroImageModule
        {...props}
        descriptionPosition="center"
        classes={{ descriptionRoot: classes.descriptionRoot }}
        video={{ loop: true, maxHeight: '750px' }}
      />
    );
  }

  if (brandSite) {
    return <CommonHeroImageModule {...props} />;
  }

  return null;
}
HeroImageModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
};

HeroImageModule.defaultProps = {
  classes: {},
  className: null,
};

export default withStyles(styles)(HeroImageModule);
// export default HeroImageModule;
