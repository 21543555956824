import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { Container, Typography, withStyles } from '@material-ui/core';

// eslint-disable-next-line import/no-cycle
import RichText from '../../../shared/RichText';
import CloudinaryImage from '../../../shared/CloudinaryImage';
import ProductMonopolyPrice from '../../../shared/Products/ProductMonopolyPrice';
import ProductLabels from '../../../shared/Products/ProductLabels';
import CustobarSubscriptionForm from '../../../page/Campaign/CampaignOrderForm/CustobarSubscriptionForm';

const styles = theme => ({
  root: {
    zIndex: '1',
    position: 'relative',
    overflow: 'hidden',
    paddingTop: ({ pageData }) => {
      const newsletter = get(pageData, 'newsletter');
      return isEmpty(newsletter) ? 0 : theme.spacing(6);
    },
  },
  container: {},
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(5),
    },
  },
  imageSide: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '100%',
    maxWidth: '100%',
    padding: theme.spacing(4, 3),

    [theme.breakpoints.up('md')]: {
      flexBasis: '50%',
      maxWidth: '50%',
    },
  },
  productImage: {
    maxHeight: '450px',
  },
  title: {
    textTransform: 'uppercase',
    paddingTop: theme.spacing(3),
  },
  priceWrapper: {
    backgroundColor: '#f0f0f0',
    padding: '6px 12px',
    borderRadius: '64px',
  },
  price: {
    fontSize: '13px',
    color: '#333333',
  },
  informations: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexBasis: '1000%',
    maxWidth: '100%',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      flexBasis: '50%',
      maxWidth: '50%',
      padding: theme.spacing(8),
    },
  },
  description: {
    fontSize: '0.8rem',
  },
  disclaimer: {
    fontSize: '0.8rem !important',
    fontStyle: 'italic',
    lineHeight: 1.3,
    marginTop: theme.spacing(2),
  },
  hyperlink: {
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.custobarSubscriptionForm.background,
    },
  },
});

function OrderFormModule({ classes, className, data }) {
  const { t } = useTranslation();

  const product = get(data, 'orderFormProduct.relatedProduct');

  const name = get(data, 'orderFormProduct.productName');
  const title = get(data, 'title', name);
  const disclaimer = get(data, 'disclaimer');

  const productImage = get(data, 'orderFormProduct.relatedProduct.heroImage');

  const monopolyUrl = get(data, 'orderFormProduct.relatedProduct.attributes.monopoly_url.value', null);

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="xl" disableGutters className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.imageSide}>
            <ProductLabels productData={product} />
            <CloudinaryImage
              publicId={get(productImage, 'cloudinaryImage.0.public_id')}
              alt={name}
              width="auto"
              height="380"
            />
            <Typography variant="h1" align="center" className={clsx(classes.title, classes.fontColor)}>
              {title}
            </Typography>
            <div className={classes.priceWrapper}>
              <ProductMonopolyPrice productData={product} classes={{ price: classes.price }} />
            </div>
          </div>

          <div className={classes.informations}>
            <div id="order_form">
              {!isEmpty(monopolyUrl) && (
                <div className={classes.order}>
                  <Typography variant="h3" className={classes.title}>
                    {t('CampaignPage.OrderForm.Order.Title')}
                  </Typography>
                  <Typography className={classes.description}>{t('CampaignPage.OrderForm.Order.Text')}</Typography>
                  <CustobarSubscriptionForm className={classes.form} pageData={data} />
                </div>
              )}
              {isEmpty(monopolyUrl) && (
                <div className={classes.subscribe}>
                  <Typography variant="h3" className={classes.title}>
                    {t('CampaignPage.OrderForm.Subscribe.Title')}
                  </Typography>
                  <Typography className={classes.description}>{t('CampaignPage.OrderForm.Subscribe.Text')}</Typography>
                  <CustobarSubscriptionForm pageData={data} className={classes.form} />
                </div>
              )}
              <RichText
                textData={disclaimer}
                classes={{
                  paragraph: classes.disclaimer,
                  hyperlink: classes.hyperlink,
                }}
                target="_blank"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

OrderFormModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

OrderFormModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(OrderFormModule);
