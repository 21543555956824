import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import SkagerrakInstagramCard from './skagerrak';

function InstagramCard(props) {
  if (isEqual(siteId, siteIds.Skagerrak)) {
    return <SkagerrakInstagramCard {...props} />;
  }

  return null;
}

export default InstagramCard;
