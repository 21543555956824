import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEqual } from 'lodash';
import { withStyles } from '@material-ui/core';

import ProductSearch from './ProductSearch';
import KlevuContentSearch from './KlevuContentSearch';

const styles = () => ({
  root: {},
});

function SearchModule({ classes, className, data, pageData }) {
  const searchType = get(data, 'searchType.title');

  return (
    <div className={clsx(classes.root, className)}>
      {isEqual(searchType, 'Product search') && <ProductSearch data={data} pageData={pageData} />}
      {!isEqual(searchType, 'Product search') && <KlevuContentSearch data={data} pageData={pageData} />}
    </div>
  );
}

SearchModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

SearchModule.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  data: null,
};

export default withStyles(styles)(SearchModule);
