import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaHamburgerMenu from './viinimaa';
import GammeldanskHamburgerMenu from './gammeldansk';

function HamburgerMenu(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaHamburgerMenu {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaHamburgerMenu {...props} />;
  }
  if (isEqual(siteId, siteIds.Gammeldansk)) {
    return <GammeldanskHamburgerMenu {...props} />;
  }
  if (brandSite) {
    return <ViinimaaHamburgerMenu {...props} />;
  }

  return null;
}

export default HamburgerMenu;
