import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { Divider, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Link from '../../navigation/Link';
import Visible from '../Visible';
import ThumbnailImage from '../ThumbnailImage';
import Categories from '../../page/Categories';
import { contentfulContentTypes } from '../../../constants';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    wordBreak: 'break-word',
    hyphens: 'auto',
    padding: theme.spacing(3, 1.5),
    flexBasis: ({ elementsPerRowMobile, gap }) => {
      if (isEmpty(gap)) {
        return `calc(100%  / ${elementsPerRowMobile})`;
      }
      const totalGap = (elementsPerRowMobile - 1) * gap;
      return `calc((100% - ${totalGap}px) / ${elementsPerRowMobile})`;
    },
    maxWidth: ({ elementsPerRowMobile, gap }) => {
      if (isEmpty(gap)) {
        return `calc(100%  / ${elementsPerRowMobile})`;
      }
      const totalGap = (elementsPerRowMobile - 1) * gap;
      return `calc((100% - ${totalGap}px) / ${elementsPerRowMobile})`;
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: ({ elementsPerRowTablet, gap }) => {
        if (isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowTablet})`;
        }
        const totalGap = (elementsPerRowTablet - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowTablet})`;
      },
      maxWidth: ({ elementsPerRowTablet, gap }) => {
        if (isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowTablet})`;
        }
        const totalGap = (elementsPerRowTablet - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowTablet})`;
      },
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: ({ elementsPerRowDesktop, gap }) => {
        if (isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowDesktop})`;
        }
        const totalGap = (elementsPerRowDesktop - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowDesktop})`;
      },
      maxWidth: ({ elementsPerRowDesktop, gap }) => {
        if (isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowDesktop})`;
        }
        const totalGap = (elementsPerRowDesktop - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowDesktop})`;
      },
      padding: theme.spacing(3, 1.5),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageWrapper: {
    color: theme.palette.text.primary,
    position: 'relative',
  },
  content: {},

  title: {
    color: theme.palette.text.primary,
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3, 0, 2, 0),
    },
  },
  image: {
    height: 'auto',
  },
  divider: {
    backgroundColor: theme.palette.common.lightGray,
    marginBottom: '6px',
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  shortDescription: {
    fontSize: '1rem',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  linkButton: {
    fontSize: '1rem',
    display: 'flex',

    justifyContent: 'flex-start',
  },
  imageTitle: {},
  listItemText: {},
  dividerSmall: {},
  listItem: {},
  imagesOnlyTitle: {},
  imageFull: {},
});

function CardWithText({
  classes,
  className,
  richText: RichText,
  image,
  title,
  categories,
  description,
  fullPath,
  pageType,
  transformation,
  width,
  height,
  hideImage,
  hideTitle,
  hideDivider,
  hideCategories,
  roundImage,
}) {
  const { t } = useTranslation();

  let linkButtonText = t('ContentList.ReadMoreLinkButton.AdvancedPage');
  if (pageType === contentfulContentTypes.ArticlePage) {
    linkButtonText = t('ContentList.ReadMoreLinkButton.ArticlePage');
  }
  if (pageType === contentfulContentTypes.RecipePage) {
    linkButtonText = t('ContentList.ReadMoreLinkButton.RecipePage');
  }
  if (pageType === contentfulContentTypes.ProductReference) {
    linkButtonText = t('ContentList.ReadMoreLinkButton.ProductPage');
  }
  if (pageType === contentfulContentTypes.Brand) {
    linkButtonText = t('ContentList.ReadMoreLinkButton.BrandPage');
  }
  const linkStyles = isEmpty(fullPath) ? { textDecoration: 'none' } : {};

  if (transformation === 'CardImage-LG-LG') {
    height = 500;
    width = 500;
  } else if (transformation === 'CardImage-Mobile') {
    height = 445;
    width = 600;
  }

  const addImagePad = isEqual(pageType, contentfulContentTypes.ProductReference);

  const variants = [
    {
      transformation: '',
      parameters: {
        c: addImagePad ? 'pad' : 'fill',
        h: height,
      },
      width,
    },
  ];

  return (
    <article className={clsx(classes.root, className)}>
      <header to={fullPath} className={classes.header}>
        <Link to={fullPath} className={classes.imageWrapper} style={linkStyles}>
          <Visible hidden={hideImage}>
            <ThumbnailImage
              className={classes.image}
              data={image}
              width={width}
              height={height}
              circle={roundImage}
              variants={variants}
            />
          </Visible>
        </Link>
      </header>
      <div className={classes.content}>
        <Visible hidden={hideTitle}>
          <Link to={fullPath}>
            <Typography variant="h3" className={classes.title} align="left">
              {title}
            </Typography>
          </Link>
        </Visible>

        <Visible hidden={hideDivider}>
          <Divider className={classes.divider} />
        </Visible>
        <Visible hidden={hideCategories}>
          <Categories categoriesData={categories} className={classes.tags} />
        </Visible>
        <Visible visible={isObject(description) && !isEmpty(description)}>
          {description && <RichText textData={description} className={classes.shortDescription} />}
        </Visible>
        <Visible visible={isString(description) && !isEmpty(description)}>
          <Typography variant="body2" className={classes.shortDescription}>
            {description}
          </Typography>
        </Visible>
        <Visible hidden={isEmpty(fullPath)}>
          <Link to={fullPath} className={classes.linkButton}>
            {linkButtonText} »
          </Link>
        </Visible>
      </div>
    </article>
  );
}

CardWithText.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  richText: PropTypes.any,
  image: PropTypes.object,
  title: PropTypes.string,
  categories: PropTypes.array,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullPath: PropTypes.string,
  pageType: PropTypes.string,
  transformation: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  elementsPerRowDesktop: PropTypes.number,
  elementsPerRowTablet: PropTypes.number,
  elementsPerRowMobile: PropTypes.number,
  hideImage: PropTypes.bool,
  hideTitle: PropTypes.bool,
  hideDivider: PropTypes.bool,
  hideCategories: PropTypes.bool,
  roundImage: PropTypes.bool,
  gap: PropTypes.number,
};

CardWithText.defaultProps = {
  classes: {},
  className: null,
  image: null,
  richText: '',
  title: null,
  categories: null,
  description: '',
  fullPath: null,
  pageType: null,
  transformation: 'CardImage-MD-MD',
  width: '350',
  height: '260',
  hideImage: false,
  hideTitle: false,
  hideDivider: false,
  hideCategories: false,
  elementsPerRowDesktop: 4,
  elementsPerRowTablet: 2,
  elementsPerRowMobile: 1,
  roundImage: false,
  gap: null,
};

export default withStyles(styles)(CardWithText);
