import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isNil } from 'lodash';
import { Typography, withStyles } from '@material-ui/core';

import ProductDescriptionCard from '../../../shared/Products/ProductDescriptionCard';

const styles = theme => ({
  root: {
    marginBottom: '40px',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    '@media print': {
      marginBottom: '0',
      pageBreakInside: 'avoid',
    },
  },
});

function FeaturedProductModule({ classes, className, data, richText: RichText }) {
  const featuredProduct = get(data, 'featuredProduct');
  const relatedProduct = get(data, 'featuredProduct.relatedProduct');

  if (isNil(featuredProduct)) {
    return null;
  }

  let description = null;
  if (!isNil(get(data, 'description'))) {
    description = <RichText textData={get(data, 'description')} />;
  } else if (!isNil(get(featuredProduct, 'description'))) {
    description = <RichText textData={get(featuredProduct, 'description')} />;
  } else {
    description = <Typography>{get(relatedProduct, 'description')}</Typography>;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <ProductDescriptionCard productData={relatedProduct} description={description} />
    </div>
  );
}

FeaturedProductModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.object,
};

FeaturedProductModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
};

export default withStyles(styles)(FeaturedProductModule);
