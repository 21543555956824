import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { filter, get, isEmpty, isEqual, map } from 'lodash';

import { withStyles } from '@material-ui/core';

import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Visible from '../../../shared/Visible';
import Link from '../../Link';
import Logo from '../../Logo';

import '../../../page/Content';

const styles = theme => ({
  root: {
    display: 'flex',
    gridColumnEnd: 3,
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
  },
  list1: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '40%',
  },
  list1Left: {
    justifyContent: 'flex-end',
  },
  list1Right: {
    justifyContent: 'flex-start',
  },
  listItem1: {
    padding: '0 25px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:hover > div': {
      visibility: 'visible',
      transitionDelay: '0s',
    },
  },
  link1: {
    ...theme.typography.navigationLink,
    display: 'flex',
    fontSize: '1rem',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.header.navigation.hoverText,
    },
  },
  activeLink1: {
    borderBottom: theme.palette.header.navigation.activeLinkBorder,
    color: theme.palette.header.navigation.activeLink,
  },
  subMenu: {
    marginTop: '26px',
    visibility: 'hidden',
    transition: '0.2s 0.2s',
    position: 'absolute',
    top: '20%',
    left: 0,
    right: 0,
    minWidth: '270px',
    padding: '8px 0',
    background: theme.palette.header.navigation.subMenuBackground,
  },
  list2: {
    color: theme.palette.header.navigation.subMenuBulletPoints,
    columnCount: '1',
    listStyle: 'none',
    fontSize: '1rem',
    padding: 0,
  },
  listItem2: {},
  link2: {
    ...theme.typography.navigationLink,
    fontSize: '1rem',
    color: theme.palette.header.text,
    display: 'flex',
    padding: '6px 24px',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.header.navigation.hoverText,
    },
  },
  activeLink2: {
    color: theme.palette.header.navigation.activeLink,
  },
  logo: {
    padding: '10px 50px',
  },
});

function getNavigationElementTitle(navigationElement) {
  let navigationElementTitle = get(navigationElement, 'pageReference.menuTitle');
  if (isEmpty(navigationElementTitle)) {
    navigationElementTitle = get(navigationElement, 'pageReference.title');
  }
  if (isEmpty(navigationElementTitle)) {
    navigationElementTitle = get(navigationElement, 'title');
  }
  return navigationElementTitle;
}

function getNavigationElementChildren(navigationElements, navigationElementId) {
  return filter(navigationElements, {
    parentNavigationElement: { id: navigationElementId },
  });
}

function TopNavigation({ classes, className, pageData }) {
  const navigationElements = get(pageData, 'mainTheme.navigation.flattenedNavigationElements');
  const navigationElements1 = filter(navigationElements, { parentNavigationElement: null });

  const header = get(pageData, 'mainTheme.header.headerElements');
  const showLogo = !!header.includes('Show logo');

  const getNavigationLinks = side => {
    const links = [];

    let maxIndex = navigationElements1.length;
    let index = Math.floor(navigationElements1.length / 2);

    if (side === 'left') {
      maxIndex = Math.ceil(navigationElements1.length / 2);
      index = 0;
    }

    for (let i = index; i < maxIndex; i++) {
      const navigationElement1 = navigationElements1[i];
      const navigationElementId1 = get(navigationElement1, 'id');
      const navigationElementFullPath1 = get(navigationElement1, 'pageReference.fullPath');
      const navigationElementChildren1 = getNavigationElementChildren(navigationElements, navigationElementId1);
      const navigationElementTitle1 = getNavigationElementTitle(navigationElement1);

      links.push(
        <li className={classes.listItem1} key={navigationElementId1}>
          <Link
            to={navigationElementFullPath1}
            className={classes.link1}
            activeClassName={classes.activeLink1}
            partiallyActive>
            {navigationElementTitle1}
            {!isEmpty(navigationElementChildren1) && <KeyboardArrowDown />}
          </Link>
          <Visible hidden={isEmpty(navigationElementChildren1)}>
            <div className={classes.subMenu}>
              <ul className={classes.list2}>
                {map(navigationElementChildren1, navigationElement2 => {
                  const navigationElementId2 = get(navigationElement2, 'id');
                  const navigationElementFullPath2 = get(navigationElement2, 'pageReference.fullPath');
                  const navigationElementTitle2 = getNavigationElementTitle(navigationElement2);

                  return (
                    <li key={navigationElementId2} className={classes.listItem2}>
                      <Link
                        to={navigationElementFullPath2}
                        className={classes.link2}
                        activeClassName={classes.activeLink2}>
                        {navigationElementTitle2}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Visible>
        </li>,
      );
    }

    return links;
  };

  return (
    <div className={clsx(classes.root, className)}>
      <ul className={clsx(classes.list1, classes.list1Left)}>{getNavigationLinks('left')}</ul>
      {showLogo && <Logo pageData={pageData} className={classes.logo} />}
      <ul className={clsx(classes.list1, classes.list1Right)}>{getNavigationLinks('right')}</ul>
    </div>
  );
}

TopNavigation.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

TopNavigation.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(TopNavigation);
