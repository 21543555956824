import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaContentListModule from './viinimaa';

function ContentListModule(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaContentListModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaContentListModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return <ViinimaaContentListModule gap="40" elementsPerRowDesktop={3} {...props} />;
  }
  if (isEqual(siteId, siteIds.OpAnderson)) {
    return <ViinimaaContentListModule elementsPerRowMobile={2} arrowButtonVariant="contained" {...props} />;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <ViinimaaContentListModule elementsPerRowMobile={2} arrowButtonVariant="contained" {...props} />;
  }
  if (isEqual(siteId, siteIds.WineworldFinland)) {
    return <ViinimaaContentListModule elementsPerRowMobile={2} arrowButtonVariant="contained" {...props} />;
  }
  if (brandSite) {
    return <ViinimaaContentListModule {...props} />;
  }

  return null;
}

export default ContentListModule;
