import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaContentListElement from './viinimaa';
import WennercoContentListElement from './wennerco';
import SkagerrakContentListElement from './skagerrak';
import OpAndersonContentListElement from './opAnderson';
import KoskenkorvaContentListElement from './koskenkorva';
import LinieAquavitContentListElement from './linieAquavit';

function ContentListElement(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaContentListElement transformation="CardImage-Mobile" {...props} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <ViinimaaContentListElement {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaContentListElement transformation="CardImage-LG-LG" {...props} />;
  }
  if (isEqual(siteId, siteIds.Skagerrak)) {
    return <SkagerrakContentListElement transformation="CardImage-LG-LG" hideDivider hideCategories {...props} />;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return <WennercoContentListElement {...props} />;
  }
  if (isEqual(siteId, siteIds.OpAnderson)) {
    return <OpAndersonContentListElement {...props} />;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <KoskenkorvaContentListElement {...props} />;
  }
  if (isEqual(siteId, siteIds.LinieAquavit)) {
    return <LinieAquavitContentListElement {...props} />;
  }
  if (brandSite) {
    return <ViinimaaContentListElement transformation="CardImage-LG-LG" {...props} />;
  }

  return null;
}

export default ContentListElement;
