import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, isEqual } from 'lodash';
import { withStyles } from '@material-ui/core';

import ListElementCard from '../../../shared/ListElementCard';
import ProductListElement from './ProductListElement';
import { contentfulContentTypes } from '../../../../constants';

const styles = () => ({
  root: {},
});

function ContentListElement({ classes, className, data, richText: RichText, ...otherProps }) {
  const fullPath = get(data, 'pageReference.fullPath');
  const categories = get(data, 'pageReference.categories');
  const pageType = get(data, 'pageReference.__typename');
  let description = get(data, 'description');
  if (isEmpty(description)) {
    description = get(data, 'pageReference.shortDescription');
  }

  let image = get(data, 'image');
  if (isEmpty(image)) {
    image = get(data, 'pageReference.images.0');
  }
  if (isEmpty(image)) {
    image = get(data, 'pageReference.heroImage');
  }

  let title = get(data, 'title');
  if (isEmpty(title)) {
    title = get(data, 'pageReference.name');
  }
  if (isEmpty(title)) {
    title = get(data, 'pageReference.title');
  }
  if (isEmpty(title)) {
    title = get(data, 'pageReference.menuTitle');
  }

  if (isEqual(pageType, contentfulContentTypes.ProductReference)) {
    return <ProductListElement data={data} richText={RichText} />;
  }
  return (
    <ListElementCard
      {...otherProps}
      className={clsx(classes.root, className)}
      richText={RichText}
      image={image}
      title={title}
      categories={categories}
      description={description}
      fullPath={fullPath}
      pageType={pageType}
    />
  );
}

ContentListElement.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  richText: PropTypes.object,
  slider: PropTypes.bool,
};

ContentListElement.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
  slider: false,
};

export default withStyles(styles)(ContentListElement);
