import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core';

import Visible from '../../../shared/Visible';

import HeroImage from './HeroImage';
import HeroVideo from '../../../page/HeroImage/HeroVideo';
import HeroDescription from '../HeroDescription';
import PhotoCredit from '../../../shared/PhotoCredit';

const styles = () => ({
  root: {
    display: 'block',
    marginBottom: '0',
    width: '100%',
    position: 'relative',
  },
  descriptionRoot: {},
  descriptionLeft: {},
});

function HeroImageModule({ classes, className, data, video, ...otherProps }) {
  const description = get(data, 'description');
  const heroVideo = get(data, 'video');
  const photoCred = get(data, 'photoCredit', null);
  const photoCredColor = get(data, 'photoCreditColor', null);

  return (
    <div className={clsx(classes.root, className)}>
      {isEmpty(heroVideo) ? (
        <HeroImage data={data} {...otherProps} />
      ) : (
        <HeroVideo data={data} loop={get(video, 'loop')} maxHeight={get(video, 'maxHeight')} />
      )}
      <Visible hidden={isEmpty(get(description, 'raw'))}>
        <HeroDescription
          data={data}
          classes={{ descriptionLeft: classes.descriptionLeft, root: classes.descriptionRoot }}
          {...otherProps}
          className={classes.heroDescription}
        />
      </Visible>
      <PhotoCredit photoCredit={photoCred} color={photoCredColor} />
    </div>
  );
}

HeroImageModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  video: PropTypes.object,
};

HeroImageModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  video: {},
};

export default withStyles(styles)(HeroImageModule);
