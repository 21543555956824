import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEqual } from 'lodash';
import { Drawer, IconButton, Toolbar, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { siteMaintenanceMode } from '../../../../constants';
import { useDrawer } from '../../../context/DrawerContext';

import Logo from '../../Logo';
import NavigationElements from '../../NavigationElements';
import LanguageSwitcher from '../../LanguageSwitcher';
import Subscription from '../../Subscription';
import B2BLink from '../../B2BLink';

const styles = theme => ({
  root: {},
  toolbar: {
    padding: '12px 12px 12px 20px',
    justifyContent: 'space-between',
    minHeight: '60px',
    borderBottom: 'solid',
    borderBottomWidth: '3px',
    borderBottomColor: theme.palette.mobileNavigation.logoBarBottomBorder,
  },
  iconButton: {
    padding: '8px',
    backgroundColor: theme.palette.mobileNavigation.xIconBackground,
    borderRadius: '0',
  },
  icon: {
    fontSize: '1.3rem',
    color: theme.palette.mobileNavigation.icon,
  },
  content: {
    overflow: 'auto',
  },
  SubscriptionLink: {
    height: 'auto',
    marginTop: theme.spacing(2),
  },
  links: {
    height: 'auto',
  },
  b2bLink: {
    display: 'inline',
    [theme.breakpoints.up('500')]: {
      display: 'none',
    },
  },
  languageSwitcher: {
    padding: theme.spacing(2),
  },
});

function MobileNavigation({ classes, className, pageData }) {
  const { closeDrawer, isDrawerOpen } = useDrawer();
  const drawerOpen = isDrawerOpen('MobileNavigationDrawer');

  const maintenanceMode = get(pageData, 'mainTheme.maintenanceMode');
  const isMaintenanceModeActive = isEqual(maintenanceMode, siteMaintenanceMode.ActiveMode);

  const navigationElements = useMemo(
    () => get(pageData, 'mainTheme.navigation.flattenedNavigationElements'),
    [pageData],
  );

  const handleNavigationElementClick = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const handleLogoClick = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  return (
    <Drawer anchor="right" className={clsx(classes.root, className)} open={drawerOpen} onClose={closeDrawer}>
      <Toolbar className={classes.toolbar}>
        <Logo logoHeight="36" onLogoClick={handleLogoClick} pageData={pageData} />
        <IconButton aria-label="Close" className={classes.iconButton} onClick={closeDrawer}>
          <Close className={classes.icon} />
        </IconButton>
      </Toolbar>
      <div className={classes.content}>
        {!isMaintenanceModeActive && (
          <>
            <NavigationElements data={navigationElements} onNavigationElementClick={handleNavigationElementClick} />
            <Subscription classes={{ SubscriptionLink: classes.SubscriptionLink }} />
          </>
        )}
        <B2BLink classes={{ link: classes.links }} className={classes.b2bLink} />
        <LanguageSwitcher pageData={pageData} className={classes.languageSwitcher} />
      </div>
    </Drawer>
  );
}

MobileNavigation.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.object,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

MobileNavigation.defaultProps = {
  classes: {},
  className: {},
  pageData: null,
};

export default withStyles(styles)(MobileNavigation);
