import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import {
  ImageListItemBar,
  withStyles,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close, GetApp, Link as LinkIcon } from '@material-ui/icons';
import ThumbnailImage from '../../../shared/ThumbnailImage';
import CloudinaryImage from '../../../shared/CloudinaryImage';
import Link from '../../../navigation/Link';

const styles = () => ({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  image: {
    borderRadius: '0',
  },
  button: {
    padding: '0',
  },
  icon: {
    padding: '10px',
  },
  dialogContent: {
    overflow: 'hidden',
    padding: '0px',
    '&:first-child': {
      padding: '0px',
    },
  },
  caption: {
    fontWeight: 'bold',
    lineHeight: 1.1,
    paddingTop: '1rem',
  },
});

function Media({ classes, data, isMobile, usePreview, transformation }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const galleryImageId = get(data, 'contentful_id');
  const caption = get(data, 'imageCaption');
  const imageTitleText = get(data, 'imageTitleText');

  // const titleText = get(data, 'imageTitleText');
  const imageLink = get(data, 'imageLink');
  const openLinkTo = get(data, 'openLinkTo');

  const altText = get(data, 'imageAltText');
  const publicId = get(data, 'cloudinaryImage.0.public_id');
  const imgurl = get(data, 'cloudinaryImage.0.secure_url');

  const variants = useMemo(
    () => [
      {
        transformation: 'FullWidthImage-XS-IN',
        width: 430,
      },
      {
        transformation: 'FullWidthImage-SM-IN',
        width: 768,
      },
      {
        transformation: 'FullWidthImage-MD-IN',
        width: 1024,
      },
      {
        transformation: 'FullWidthImage-LG-IN',
        width: 1440,
      },
    ],
    [],
  );
  usePreview = false;
  if (!usePreview) {
    if (isEmpty(imageLink)) {
      return (
        <div className={classes.container}>
          <ThumbnailImage data={data} className={classes.image} transformation={transformation} />
          {caption && (
            <Typography variant="body1" className={classes.caption}>
              {caption}
            </Typography>
          )}
        </div>
      );
    }

    return (
      <Link to={imageLink} target={!openLinkTo ? '_blank' : ''}>
        <ThumbnailImage data={data} className={classes.image} transformation={transformation} />
        {caption && (
          <Typography variant="body2" className={classes.caption}>
            {caption}
          </Typography>
        )}
      </Link>
    );
  }
  return (
    <>
      <Button component="button" variant="text" onClick={handleOpen} className={classes.button}>
        <ThumbnailImage data={data} className={classes.image} transformation={transformation} />
        {caption && <ImageListItemBar className={classes.tileBar} subtitle={caption} />}
      </Button>
      <MuiDialog open={open} onClose={handleClose} aria-labelledby={galleryImageId} maxWidth={isMobile ? 'sm' : 'lg'}>
        <DialogContent className={classes.dialogContent}>
          <CloudinaryImage
            id={galleryImageId}
            publicId={publicId}
            variants={variants}
            alt={altText}
            title={imageTitleText}
            width="auto"
            height={isMobile ? '600' : '800'}
          />
        </DialogContent>
        <DialogActions>
          {imageLink && (
            <IconButton href={imageLink} target={!openLinkTo ? '_blank' : ''} className={classes.icon}>
              <LinkIcon />
            </IconButton>
          )}
          <IconButton href={imgurl} target="_blank" className={classes.icon} download={imgurl}>
            <GetApp />
          </IconButton>
          <IconButton onClick={handleClose} className={classes.icon}>
            <Close />
          </IconButton>
        </DialogActions>
      </MuiDialog>
    </>
  );
}

Media.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isMobile: PropTypes.bool,
  usePreview: PropTypes.bool,
  transformation: PropTypes.string,
};

Media.defaultProps = {
  classes: {},
  className: null,
  data: null,
  isMobile: true,
  usePreview: true,
  transformation: 'CardImage-LG-LG',
};

export default withStyles(styles)(Media);
