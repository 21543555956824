import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core';

import CloudinaryImage from '../../CloudinaryImage';

const styles = () => ({
  root: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '-1',
    height: '100%',
  },
});

function BackgroundImage({ classes, className, altText, publicId }) {
  const variants = useMemo(
    () => [
      {
        transformation: 'RowFullWidthImage-XS-XS',
        width: 430,
      },
      {
        transformation: 'RowFullWidthImage-SM-XS',
        width: 768,
      },
      {
        transformation: 'RowFullWidthImage-MD-XS',
        width: 1024,
      },
      {
        transformation: 'RowFullWidthImage-LG-LG',
        width: 1440,
      },
      {
        transformation: 'RowFullWidthImage-XL-LG',
        width: 2048,
      },
      {
        transformation: '',
        width: 3000,
        parameters: {
          c: 'fill',
        },
      },
    ],
    [],
  );
  if (isEmpty(publicId)) {
    return null;
  }
  return (
    <CloudinaryImage
      className={clsx(classes.root, className)}
      publicId={publicId}
      variants={variants}
      alt={altText}
      width="100%"
      height="100%"
    />
  );
}

BackgroundImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  altText: PropTypes.string,
  publicId: PropTypes.string,
};

BackgroundImage.defaultProps = {
  classes: {},
  className: null,
  altText: null,
  publicId: null,
};

export default withStyles(styles)(BackgroundImage);
