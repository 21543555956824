import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import split from 'lodash/split';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import join from 'lodash/join';
import get from 'lodash/get';
import { Typography, withStyles } from '@material-ui/core';

import Link from '../../../navigation/Link';

const styles = theme => ({
  root: {
    maxWidth: '100%',
    flexBasis: '100%',
    wordBreak: 'break-word',
    hyphens: 'auto',
    padding: theme.spacing(3, 2),
    '&:hover #_instaImage': {
      transform: ' scale(1.15)',
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: ({ elementsPerRowTablet }) => {
        return `calc(100% / ${elementsPerRowTablet})`;
      },
      maxWidth: ({ elementsPerRowTablet }) => {
        return `calc(100% / ${elementsPerRowTablet})`;
      },
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: ({ elementsPerRowDesktop }) => {
        return `calc(100% / ${elementsPerRowDesktop})`;
      },
      maxWidth: ({ elementsPerRowDesktop }) => {
        return `calc(100% / ${elementsPerRowDesktop})`;
      },
      padding: theme.spacing(3, 1.5),
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  header: {
    display: 'block',
    maxWidth: '100%',
  },
  imageWrapper: {
    overflow: 'hidden',
  },

  image: {
    display: 'block',
    maxWidth: '100%',
    objectFit: 'cover',
    transition: 'all 2s ease',
  },

  content: {
    ...theme.listElementCard.content,
    padding: '16px',
  },

  shortDescription: {
    fontSize: '1rem',
  },
});

function InstagramCard({ classes, className, data, width, height }) {
  const id = get(data, 'id');
  const caption = get(data, 'caption');
  const src = get(data, 'localFile.childImageSharp.fixed.src');

  const filteredCaption = useMemo(() => {
    const wordList = split(caption, ' ');
    return join(
      filter(wordList, item => !includes(item, '#')),
      ' ',
    );
  }, [caption]);
  return (
    <article className={clsx(classes.root, className)}>
      <Link to={`https://www.instagram.com/p/${id}`} className={classes.link} target="_blank">
        <header className={classes.header}>
          <div className={classes.imageWrapper}>
            <img
              className={classes.image}
              src={src}
              id="_instaImage"
              alt="Instagram"
              width={width}
              height={height}
              loading="lazy"
            />
          </div>
        </header>
        <div className={classes.content}>
          <Typography variant="body2" className={classes.shortDescription}>
            {filteredCaption}
          </Typography>
        </div>
      </Link>
    </article>
  );
}

InstagramCard.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  width: PropTypes.string,
  height: PropTypes.string,
  elementsPerRowTablet: PropTypes.number,
  elementsPerRowDesktop: PropTypes.number,
};

InstagramCard.defaultProps = {
  classes: {},
  className: null,
  data: null,
  width: '600',
  height: 'auto',
  elementsPerRowTablet: 2,
  elementsPerRowDesktop: 4,
};

export default withStyles(styles)(InstagramCard);
