import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import CardWithText from '../CardWithText';
import CardWithImageOnly from '../CardWithImageOnly';

const styles = theme => ({
  root: {},
  header: {},
  content: { ...theme.listElementCard.content },
  imageWrapper: {},
  imageFull: {},
  title: {
    color: theme.palette.link.link,
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  imagesOnlyTitle: {},
  listItem: {},
  listItemText: {},
  imageTitle: {},
  divider: {},
  dividerSmall: {},
  tags: {},
  shortDescription: {},
  linkButton: { color: theme.palette.link.link },
});

function ContentListElement({ classes, hideText, ...otherProps }) {
  if (hideText) {
    return <CardWithImageOnly {...otherProps} />;
  }

  return (
    <CardWithText
      classes={{ content: classes.content, root: classes.root, title: classes.title, linkButton: classes.linkButton }}
      {...otherProps}
    />
  );
}
ContentListElement.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  hideText: PropTypes.bool,
};

ContentListElement.defaultProps = {
  classes: {},
  className: null,
  hideText: false,
};
export default withStyles(styles)(ContentListElement);
