import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { List, ListItem, ListItemText, Divider, Typography, withStyles } from '@material-ui/core';

import Link from '../../navigation/Link';
import ThumbnailImage from '../ThumbnailImage';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    wordBreak: 'break-word',
    hyphens: 'auto',
    padding: `0 3px 0 0`,
    '&:last-of-type': {
      paddingRight: 0,
    },
    flexBasis: ({ elementsPerRowMobile }) => {
      return `calc(100% / ${elementsPerRowMobile})`;
    },
    maxWidth: ({ elementsPerRowMobile }) => {
      return `calc(100% / ${elementsPerRowMobile})`;
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: ({ elementsPerRowTablet }) => {
        return `calc(100% / ${elementsPerRowTablet})`;
      },
      maxWidth: ({ elementsPerRowTablet }) => {
        return `calc(100% / ${elementsPerRowTablet})`;
      },
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: ({ elementsPerRowDesktop }) => {
        return `calc(100% / ${elementsPerRowDesktop})`;
      },
      maxWidth: ({ elementsPerRowDesktop }) => {
        return `calc(100% / ${elementsPerRowDesktop})`;
      },
    },
  },

  imageWrapper: {
    color: theme.palette.text.primary,
    position: 'relative',
  },
  imageFull: {
    filter: 'brightness(70%)',
    transition: 'all 500ms ease',
    '&:hover': {
      filter: 'brightness(100%)',
      transition: 'all 500ms ease',
    },
  },
  title: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3, 0, 2, 0),
    },
  },
  imagesOnlyTitle: {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'center',
  },
  listItem: {
    padding: 0,
    textAlign: 'center',
  },
  listItemText: {
    fontSize: '0.6875rem',
    fontWeight: 'bold',
    letterSpacing: '1.1px',
    textTransform: 'uppercase',
  },
  imageTitle: {
    bottom: '10%',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    left: 0,
    position: 'absolute',
    right: 0,
  },
  image: {},

  dividerSmall: {
    backgroundColor: theme.palette.common.red,
    height: '2px',
    margin: '6px auto',
    width: '32px',
  },
  header: {},
  shortDescription: {},
  linkButton: {},
  divider: {},
  tags: {},
});

function CardWithImageOnly({ classes, className, image, title, categories, fullPath, transformation, width, height }) {
  return (
    <article className={clsx(classes.root, className)}>
      <Link to={fullPath} className={classes.imageWrapper}>
        <ThumbnailImage
          className={classes.imageFull}
          data={image}
          transformation={transformation}
          width={width}
          height={height}
          circle={false}
        />
        <div className={classes.imageTitle}>
          <Typography variant="h3" className={clsx(classes.title, classes.imagesOnlyTitle)}>
            {title}
          </Typography>
          {categories && (
            <>
              <Divider className={classes.dividerSmall} />

              <List dense>
                {categories.map(category => {
                  return (
                    <ListItem className={classes.listItem} key={`-${category.title}`}>
                      <ListItemText
                        primary={
                          <Typography type="body2" className={clsx(classes.listItemText, classes.imagesOnlyTitle)}>
                            {category.title}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </div>
      </Link>
    </article>
  );
}

CardWithImageOnly.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  image: PropTypes.object,
  title: PropTypes.string,
  categories: PropTypes.array,
  fullPath: PropTypes.string,
  transformation: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  elementsPerRowDesktop: PropTypes.number,
  elementsPerRowTablet: PropTypes.number,
  elementsPerRowMobile: PropTypes.number,
};

CardWithImageOnly.defaultProps = {
  classes: {},
  className: null,
  image: null,
  title: null,
  categories: null,
  fullPath: null,
  transformation: 'CardImage-MD-MD',
  width: '600',
  height: 'auto',
  elementsPerRowDesktop: 4,
  elementsPerRowTablet: 2,
  elementsPerRowMobile: 1,
};

export default withStyles(styles)(CardWithImageOnly);
