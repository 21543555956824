/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import { withStyles, Typography, Button } from '@material-ui/core';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import InstagramIcon from '@material-ui/icons/Instagram';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InstagramCard from '../../../shared/InstagramCard';

const styles = theme => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    marginBottom: theme.spacing(5),
    fontSize: '2rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.6rem',
    },
  },
  icon: {
    margin: '10px 24px 0 0',
  },
  cardWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    gap: ({ gap }) => {
      if (gap) {
        return `${gap}px`;
      }
      return '0';
    },
  },

  button: {
    margin: theme.spacing(5),
    fontFamily: 'sweet-sans-pro',
    padding: '12px 14px',
  },
});

function InstagramFeedModule({ classes, className, data, ...otherProps }) {
  const { t } = useTranslation();
  const feedTitle = get(data, 'feedTitle');
  const instagramNodes = get(data, 'instagramNodes');
  const instagramAccountUrl = get(data, 'instagramAccountUrl');

  if (isEmpty(instagramNodes)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      {!!feedTitle && (
        <div className={classes.center}>
          <InstagramIcon fontSize="large" className={classes.icon} />
          <Typography variant="h3" className={classes.title}>
            {feedTitle}
          </Typography>
        </div>
      )}

      <div className={classes.cardWrapper}>
        {map(instagramNodes, node => {
          const timestamp = get(data, 'timestamp');
          return <InstagramCard data={node} {...otherProps} key={timestamp} />;
        })}
      </div>
      {!!instagramAccountUrl && (
        <div className={clsx(classes.center, classes.buttonWrapper)}>
          <Button
            to={instagramAccountUrl}
            target="_blank"
            size="small"
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<ArrowForwardIcon />}>
            {t('instagramFeedModule.label')}
          </Button>
        </div>
      )}
    </div>
  );
}

InstagramFeedModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

InstagramFeedModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(InstagramFeedModule);
