import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.lightGray,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontFamily: theme.typography.h1.fontFamily,
    textAlign: 'center',
  },
});

function ImageMissing({ classes, height }) {
  const { t } = useTranslation();

  if (!height) return null;

  return (
    <div className={classes.root} style={{ height, width: height }}>
      <Typography variant="body2" className={classes.text}>
        {t('KlevuSearch.imageMissing')}
      </Typography>
    </div>
  );
}

ImageMissing.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number,
};

ImageMissing.defaultProps = {
  classes: {},
  height: 70,
};

export default withStyles(styles)(ImageMissing);
