import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { Typography, withStyles } from '@material-ui/core';
import Visible from '../Visible';

const styles = theme => ({
  root: {
    position: 'relative',
  },

  photoCred: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    margin: theme.spacing(2),
    color: ({ color }) => {
      if (color) {
        return color;
      }
      return 'inherit';
    },
    fontWeight: 'bold',
  },
});

function PhotoCredit({ classes, className, photoCredit }) {
  if (!photoCredit) {
    return null;
  }

  return (
    <Visible visible={!isEmpty(photoCredit)}>
      <div className={clsx(className, classes.root)}>
        <Typography variant="body1" className={classes.photoCred}>
          {photoCredit}
        </Typography>
      </div>
    </Visible>
  );
}

PhotoCredit.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  photoCredit: PropTypes.string,
  color: PropTypes.string,
};

PhotoCredit.defaultProps = {
  classes: {},
  className: null,
  photoCredit: null,
  color: 'inherit',
};

export default memo(withStyles(styles)(PhotoCredit));
