import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map } from 'lodash';
import { withStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

const styles = theme => ({
  tableContainer: {
    borderRadius: theme.table.borderRadius,
    border: theme.table.border,
    boxShadow: 'none',
    marginBottom: '40px',
  },
  tableCell: {
    margin: '0',
    '& > p': {
      margin: '0',
    },
  },
  align: {
    textAlign: ({ evenColumnTextAlign }) => {
      return evenColumnTextAlign;
    },
  },
});

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.table.head.backgroundColor,
    color: theme.table.head.color,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.table.row.backgroundColor ? theme.table.row.backgroundColor : null,
    borderBottom: 'solid 1px red',
    textAlign: 'right',
    '&:nth-of-type(even)': {
      backgroundColor: theme.table.row.even.backgroundColor,
    },
  },
}))(TableRow);

function TableModule({ classes, className, data }) {
  const title = get(data, 'title');
  const columns = get(data, 'table.columns');
  const rows = get(data, 'table.rows');
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={clsx(classes.root, className)} aria-label={title}>
        <TableHead>
          <TableRow>
            {map(columns, (column, i) => (
              <StyledTableCell
                className={(clsx(classes.tableCell), i % 2 === 0 ? classes.align : null)}
                key={get(column, 'key')}
                colSpan={get(column, 'span')}
                dangerouslySetInnerHTML={{ __html: get(column, 'text') }}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(rows, row => (
            <StyledTableRow key={get(row, 'key')}>
              {map(get(row, 'columns'), (column, i) => (
                <TableCell
                  className={(clsx(classes.tableCell), i % 2 === 0 ? classes.align : null)}
                  key={get(column, 'key')}
                  colSpan={get(column, 'span')}
                  dangerouslySetInnerHTML={{ __html: get(column, 'text') }}
                />
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  evenColumnTextAlign: PropTypes.string,
};

TableModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  evenColumnTextAlign: null,
};

export default withStyles(styles)(TableModule);
