/* eslint-disable react/self-closing-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

function IdentifierModule({ data }) {
  const identifier = get(data, 'identifier');
  return <div id={identifier}></div>;
}

IdentifierModule.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

IdentifierModule.defaultProps = {
  data: null,
};

export default IdentifierModule;
