import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import CardWithText from '../opAnderson/CardWithText';
import CardWithImageOnly from '../CardWithImageOnly';

const styles = theme => ({
  shortDescription: {
    textAlign: 'center',
    marginBottom: '24px',
  },
  linkButton: {
    fontSize: '16px',
  },
  content: {
    padding: '0',
    backgroundColor: 'transparent',
  },
  title: {
    fontSize: '19px',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
  },
});

function ContentListElement({ classes, hideText, ...otherProps }) {
  if (hideText) {
    return <CardWithImageOnly {...otherProps} />;
  }

  return (
    <CardWithText
      hideCategories
      hideDivider
      hideDescription={false}
      startIcon={null}
      transformation="ContactPersonImage-MD-MD"
      height="auto"
      classes={{
        shortDescription: classes.shortDescription,
        linkButton: classes.linkButton,
        content: classes.content,
        title: classes.title,
      }}
      {...otherProps}
    />
  );
}
ContentListElement.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  hideText: PropTypes.bool,
};

ContentListElement.defaultProps = {
  classes: {},
  className: null,
  hideText: false,
};
export default withStyles(styles)(ContentListElement);
