import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, round, split, includes, isEqual, toNumber } from 'lodash';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Label from '../Label';

const styles = () => ({
  root: {},
  label: {
    margin: '8px 24px',
    position: 'absolute',

    top: ({ labelPosition }) => {
      if (labelPosition === 'bottom' || labelPosition === 'bottom_left') {
        return 'auto';
      }
      return '0';
    },

    bottom: ({ labelPosition }) => {
      if (labelPosition === 'bottom' || labelPosition === 'bottom_left') {
        return '0';
      }
      return 'auto';
    },
    right: ({ labelPosition }) => {
      if (labelPosition === 'bottom_left') {
        return 'auto';
      }
      return '0';
    },

    left: ({ labelPosition }) => {
      if (labelPosition === 'bottom_left') {
        return '0';
      }
      return 'auto';
    },
  },
  icon: {
    margin: '8px 0px',
  },
  saleLabel: {
    fontSize: '1.3rem',
  },
  newLabel: {},
});

function calculateSalePercentage(normPrice, reducedPrice) {
  if (isEmpty(normPrice.toString()) || isEmpty(reducedPrice)) {
    return 0;
  }
  const norm = toNumber(normPrice);
  const reduced = toNumber(reducedPrice);
  return norm > reduced ? round(((norm - reduced) * 100) / norm, 0) : 0;
}

function ProductLabels({ className, classes, productData, ...otherProps }) {
  const { t } = useTranslation();

  const normPrice = get(productData, 'price');
  const reducedPrice = get(productData, 'attributes.monopoly_reduced_price.value');

  const salePercentage = useMemo(() => {
    return calculateSalePercentage(normPrice, reducedPrice);
  }, [normPrice, reducedPrice]);

  const labelsData = get(productData, 'attributes.viinimaa_labels.value');
  let labelList = [labelsData];
  if (includes(labelsData, ',')) {
    labelList = split(labelsData, ',');
  }

  const newLabel = includes(labelList, 'Uutuus');

  return (
    <div className={clsx(className, classes.label)}>
      {newLabel && (
        <Label
          text={t('ProductLabels.new')}
          type="circle"
          className={classes.icon}
          classes={{ text: classes.newLabel }}
          {...otherProps}
        />
      )}
      {!isEqual(salePercentage, 0) && (
        <Label
          text={`-${salePercentage}%`}
          saleLabel
          type="circle"
          className={classes.icon}
          classes={{ text: classes.saleLabel }}
          {...otherProps}
        />
      )}
    </div>
  );
}

ProductLabels.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
  labelPosition: PropTypes.oneOf(['top', 'bottom', 'bottom_left']),
};

ProductLabels.defaultProps = {
  classes: {},
  className: null,
  productData: {},
  labelPosition: 'top',
};

export default withStyles(styles)(ProductLabels);
