import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaTopNavigation from './viinimaa';
import FolkofolkTopNavigation from './folkofolk';
import BlossaTopNavigation from './blossa';
import LinieAquavitTopNavigation from './linieAquavit';

function TopNavigation(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaTopNavigation {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkTopNavigation {...props} />;
  }
  if (isEqual(siteId, siteIds.Blossa)) {
    return <BlossaTopNavigation {...props} />;
  }
  if (isEqual(siteId, siteIds.LinieAquavit)) {
    return <LinieAquavitTopNavigation {...props} />;
  }

  if (brandSite) {
    return <ViinimaaTopNavigation {...props} />;
  }

  return null;
}

export default TopNavigation;
