import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get } from 'lodash';

import { ListItemIcon, Menu, MenuItem, withStyles } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import Button from '../../../shared/Button';

import { getWindowHref } from '../../../../utils/windowUtils';

const styles = theme => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
  icon: {
    fontSize: '2rem',
  },
  listItemIcon: {
    color: theme.palette.text.primary,
    minWidth: '32px',
  },
  menuItem: {
    fontSize: '.875rem',
  },
});

function ShareButtons({ classes, className, pageData }) {
  const { t } = useTranslation();
  const shareText = get(pageData, 'title');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const shareServices = [
    {
      shareLink: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(getWindowHref())}`,
      name: 'Facebook',
    },
    {
      shareLink: `https://TwItTeR.CoM/intent/tweet?text=${encodeURIComponent(shareText)}&amp;url=${encodeURIComponent(
        getWindowHref(),
      )}`,
      name: 'Twitter',
    },
  ];

  const pageId = get(pageData, 'id');
  const searchPageId = get(pageData, 'searchPage.id');

  if (pageId === searchPageId) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Button
        id="share-button"
        className={classes.shareButton}
        variant="outlined"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={React.createElement(Icons['Share'])}>
        {t('ShareButtons.share')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'share-button',
        }}>
        {shareServices.map(shareService => (
          <MenuItem
            key={shareService.name}
            component="a"
            href={shareService.shareLink}
            onClick={handleClose}
            rel="nofollow"
            target="_blank"
            className={classes.menuItem}>
            <ListItemIcon className={classes.listItemIcon}>
              {React.createElement(Icons[shareService.name])}
            </ListItemIcon>
            {t(`ShareButtons.${shareService.name}`)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

ShareButtons.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ShareButtons.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(ShareButtons);
