import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import LinieAquavitSocialMediaElementModule from './linieAquavit';
import ViinimaaSocialMediaElementModule from './viinimaa';

function SocialMediaElementModule(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaSocialMediaElementModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaSocialMediaElementModule {...props} />;
  }
  if (isEqual(siteId, siteIds.LinieAquavit)) {
    return <LinieAquavitSocialMediaElementModule {...props} />;
  }
  if (brandSite) {
    return <ViinimaaSocialMediaElementModule {...props} />;
  }

  return null;
}

export default SocialMediaElementModule;
