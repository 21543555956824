import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core';
import { get } from 'lodash';

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  richText: {
    fontSize: '1.1rem !important',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.3rem !important',
    },
  },
});

function LeadTextModule({ classes, className, data, richText: RichText }) {
  const description = get(data, 'description');
  return (
    <div className={clsx(classes.root, className)}>
      <RichText textData={description} className={classes.richText} />
    </div>
  );
}

LeadTextModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.object,
};

LeadTextModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
};

export default withStyles(styles)(LeadTextModule);
