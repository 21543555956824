import { useLocation } from '@reach/router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguageFromPathname } from '../../utils/langUtils';

const useInitI18next = () => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const languageCode = getLanguageFromPathname(pathname);

  useEffect(() => {
    i18n.changeLanguage(languageCode);
  }, [i18n, languageCode]);
};

export default useInitI18next;
