import { withStyles } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';

export default function FileFontLoadorFactory(fontName, fontFileURL) {
  const styles = () => ({
    '@font-face': {
      fontFamily: fontName,
      fontStyle: 'normal',
      fontWeight: '400',
      src: `url(${fontFileURL})`,
    },
  });

  function FileFontLoador() {
    return null;
  }
  if (isEmpty(fontFileURL)) {
    return FileFontLoador;
  }
  return withStyles(styles)(FileFontLoador);
}
