import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core';

import CloudinaryImage from '../../CloudinaryImage';

const styles = () => ({
  root: {
    display: 'block',
    maxWidth: '100%',
  },
  circle: {
    borderRadius: '50%',
  },
});

function ThumbnailImage({ classes, className, data, transformation, width, height, circle, variants }) {
  const altText = get(data, 'imageAltText');
  const titleText = get(data, 'imageTitleText');
  const publicId = get(data, 'cloudinaryImage.0.public_id');

  if (isEmpty(publicId)) {
    return null;
  }
  return (
    <CloudinaryImage
      className={clsx(classes.root, className, circle ? classes.circle : null)}
      publicId={publicId}
      transformation={transformation}
      alt={altText}
      title={titleText}
      width={width}
      height={height}
      variants={variants}
    />
  );
}

ThumbnailImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  transformation: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  circle: PropTypes.bool,
  variants: PropTypes.array,
};

ThumbnailImage.defaultProps = {
  classes: {},
  className: null,
  data: null,
  transformation: '',
  width: 'auto',
  height: 'auto',
  circle: false,
  variants: [],
};

export default withStyles(styles)(ThumbnailImage);
