import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { isEqual } from 'lodash';
import { withStyles, Typography } from '@material-ui/core';

import StarIconDark from '../../../../images/folkofolk/labelDark.svg';
import SaleIconLight from '../../../../images/folkofolk/labelLight.svg';

const styles = theme => ({
  root: {},
  circleLabel: {
    backgroundColor: ({ saleLabel }) => {
      if (saleLabel) {
        return theme.palette.common.green;
      }
      return theme.palette.common.red;
    },
    borderRadius: '50%',
    width: ({ size }) => {
      return `${size}px`;
    },
    height: ({ size }) => {
      return `${size}px`;
    },
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  starLabel: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: ({ size }) => {
      return `${size}px`;
    },
    height: ({ size }) => {
      return `${size}px`;
    },
  },

  icon: {
    position: 'absolute',
    transform: ({ size }) => {
      if (size === '60') {
        return 'scale(1.1)';
      }
      return 'scale(1.35)';
    },
  },

  text: {
    zIndex: '1',
    fontWeight: 'bold',
    color: ({ saleLabel }) => {
      if (saleLabel) {
        return theme.palette.text.primary;
      }
      return theme.palette.common.white;
    },
  },
});

function ProductLabels({ className, classes, text, saleLabel, type }) {
  return (
    <div className={clsx(className)}>
      {isEqual(type, 'circle') && (
        <div className={classes.circleLabel}>
          <Typography className={classes.text}> {text}</Typography>
        </div>
      )}
      {isEqual(type, 'star') && (
        <div className={classes.starLabel}>
          <Typography className={classes.text}> {text}</Typography>
          <img src={saleLabel ? SaleIconLight : StarIconDark} alt="logo" className={classes.icon} loading="lazy" />
        </div>
      )}
    </div>
  );
}

ProductLabels.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  saleLabel: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
};

ProductLabels.defaultProps = {
  classes: {},
  className: null,
  saleLabel: false,
  text: null,
  type: 'circle',
  size: '80',
};

export default withStyles(styles)(ProductLabels);
