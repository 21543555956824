import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

import { List, ListItem, ListItemText, Divider, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Link from '../../../navigation/Link';
import Visible from '../../Visible';
import ThumbnailImage from '../../ThumbnailImage';
import Categories from '../../../page/Categories';
import { contentfulContentTypes, magentoContentTypes } from '../../../../constants';

const styles = theme => ({
  root: {
    backgroundColor: ({ hideText }) => {
      if (hideText) {
        return 'transparent';
      }
      return '#1e1f20';
    },
    color: '#fffef8',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: '100%',
    wordBreak: 'break-word',
    hyphens: 'auto',
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up('sm')]: {
      flexBasis: ({ elementsPerRowTablet, gap, hideText }) => {
        if (hideText || isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowTablet})`;
        }
        const totalGap = (elementsPerRowTablet - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowTablet})`;
      },
      maxWidth: ({ elementsPerRowTablet, gap, hideText }) => {
        if (hideText || isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowTablet})`;
        }
        const totalGap = (elementsPerRowTablet - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowTablet})`;
      },
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: ({ elementsPerRowDesktop, gap, hideText }) => {
        if (hideText || isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowDesktop})`;
        }
        const totalGap = (elementsPerRowDesktop - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowDesktop})`;
      },
      maxWidth: ({ elementsPerRowDesktop, gap, hideText }) => {
        if (hideText || isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowDesktop})`;
        }
        const totalGap = (elementsPerRowDesktop - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowDesktop})`;
      },
      padding: theme.spacing(3, 3),
    },
  },
  textColor: {
    color: '#fffef8',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageWrapper: {
    color: theme.palette.text.primary,
    position: 'relative',
  },
  imageFull: {
    filter: 'brightness(70%)',
    transition: 'all 500ms ease',
    '&:hover': {
      filter: 'brightness(100%)',
      transition: 'all 500ms ease',
    },
  },
  title: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3, 0, 3, 0),
    },
  },
  imagesOnlyTitle: {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'center',
  },
  listItem: {
    padding: 0,
    textAlign: 'center',
  },
  listItemText: {
    fontSize: '0.6875rem',
    fontWeight: 'bold',
    letterSpacing: '1.1px',
    textTransform: 'uppercase',
  },
  imageTitle: {
    bottom: '10%',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    left: 0,
    position: 'absolute',
    right: 0,
  },
  divider: {
    backgroundColor: '#fffef8',
    margin: '25px 0 8px 0',
  },
  dividerSmall: {
    backgroundColor: theme.palette.common.red,
    height: '2px',
    margin: '6px auto',
    width: '32px',
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  shortDescription: {
    fontSize: '1rem',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  linkButton: {
    fontSize: '1rem',
    display: 'flex',

    justifyContent: 'flex-start',
  },
  contentWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& a': {
      color: '#fffef8',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
});

function ContentListElement({
  classes,
  className,
  richText: RichText,
  image,
  title,
  categories,
  description,
  fullPath,
  pageType,
  transformation,
  width,
  height,
  hideText,
}) {
  const { t } = useTranslation();

  let linkButtonText = t('ContentList.ReadMoreLinkButton.AdvancedPage');
  if (pageType === contentfulContentTypes.ArticlePage) {
    linkButtonText = t('ContentList.ReadMoreLinkButton.ArticlePage');
  }
  if (pageType === contentfulContentTypes.RecipePage) {
    linkButtonText = t('ContentList.ReadMoreLinkButton.RecipePage');
  }
  if (pageType === magentoContentTypes.Product) {
    linkButtonText = t('ContentList.ReadMoreLinkButton.ProductPage');
  }
  if (pageType === contentfulContentTypes.Brand) {
    linkButtonText = t('ContentList.ReadMoreLinkButton.BrandPage');
  }
  if (hideText) {
    return (
      <article className={clsx(classes.root, className)}>
        <Link to={fullPath} className={classes.imageWrapper}>
          <ThumbnailImage
            className={classes.imageFull}
            data={image}
            transformation={transformation}
            width={width}
            height={height}
            circle={false}
          />
          <div className={classes.imageTitle}>
            <Typography variant="h3" className={clsx(classes.title, classes.imagesOnlyTitle, classes.textColor)}>
              {title}
            </Typography>
            {categories && (
              <>
                <List dense>
                  {categories.map(category => {
                    return (
                      <ListItem className={classes.listItem} key={`__${category.title}`}>
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              className={clsx(classes.listItemText, classes.imagesOnlyTitle, classes.textColor)}>
                              {category.title}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
                <Divider className={classes.dividerSmall} />
              </>
            )}
          </div>
        </Link>
      </article>
    );
  }

  return (
    <article className={clsx(classes.root, className)}>
      <header to={fullPath} className={classes.header}>
        <Link to={fullPath} className={classes.imageWrapper}>
          <ThumbnailImage
            className={classes.image}
            data={image}
            transformation={transformation}
            width={width}
            height={height}
            circle={false}
          />
          <Typography variant="h3" className={clsx(classes.title, classes.textColor)} align="left">
            {title}
          </Typography>
        </Link>
      </header>
      {!hideText && (
        <div className={classes.contentWrapper}>
          <div className={classes.textWrapper}>
            <Categories
              categoriesData={categories}
              classes={{ category: classes.textColor }}
              className={classes.tags}
            />
            <Visible visible={isObject(description) && !isEmpty(description)}>
              {description && (
                <RichText
                  textData={description}
                  classes={{ paragraph: classes.textColor }}
                  className={clsx(classes.shortDescription, classes.textColor)}
                />
              )}
            </Visible>
            <Visible visible={isString(description) && !isEmpty(description)}>
              <Typography variant="body2" className={clsx(classes.shortDescription, classes.textColor)}>
                {description}
              </Typography>
            </Visible>
            <Visible hidden={isEmpty(fullPath)}>
              <Link to={fullPath} className={clsx(classes.linkButton, classes.textColor)}>
                {linkButtonText} »
              </Link>
            </Visible>
          </div>
          <Divider className={classes.divider} />
        </div>
      )}
    </article>
  );
}

ContentListElement.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  richText: PropTypes.any,
  image: PropTypes.object,
  title: PropTypes.string,
  categories: PropTypes.array,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullPath: PropTypes.string,
  pageType: PropTypes.string,
  transformation: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  hideText: PropTypes.bool,
  elementsPerRowDesktop: PropTypes.number,
  elementsPerRowTablet: PropTypes.number,
};

ContentListElement.defaultProps = {
  classes: {},
  className: null,
  richText: '',
  image: null,
  title: null,
  categories: null,
  description: '',
  fullPath: null,
  pageType: null,
  transformation: 'CardImage-LG-LG',
  width: '100%',
  height: '150',
  hideText: false,
  elementsPerRowDesktop: 3,
  elementsPerRowTablet: 3,
};

export default withStyles(styles)(ContentListElement);
