import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import LinieAquavitMediaGalleryModule from './linieAquavit';
import ViinimaaMediaGalleryModule from './viinimaa';
import WennercoMediaGalleryModule from './wennerco';

function MediaGalleryModule(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaMediaGalleryModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaMediaGalleryModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return <WennercoMediaGalleryModule {...props} />;
  }
  if (isEqual(siteId, siteIds.WineworldFinland)) {
    return <WennercoMediaGalleryModule {...props} />;
  }
  if (isEqual(siteId, siteIds.LinieAquavit)) {
    return <LinieAquavitMediaGalleryModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Socialwines)) {
    return <WennercoMediaGalleryModule {...props} />;
  }
  if (brandSite) {
    return <ViinimaaMediaGalleryModule {...props} />;
  }

  return null;
}

export default MediaGalleryModule;
