import React from 'react';
import PropTypes from 'prop-types';

import { Chip, Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

function SearchSuggestions({ classes, isObject, searchPage, suggestions, title }) {
  if (suggestions.length === 0) return null;

  return (
    <>
      <Typography variant="h3">{title}</Typography>

      {suggestions.map((suggestion, index) => {
        let suggestionTerm;
        let key = index;
        if (isObject) {
          key = suggestion.timestamp;
          suggestionTerm = suggestion.term;
        } else {
          suggestionTerm = suggestion.replace(/(<([^>]+)>)/gi, '');
        }

        const suggestionUrl = suggestionTerm.replace(' ', '+');

        return (
          <Chip
            key={key}
            label={suggestionTerm}
            component="a"
            href={`${searchPage}?q=${suggestionUrl}`}
            clickable
            className={classes.root}
          />
        );
      })}
    </>
  );
}

SearchSuggestions.propTypes = {
  classes: PropTypes.object,
  isObject: PropTypes.bool,
  searchPage: PropTypes.string,
  suggestions: PropTypes.array,
  title: PropTypes.string,
};

SearchSuggestions.defaultProps = {
  classes: {},
  isObject: false,
  searchPage: '',
  suggestions: [],
  title: '',
};

export default withStyles(styles)(SearchSuggestions);
