import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { withStyles, Input, Typography, FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  dash: {
    width: '40px',
    textAlign: 'center',
  },
  formControl: {
    marginBottom: '0',
  },
  min: {
    width: '80px',
  },
  max: {
    width: '80px',
  },
});

let timeoutId;

function FilterInputRange({ classes, className, name, value, onChange }) {
  const { t } = useTranslation();

  const [minValue, setMinValue] = useState(get(value, '0', ''));
  const [maxValue, setMaxValue] = useState(get(value, '1', ''));

  useEffect(() => {
    setMinValue(get(value, '0', ''));
    setMaxValue(get(value, '1', ''));
  }, [value]);

  const handleMinChange = useCallback(
    event => {
      const nextMinValue = get(event, 'target.value');
      setMinValue(nextMinValue);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        onChange({ target: { name, value: [nextMinValue, maxValue] } });
      }, 1000);
    },
    [name, maxValue, onChange],
  );

  const handleMaxChange = useCallback(
    event => {
      const nextMaxValue = get(event, 'target.value');
      setMaxValue(nextMaxValue);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        onChange({ target: { name, value: [minValue, nextMaxValue] } });
      }, 1000);
    },
    [name, minValue, onChange],
  );

  return (
    <div className={clsx(classes.root, className)}>
      <FormControl className={classes.formControl}>
        <Input
          id={`${name}-min`}
          name={`${name}-min`}
          placeholder={t('FilterInputRange.inputs.min.placeholder')}
          className={classes.min}
          value={minValue}
          inputProps={{ min: 0, max: 500, step: 1, type: 'number' }}
          onChange={handleMinChange}
        />
      </FormControl>
      <Typography className={classes.dash}>-</Typography>
      <FormControl className={classes.formControl}>
        <Input
          id={`${name}-max`}
          name={`${name}-max`}
          placeholder={t('FilterInputRange.inputs.max.placeholder')}
          className={classes.max}
          value={maxValue}
          inputProps={{ min: 0, max: 500, step: 1, type: 'number' }}
          onChange={handleMaxChange}
        />
      </FormControl>
    </div>
  );
}

FilterInputRange.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  options: PropTypes.array,
};

FilterInputRange.defaultProps = {
  classes: {},
  className: null,
  name: null,
  value: [],
  onChange: () => {},
  multiple: false,
  options: [],
};

export default withStyles(styles)(FilterInputRange);
