import React from 'react';
import PropTypes from 'prop-types';

import Pagination from '@material-ui/lab/Pagination';
import { Box } from '@material-ui/core';

function SearchPagination({ currentPage, justify, location, onChangeHandler, pageCount }) {
  if (!pageCount || pageCount <= 1) return null;

  return (
    <Box
      display="flex"
      justifyContent={justify}
      alignItems="center"
      paddingTop={location === 'bottom' ? 6 : 0}
      paddingBottom={0}>
      <Pagination count={pageCount} color="primary" page={currentPage} onChange={onChangeHandler} />
    </Box>
  );
}

SearchPagination.propTypes = {
  currentPage: PropTypes.number,
  justify: PropTypes.string,
  location: PropTypes.string,
  onChangeHandler: PropTypes.func,
  pageCount: PropTypes.number,
};

SearchPagination.defaultProps = {
  currentPage: 1,
  justify: 'center',
  location: 'bottom',
  onChangeHandler: () => {},
  pageCount: 0,
};

export default SearchPagination;
