import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { withStyles } from '@material-ui/core';
import ListElementCard from '../../../shared/ListElementCard';
import { contentfulContentTypes } from '../../../../constants';

const styles = () => ({
  root: {},
  cardImagesOnly: {
    padding: `0 3px 0 0`,
    '&:last-of-type': {
      paddingRight: 0,
    },
  },
  sliderCart: {
    padding: `24px 24px 24px 0`,
    '&:last-of-type': {
      paddingRight: 0,
    },
  },
});

function ArticlePage({ classes, className, data, hideText, richText: RichText, elementsPerRowDesktop, ...otherProps }) {
  const title = get(data, 'title');
  const fullPath = get(data, 'fullPath');
  const categories = get(data, 'categories');
  const heroImage = get(data, 'heroImage');
  const shortDescription = get(data, 'shortDescription');
  const pageType = contentfulContentTypes.ArticlePage;

  if (hideText) {
    return (
      <ListElementCard
        {...otherProps}
        className={clsx(classes.root, classes.cardImagesOnly)}
        image={heroImage}
        title={title}
        categories={categories}
        description={shortDescription}
        fullPath={fullPath}
        pageType={pageType}
        hideText={hideText}
        height="350"
        transformation="CardImage-SM-MD"
        elementsPerRowDesktop={elementsPerRowDesktop}
      />
    );
  }
  if (elementsPerRowDesktop) {
    return (
      <ListElementCard
        {...otherProps}
        className={clsx(classes.root, classes.sliderCard)}
        image={heroImage}
        title={title}
        categories={categories}
        description={shortDescription}
        fullPath={fullPath}
        pageType={pageType}
      />
    );
  }

  return (
    <ListElementCard
      {...otherProps}
      className={clsx(classes.root, className)}
      image={heroImage}
      title={title}
      categories={categories}
      description={shortDescription}
      fullPath={fullPath}
      pageType={pageType}
    />
  );
}

ArticlePage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  richText: PropTypes.object,
  hideText: PropTypes.bool,
  elementsPerRowDesktop: PropTypes.number,
};
ArticlePage.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
  hideText: false,
  elementsPerRowDesktop: 4,
};

export default withStyles(styles)(ArticlePage);
