import React from 'react';

import { isEqual } from 'lodash';
import { ArrowForward } from '@material-ui/icons';
import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaButtonLinkModule from './viinimaa';

function ButtonLinkModule(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaButtonLinkModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaButtonLinkModule {...props} endIcon={<ArrowForward fontSize="small" />} />;
  }
  if (brandSite) {
    return <ViinimaaButtonLinkModule {...props} />;
  }

  return null;
}

export default ButtonLinkModule;
