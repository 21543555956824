/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import clsx from 'clsx';

import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
});

function AudioModule({ classes, className, data }) {
  const audioFileURL = get(data, 'audioFile.0.secure_url');

  return (
    <div className={clsx(classes.root, className)}>
      <audio controls>
        <source src={audioFileURL} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

AudioModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

AudioModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(AudioModule);
