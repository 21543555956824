import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles, Grid, Box } from '@material-ui/core';
import get from 'lodash/get';

import Image from './Image';

const styles = theme => ({
  root: {
    display: 'block',
  },
  gridContainer: {
    '& > div': {
      '&:not(:last-child)': {
        [theme.breakpoints.up('md')]: {
          paddingRight: theme.spacing(2),
        },
      },

      '&:first-child': {
        paddingRight: theme.spacing(2),
      },

      '&:last-child': {
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          paddingTop: 0,
        },
      },
    },
  },
  largestItem: {
    padding: theme.spacing(0, 0, 2),
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  column: {
    '& > div:not(:first-child)': {
      paddingTop: theme.spacing(2),
    },
  },
  largeImage: {
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      height: '616px',
    },
  },
  mediumImage: {
    height: '200px',
    [theme.breakpoints.up('md')]: {
      height: '390px',
    },
  },
  smallImage: {
    height: '125px',
    [theme.breakpoints.up('md')]: {
      height: '210px',
    },
  },
});

function LayoutThree({ classes, className, data, richText: RichText }) {
  const inspirationalElements = get(data, 'inspirationalElements');
  const image1 = get(inspirationalElements, '0');
  const image2 = get(inspirationalElements, '1');
  const image3 = get(inspirationalElements, '2');
  const image4 = get(inspirationalElements, '3');
  const image5 = get(inspirationalElements, '4');

  const totalHeight = 768;
  const midHeight = 374;
  const smallHeight = totalHeight - midHeight - 16;
  const smallImageWidth = 400;

  return (
    <div className={clsx(classes.root, className)}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid container spacing={0} item xs={6} md={3} direction="column" wrap="nowrap" className={classes.column}>
            <Grid item xs={12} md={12}>
              <Image
                data={image1}
                richText={RichText}
                showDescription
                height={midHeight}
                width={smallImageWidth}
                classes={{ image: classes.mediumImage }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Image
                data={image2}
                richText={RichText}
                height={smallHeight}
                width={smallImageWidth}
                classes={{ image: classes.smallImage }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} item xs={6} md={3} direction="column" wrap="nowrap" className={classes.column}>
            <Grid item xs={12} md={12}>
              <Image
                data={image3}
                richText={RichText}
                height={smallHeight}
                width={smallImageWidth}
                classes={{ image: classes.smallImage }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Image
                data={image4}
                richText={RichText}
                showDescription
                height={midHeight}
                width={smallImageWidth}
                classes={{ image: classes.mediumImage }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.largestItem}>
            <Image
              data={image5}
              richText={RichText}
              showDescription
              height={totalHeight}
              width="790"
              classes={{ image: classes.largeImage }}
              transformation=""
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

LayoutThree.propTypes = {
  classes: PropTypes.object,
  richText: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

LayoutThree.defaultProps = {
  classes: {},
  className: null,
  richText: null,
  data: null,
};

export default withStyles(styles)(LayoutThree);
