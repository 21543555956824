import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import get from 'lodash/get';

import ContentRows from '../../../page/ContentRows';

const styles = theme => ({
  root: {
    borderTop: 'solid',
    borderTopWidth: theme.palette.footer.border.width,
    borderTopColor: theme.palette.footer.border.color,
    background: theme.palette.footer.background,
    paddingBottom: theme.palette.footer.padding.bottom,
    '@media print': {
      display: 'none',
    },
  },
  logoRow: {
    paddingBottom: '18px',
    paddingTop: '34px',
  },
});

function Footer({ classes, className, pageData }) {
  const footer = get(pageData, 'mainTheme.footer');

  if (footer) {
    const footerRows = { contentRows: footer };

    return (
      <footer className={clsx(classes.root, className)}>
        <ContentRows pageData={footerRows} />
      </footer>
    );
  }

  return null;
}

Footer.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showLogo: PropTypes.bool,
};

Footer.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  showLogo: false,
};

export default withStyles(styles)(Footer);
