import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEqual } from 'lodash';
import { Drawer, IconButton, Toolbar, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { siteMaintenanceMode } from '../../../../constants';
import { useDrawer } from '../../../context/DrawerContext';

import Logo from '../../Logo';
import NavigationElements from '../../NavigationElements';
import LanguageSwitcher from '../../LanguageSwitcher';

const styles = theme => ({
  root: {},
  toolbar: {
    padding: '12px 12px 12px 20px',
    justifyContent: 'center',
    minHeight: '90px',
  },
  iconButton: {
    padding: '10px',
    position: 'absolute',
    backgroundColor: theme.palette.mobileNavigation.xIconBackground,
    top: 0,
    right: 0,
    margin: '24px',
  },
  icon: {
    fontSize: '1rem',
  },
  content: {
    overflow: 'auto',
    justifyContent: 'center',
    paddingTop: '24px',
  },
  navElement: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  listItem: {
    borderBottom: 'none',
  },
  listItemBottom: {
    width: '36px',
    height: '1px',
    backgroundColor: theme.palette.mobileNavigation.navigationElementBorder,
  },
  listItemTextRight: {
    textAlign: 'center',
    padding: '24px 12px 16px 48px',
  },
  link: {
    fontFamily: 'URWDINCond-Demi',
    fontSize: '24px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.header.navigation.activeLink,
    },
  },

  iconWrapper: {
    marginTop: '10px',
  },
  BackIconWrapper: {
    marginTop: '20px',
  },
  label: {
    color: theme.palette.mobileNavigation.text,
  },

  languageSwitcher: {
    padding: theme.spacing(2),
  },
});

function MobileNavigation({ classes, className, pageData }) {
  const { closeDrawer, isDrawerOpen } = useDrawer();
  const drawerOpen = isDrawerOpen('MobileNavigationDrawer');

  const maintenanceMode = get(pageData, 'mainTheme.maintenanceMode');
  const isMaintenanceModeActive = isEqual(maintenanceMode, siteMaintenanceMode.ActiveMode);

  const navigationElements = useMemo(
    () => get(pageData, 'mainTheme.navigation.flattenedNavigationElements'),
    [pageData],
  );

  const handleNavigationElementClick = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const handleLogoClick = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  return (
    <Drawer anchor="right" className={clsx(classes.root, className)} open={drawerOpen} onClose={closeDrawer}>
      <Toolbar className={classes.toolbar}>
        <Logo width="200" onLogoClick={handleLogoClick} pageData={pageData} />
        <IconButton aria-label="Close" className={classes.iconButton} onClick={closeDrawer}>
          <Close className={classes.icon} />
        </IconButton>
      </Toolbar>
      <div className={classes.content}>
        {!isMaintenanceModeActive && (
          <NavigationElements
            data={navigationElements}
            onNavigationElementClick={handleNavigationElementClick}
            classes={{
              listItem: classes.listItem,
              listItemBottom: classes.listItemBottom,
              root: classes.navElement,
              listItemTextRight: classes.listItemTextRight,
              link: classes.link,
              iconWrapper: classes.iconWrapper,
              label: classes.label,
              BackIconWrapper: classes.BackIconWrapper,
            }}
          />
        )}
        <LanguageSwitcher pageData={pageData} className={classes.languageSwitcher} />
      </div>
    </Drawer>
  );
}

MobileNavigation.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.object,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

MobileNavigation.defaultProps = {
  classes: {},
  className: {},
  pageData: null,
};

export default withStyles(styles)(MobileNavigation);
