import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import SkagerrakInstagramFeedModule from './skagerrak';

function InstagramFeedModule(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <SkagerrakInstagramFeedModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <SkagerrakInstagramFeedModule {...props} />;
  }
  if (brandSite) {
    return <SkagerrakInstagramFeedModule {...props} />;
  }

  return null;
}

export default InstagramFeedModule;
