import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { IconButton, Toolbar, withStyles } from '@material-ui/core';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { siteMaintenanceMode } from '../../../../constants';

import { useDrawer } from '../../../context/DrawerContext';

import Logo from '../../../navigation/Logo';
import AppBar from '../Toolbar';
import MobileNavigation from '../../../navigation/MobileNavigation';
import QuickSearchIconButton from '../../../search/QuickSearchIconButton';
import Subscription from '../../../navigation/Subscription';
import LanguageSwitcher from '../../../navigation/LanguageSwitcher';
import TopNavigation from '../../../navigation/TopNavigation';
import RichText from '../../../shared/RichText';

import HambugerMenuIcon from './HambugerMenuIcon';

const styles = theme => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
  navbar: {
    justifyContent: 'space-between',
    minHeight: theme.palette.header.minHeight,
    borderBottom: 'none',
    backgroundColor: ({ backgroundColor }) => {
      if (!backgroundColor) {
        return theme.palette.header.background;
      }
      return backgroundColor;
    },
    backgroundImage: ({ backgroundImage }) => {
      if (!backgroundImage) {
        return 'none';
      }
      return backgroundImage;
    },
    borderBottomWidth: '3px',
    borderBottomColor: theme.palette.footer.border.color,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.palette.header.paddingYDesktop,
      paddingRight: theme.palette.header.paddingYDesktop,
    },
  },

  languageSwitcher: {
    display: ({ showLanguageSwitcher }) => {
      if (showLanguageSwitcher) {
        return 'flex';
      }
      return 'none';
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  rightSide: {
    display: 'flex',
  },
  logo: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '131px',
    },
  },
  subscriptionIcon: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  icon: {
    fontSize: '2rem',
  },
  navigation: {
    '@media print': {
      display: 'none',
    },
  },
  hamburgerMenu: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(2),
    '& p': {
      marginBottom: 0,
    },
  },
  hyperlink: {},
  socialMedia: {},
  emptyDiv: {},
});

function Header({ classes, pageData, hamburgerMenuIcon, hideContent, mobileMood, ...otherProps }) {
  const { openDrawer } = useDrawer();

  const handleMenuClick = useCallback(() => {
    openDrawer('MobileNavigationDrawer');
  }, [openDrawer]);

  const header = get(pageData, 'mainTheme.header');
  if (header) {
    const maintenanceMode = get(pageData, 'mainTheme.maintenanceMode');
    const isMaintenanceModeActive = isEqual(maintenanceMode, siteMaintenanceMode.ActiveMode);
    const headerElements = get(header, 'headerElements');
    const showLogo = !!headerElements.includes('Show logo');
    const showSearch = !!headerElements.includes('Show search');
    const showNavigation = !!headerElements.includes('Show navigation');
    const showNewsletterSubscription = !!headerElements.includes('Show newsletter subscription');
    const headerContent = get(header, 'headerContent');

    return (
      <div>
        <AppBar className={classes.root} pageData={pageData}>
          <Toolbar className={classes.navbar}>
            <div className={classes.emptyDiv} />
            {showLogo && <Logo pageData={pageData} className={classes.logo} width="220" />}
            {showNavigation && !mobileMood && (
              <div className={classes.navigation}>
                {!isMaintenanceModeActive && <TopNavigation pageData={pageData} />}
              </div>
            )}
            <div className={classes.rightSide}>
              <LanguageSwitcher className={classes.languageSwitcher} pageData={pageData} />
              {!isMaintenanceModeActive && showNewsletterSubscription && (
                <Subscription className={classes.subscriptionIcon} />
              )}
              {!isMaintenanceModeActive && showSearch && <QuickSearchIconButton />}

              {headerContent && !hideContent && (
                <RichText
                  pageData={pageData}
                  classes={{ hyperlink: classes.hyperlink, socialMedia: classes.socialMedia }}
                  textData={headerContent}
                  className={classes.content}
                />
              )}

              {showNavigation && (
                <IconButton
                  edge="end"
                  aria-label="Menu"
                  color="inherit"
                  className={classes.hamburgerMenu}
                  onClick={handleMenuClick}>
                  {!hamburgerMenuIcon && <HambugerMenuIcon {...otherProps} />}
                  {hamburgerMenuIcon && hamburgerMenuIcon}
                </IconButton>
              )}
            </div>
          </Toolbar>
        </AppBar>
        {showNavigation && <MobileNavigation pageData={pageData} />}
      </div>
    );
  }

  return null;
}

Header.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showLanguageSwitcher: PropTypes.bool,
  hamburgerMenuIcon: PropTypes.node,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  hideContent: PropTypes.bool,
  mobileMood: PropTypes.bool,
};

Header.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  showLanguageSwitcher: false,
  hamburgerMenuIcon: null,
  backgroundColor: 'transparent',
  backgroundImage: 'none',
  hideContent: false,
  mobileMood: true,
};

export default withStyles(styles)(Header);
