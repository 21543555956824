import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { withStyles, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import AccordionExpandIcon from './AccordionExpandIcon';
import Visible from '../../../shared/Visible';

const styles = theme => ({
  root: {
    display: 'block',
    margin: theme.spacing(1, 0),
  },
  accordion: {
    backgroundColor: theme.accordion.backgroundColor,
    borderRadius: `${theme.accordion.borderRadius} !important`,
    border: theme.accordion.border,
    color: theme.accordion.color,
    boxShadow: 'none',
    overflow: 'hidden',
    '&:hover': {
      ...theme.accordion.hover,
    },
  },
  accordionSummary: {
    paddingLeft: theme.accordion.padding.left,
    paddingRight: theme.accordion.padding.right,
    '&:hover': {
      backgroundColor: theme.accordion.hover.backgroundColor,
      color: theme.accordion.hover.color,
    },
  },
  summaryWrapper: {},
  startIcon: {
    marginRight: '16px',
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    ...theme.accordion.startIcon,
  },
  summaryTitle: {
    fontWeight: 'bold',
    color: 'inherit',
    fontSize: theme.accordion.titleFontSize,
  },
  subTitle: { ...theme.accordion.subtitle },
  accordionDetails: {
    padding: theme.accordion.padding.left,
  },

  expandIcon: {
    backgroundColor: theme.accordion.expandIcon.backgroundColor,
    borderRadius: '0',
    padding: '7px',
    color: theme.accordion.color,
    '&:hover': {
      backgroundColor: theme.accordion.expandIcon.hover.backgroundColor,
      color: theme.accordion.hover.color,
    },
    marginRight: 0,
    transform: 'none',
    transition: 'none',
  },
});

function AccordionModule({ classes, className, data, richText: RichText, startIcon, ...otherProps }) {
  const title = get(data, 'title');
  const description = get(data, 'description');
  const openByDefault = get(data, 'openByDefault');
  const subtitle = get(data, 'subtitle');

  const [accordionOpen, setAccordionOpen] = React.useState(false);

  const handleTitleClick = React.useCallback(() => {
    setAccordionOpen(prevAccordionOpen => !prevAccordionOpen);
  }, []);

  React.useEffect(() => {
    setAccordionOpen(openByDefault);
  }, [openByDefault]);

  return (
    <div className={clsx(classes.root, className)}>
      <Accordion
        classes={{ root: classes.accordion }}
        className={classes.heading}
        expanded={accordionOpen}
        onClick={handleTitleClick}>
        <AccordionSummary
          className={classes.accordionSummary}
          classes={{ expandIcon: classes.expandIcon }}
          expandIcon={<AccordionExpandIcon open={accordionOpen} {...otherProps} />}>
          <Visible hidden={isEmpty(startIcon)}>
            <div className={classes.startIcon}>{startIcon}</div>
          </Visible>
          <div className={classes.summaryWrapper}>
            <Typography className={classes.summaryTitle}>{title}</Typography>
            <Visible hidden={isEmpty(subtitle)}>
              <Typography variant="body2" className={classes.subTitle}>
                {subtitle}
              </Typography>
            </Visible>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <RichText textData={description} className={classes.content} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

AccordionModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.object,
  startIcon: PropTypes.element,
};

AccordionModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
  startIcon: null,
};

export default withStyles(styles)(AccordionModule);
