/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const HambugerMenuIcon = ({ fill, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.333 10.667h-16a1.333 1.333 0 0 1 0-2.667h16a1.333 1.333 0 1 1 0 2.667zm4 6.666h-20a1.333 1.333 0 1 1 0-2.666h20a1.333 1.333 0 1 1 0 2.666zM5.333 24H16a1.333 1.333 0 0 0 0-2.667H5.333a1.333 1.333 0 0 0 0 2.667z"
        fill={fill}
      />
    </svg>
  );
};

HambugerMenuIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
HambugerMenuIcon.defaultProps = {
  fill: '#000000',
  width: '57',
  height: '57',
};

export default HambugerMenuIcon;
