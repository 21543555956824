import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map } from 'lodash';
import { withStyles, NativeSelect } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = () => ({
  root: {},
});

function FilterInputSelect({ classes, className, name, value, onChange, options }) {
  const { t } = useTranslation();

  return (
    <NativeSelect id={name} name={name} className={clsx(classes.root, className)} value={value} onChange={onChange}>
      <option value="">{t('FilterInputSelect.options.default.label')}</option>
      {map(options, option => (
        <option key={get(option, 'value')} value={get(option, 'value')}>
          {get(option, 'label')}
        </option>
      ))}
    </NativeSelect>
  );
}

FilterInputSelect.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  options: PropTypes.array,
};

FilterInputSelect.defaultProps = {
  classes: {},
  className: null,
  name: null,
  value: null,
  onChange: () => {},
  multiple: false,
  options: [],
};

export default withStyles(styles)(FilterInputSelect);
