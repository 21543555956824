/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Link, withStyles, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { useKlevuSearch } from '../../../context/KlevuSearchContext';
import { isSSR } from '../../../../utils/windowUtils';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0',
    [theme.breakpoints.up('md')]: {
      margin: '0',
    },
    '@media print': {
      display: 'none',
    },
  },
  link: {
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    color: theme.palette.header.text,
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '0',
      textDecoration: 'none',
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    height: '36px',
    width: '36px',
    backgroundColor: theme.palette.header.icons.backgroundColor,
    color: theme.palette.header.icons.color,
  },
});

function QuickSearchIconButton({ classes, className }) {
  const { t } = useTranslation();

  const { isInputOpen, updateIsInputOpen } = useKlevuSearch();

  const searchInput = !isSSR() ? document.getElementById('klevu-search') : null;

  useEffect(() => {
    if (!isInputOpen && searchInput) {
      searchInput.blur();
    }
  }, [isInputOpen, searchInput]);

  const handleButtonClick = useCallback(() => {
    updateIsInputOpen(!isInputOpen);
    if (!isSSR()) {
      searchInput.focus();
    }
  }, [updateIsInputOpen, isInputOpen, searchInput]);

  return (
    <div className={clsx(classes.root, className)}>
      <Link onClick={handleButtonClick} className={classes.link}>
        <div className={classes.icon}>
          <Search />
        </div>
        <Typography variant="body2">{t('QuickSearchIconButton.label')}</Typography>
      </Link>
    </div>
  );
}

QuickSearchIconButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
};

QuickSearchIconButton.defaultProps = {
  classes: {},
  className: null,
};

export default withStyles(styles)(QuickSearchIconButton);
