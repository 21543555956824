import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useDrawer } from '../../../context/DrawerContext';
import Link from '../../Link';

const styles = theme => ({
  root: {},
  link: {
    height: '100%',
    padding: theme.spacing(1.5),
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    color: theme.palette.header.text,
    '&:hover': {
      borderRadius: '0',
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
});

function B2BLink({ classes, className }) {
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();

  const handleClick = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  return (
    <div className={clsx(classes.root, className)}>
      <Link to={t('B2BLink.target')} className={classes.link} onClick={handleClick} target="_blank">
        <Typography variant="body2">{t('B2BLink.label')}</Typography>
        <div className={classes.icon}>
          <ArrowForward fontSize="small" />
        </div>
      </Link>
    </div>
  );
}
B2BLink.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
};

B2BLink.defaultProps = {
  classes: {},
  className: null,
};

export default withStyles(styles)(B2BLink);
