import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Link as GatsbyLink } from 'gatsby';
import { Button as MuiButton, withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {},
});

function Button({ classes, className, children, to, ...otherProps }) {
  const internal = useMemo(() => /^\/(?!\/)/.test(to), [to]);

  if (internal) {
    return (
      <MuiButton {...otherProps} component={GatsbyLink} className={clsx(classes.root, className)} to={to}>
        {children}
      </MuiButton>
    );
  }

  return (
    <MuiButton {...otherProps} className={clsx(classes.root, className)} href={to}>
      {children}
    </MuiButton>
  );
}

Button.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string,
};

Button.defaultProps = {
  classes: {},
  className: null,
  children: null,
  to: null,
};

export default withStyles(styles)(Button);
