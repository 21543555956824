import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { filter, get, isEmpty, map, size, isEqual } from 'lodash';

import { withStyles } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import NavIcon from './NavIcon';
import { useActiveNavigation } from '../../../context/ActiveNavigationContext';
import { fofNavigationTitle, fofNavigationBreakpoint } from '../../../../constants';
import Visible from '../../../shared/Visible';
import Link from '../../Link';
import { getWindowPathName } from '../../../../utils/windowUtils';

import '../../../page/Content';

const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'left',
    zIndex: '20',
  },
  mainNav: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    listStyle: 'none',
    width: '100%',
    margin: 0,
    padding: 0,
    [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: {
      width: 'auto',
    },
  },
  mainNavItems: {
    display: 'inline-flex',
    width: '100%',
    padding: theme.spacing(0, 0),
    [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: {
      width: 'auto',
    },
  },

  subMenu1: {
    display: 'none',
    padding: theme.spacing(0, 0),
    [theme.breakpoints.up(fofNavigationBreakpoint.Breakpoint)]: {
      display: 'inline-flex',
    },
  },
  submenu1Item: {
    padding: theme.spacing(0, 0),
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    position: 'relative',
    height: '80px',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:hover > div': {
      visibility: 'visible',
      transitionDelay: '0s',
    },
  },
  subMenu1Link: {
    ...theme.typography.navigationLink,
    fontSize: '14px',
    display: 'flex',
    padding: '8px 0px 8px 35px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  spiritsSubMenu1Link: { color: theme.palette.common.darkBrown },
  winesSubMenu1Link: { color: theme.palette.common.darkPurple },
  subMenu2: {
    marginTop: '0px',
    visibility: 'hidden',
    transition: '0.1s 0.1s',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    minWidth: '380px',
    padding: '15px 15px 30px',
  },

  spiritsSubMenu2: { backgroundColor: theme.palette.common.darkBrown, color: theme.palette.common.white },
  winesSubMenu2: { backgroundColor: theme.palette.common.darkPurple, color: theme.palette.common.white },

  subMenu2List: {},
  subMenu2Item: {
    color: theme.palette.common.white,
    columnCount: '1',
    listStyle: 'square',
    fontSize: '1rem',
  },
  subMenu2Link: {
    ...theme.typography.navigationLink,
    fontSize: '1rem',
    color: theme.palette.common.white,
    display: 'flex',
    padding: '7.5px 10px',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.common.lighterRed,
    },
  },
  activeLink2: {
    color: theme.palette.common.lighterRed,
  },
});

function getNavigationElementTitle(navigationElement) {
  let navigationElementTitle = get(navigationElement, 'pageReference.menuTitle');
  if (isEmpty(navigationElementTitle)) {
    navigationElementTitle = get(navigationElement, 'pageReference.title');
  }
  if (isEmpty(navigationElementTitle)) {
    navigationElementTitle = get(navigationElement, 'title');
  }
  return navigationElementTitle;
}

function getNavigationElementChildren(navigationElements, navigationElementId) {
  return filter(navigationElements, {
    parentNavigationElement: { id: navigationElementId },
  });
}

function TopNavigation({ classes, className, pageData }) {
  // const wines = get(fofNavigationTitle, 'wines');
  const spirits = get(fofNavigationTitle, 'spirits');
  const location = getWindowPathName();

  const { activeNav, clearActiveNav } = useActiveNavigation();
  const navigationElements = get(pageData, 'mainTheme.navigation.flattenedNavigationElements');
  const mainNavigationElements = filter(navigationElements, { parentNavigationElement: null });

  const secondNavigationElements = useMemo(() => get(activeNav, 'children'), [activeNav]);

  useEffect(() => {
    if (location === '/') {
      clearActiveNav();
    }
  }, [location]);

  return (
    <div className={clsx(classes.root, className)}>
      <ul className={classes.mainNav}>
        <li className={classes.mainNavItems}>
          {map(mainNavigationElements, (mainNavigationElement, index) => {
            const mainNavigationElementId = get(mainNavigationElement, 'id');
            const mainNavigationElementFullPath = get(mainNavigationElement, 'pageReference.fullPath');
            const mainNavigationElementChildren = getNavigationElementChildren(
              navigationElements,
              mainNavigationElementId,
            );
            const mainNavigationElementTitle = get(mainNavigationElement, 'title');

            return (
              <NavIcon
                key={index}
                title={get(mainNavigationElement, 'pageReference.menuTitle')}
                to={mainNavigationElementFullPath}
                data={{
                  id: mainNavigationElementId,
                  fullPath: mainNavigationElementFullPath,
                  children: mainNavigationElementChildren,
                  title: mainNavigationElementTitle,
                  index,
                }}
              />
            );
          })}
        </li>
        <Visible hidden={isEmpty(secondNavigationElements)}>
          <div className={classes.subMenu1}>
            {map(secondNavigationElements, (navigationElement2, i) => {
              const navigationElementId2 = get(navigationElement2, 'id');
              const navigationElementFullPath2 = get(navigationElement2, 'pageReference.fullPath');
              const navigationElementTitle2 = getNavigationElementTitle(navigationElement2);
              const navigationElementChildren2 = getNavigationElementChildren(navigationElements, navigationElementId2);

              return (
                <div key={i} className={classes.submenu1Item}>
                  <Link
                    to={navigationElementFullPath2}
                    className={clsx(
                      classes.subMenu1Link,
                      isEqual(get(activeNav, 'title'), spirits)
                        ? classes.spiritsSubMenu1Link
                        : classes.winesSubMenu1Link,
                    )}>
                    {navigationElementTitle2}
                    {size(navigationElementChildren2) > 0 && <KeyboardArrowDown />}
                  </Link>
                  <Visible hidden={isEmpty(get(navigationElement2, 'subNavigationElements'))}>
                    <div
                      className={clsx(
                        classes.subMenu2,
                        isEqual(get(activeNav, 'title'), spirits) ? classes.spiritsSubMenu2 : classes.winesSubMenu2,
                      )}>
                      <ul className={classes.subMenu2List}>
                        {map(navigationElementChildren2, navigationElement3 => {
                          const navigationElementId3 = get(navigationElement3, 'id');
                          const navigationElementFullPath3 = get(navigationElement3, 'pageReference.fullPath');
                          const navigationElementTitle3 = getNavigationElementTitle(navigationElement3);
                          return (
                            <li key={navigationElementId3} className={classes.subMenu2Item}>
                              <Link
                                to={navigationElementFullPath3}
                                className={classes.subMenu2Link}
                                partiallyActive
                                activeClassName={classes.activeLink2}>
                                {navigationElementTitle3}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Visible>
                </div>
              );
            })}
          </div>
        </Visible>
      </ul>
    </div>
  );
}

TopNavigation.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

TopNavigation.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(TopNavigation);
