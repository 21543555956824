import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Close, FilterList } from '@material-ui/icons';
import { Box, Button, Divider, Drawer, Grid, IconButton, Typography, withStyles } from '@material-ui/core';

import SearchFilters from '../../SearchFilters';

const styles = () => ({
  title: {
    marginBottom: '0 !important',
  },
});

function FilterDrawer({ classes, filters, manager, ...otherProps }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (!filters) return null;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" size="small" color="primary" endIcon={<FilterList />} onClick={handleDrawerOpen}>
        {t('KlevuSearch.filter')}
      </Button>
      <Drawer variant="temporary" anchor="right" open={open} onClose={handleDrawerClose}>
        <Box paddingLeft={2} paddingTop={1} paddingBottom={1}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h2" className={classes.title}>
                {t('KlevuSearch.filterResults')}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleDrawerClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box marginTop={1}>
          <SearchFilters filters={filters} manager={manager} appearance="small" {...otherProps} />
        </Box>
      </Drawer>
    </>
  );
}

FilterDrawer.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.array,
  manager: PropTypes.object,
};

FilterDrawer.defaultProps = {
  classes: {},
  filters: [],
  manager: {},
};

export default withStyles(styles)(FilterDrawer);
