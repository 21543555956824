/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty } from 'lodash';
import map from 'lodash/map';
import { withStyles } from '@material-ui/core';

import { createCloudinaryImageUrl } from '../../../../utils/cloudinaryUtils';

const styles = () => ({
  root: {
    display: 'block',
    maxWidth: '100%',
    objectFit: 'cover',
  },
});

function CloudinaryImage({
  classes,
  className,
  element,
  format,
  publicId,
  transformation,
  variants,
  quality,
  gravity,
  ...otherProps
}) {
  if (isEmpty(publicId) || (isEmpty(variants) && isEmpty(transformation) && isEmpty(format))) {
    return null;
  }

  const getParameterString = variant => {
    let variantParameters = get(variant, 'parameters');
    const imageWidth = get(variant, 'width');

    if (isEmpty(get(variant, 'transformation')) && !get(variantParameters, 'w') && imageWidth) {
      if (isEmpty(variantParameters)) {
        variantParameters = { w: imageWidth };
      } else {
        variantParameters.w = imageWidth;
      }
    }

    if (isEmpty(variantParameters) || !isEmpty(get(variant, 'transformation'))) {
      return '';
    }

    return Object.keys(variantParameters)
      .map(key => variantParameters[key] && `${key}_${variantParameters[key]}`)
      .filter(([_, v]) => v != null)
      .toString();
  };

  if (element === 'picture') {
    return (
      <picture>
        {map(variants, variant => (
          <source
            media={`(max-width: ${get(variant, 'width')}px)`}
            srcSet={`${createCloudinaryImageUrl(
              get(variant, 'transformation'),
              publicId,
              getParameterString(variant),
              format,
              quality,
              gravity,
            )}`}
          />
        ))}
        <img
          src={`${createCloudinaryImageUrl(
            get(variants, [0, 'transformation']),
            publicId,
            getParameterString(get(variants, 0)),
            format,
            quality,
            gravity,
          )}`}
          {...otherProps}
          className={clsx(classes.root, className)}
          loading="lazy"
        />
      </picture>
    );
  }

  const srcSet = [];
  const sizes = [];

  for (let i = 0; i < variants.length; i += 1) {
    srcSet.push(
      `${createCloudinaryImageUrl(
        get(variants[i], 'transformation'),
        publicId,
        getParameterString(variants[i]),
        format,
        quality,
        gravity,
      )} ${get(variants[i], 'width')}w`,
    );

    sizes.push(`(max-width: ${get(variants[i], 'width')}px) ${get(variants[i], 'width')}px`);

    if (variants.length - 1 === i) {
      sizes.push(`(min-width: ${get(variants[i], 'width')}px) ${get(variants[i], 'width')}px`);
    }
  }

  if (variants.length > 0) {
    sizes.push(`${get(variants[0], 'width')}w`);
  }

  const imageTransformation = transformation || get(variants[0], 'transformation');
  const parameterString = variants ? getParameterString(get(variants, '0')) : '';

  return (
    <img
      srcSet={srcSet.join(', ')}
      sizes={sizes.join(', ')}
      src={createCloudinaryImageUrl(imageTransformation, publicId, parameterString, format, quality, gravity)}
      {...otherProps}
      className={clsx(classes.root, className)}
      loading="lazy"
    />
  );
}

CloudinaryImage.propTypes = {
  alt: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  element: PropTypes.oneOf(['image', 'picture']),
  format: PropTypes.string,
  gravity: PropTypes.string,
  publicId: PropTypes.string,
  title: PropTypes.string,
  transformation: PropTypes.string,
  variants: PropTypes.array,
  quality: PropTypes.string,
};

CloudinaryImage.defaultProps = {
  alt: '',
  classes: {},
  className: null,
  element: 'image',
  format: 'auto',
  gravity: 'custom',
  publicId: '',
  title: '',
  transformation: '',
  variants: [],
  quality: 'auto',
};

export default withStyles(styles)(CloudinaryImage);
