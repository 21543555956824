import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import Visible from '../Visible';

const styles = () => ({
  root: {},
  loadMore: { width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3rem' },
});

function LoadMoreButton({ classes, handleLoadMoreClick, isFetching, hasElements, hasNextPage, disable }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Visible hidden={disable}>
        <Visible visible={isFetching}>
          <div className={classes.loadMore}>
            <CircularProgress />
          </div>
        </Visible>
        <Visible visible={!isFetching && hasElements && hasNextPage}>
          <div className={classes.loadMore}>
            <Button variant="outlined" size="small" onClick={handleLoadMoreClick}>
              {t(`LoadMoreButton.label`)}
            </Button>
          </div>
        </Visible>
      </Visible>
    </div>
  );
}

LoadMoreButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  handleLoadMoreClick: PropTypes.func,
  isFetching: PropTypes.bool,
  disable: PropTypes.bool,
  hasElements: PropTypes.bool,
  hasNextPage: PropTypes.bool,
};

LoadMoreButton.defaultProps = {
  classes: {},
  className: null,
  handleLoadMoreClick: null,
  isFetching: false,
  disable: false,
  hasElements: false,
  hasNextPage: false,
};

export default withStyles(styles)(LoadMoreButton);
