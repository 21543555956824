import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export default function LoadingIndicator() {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <CircularProgress variant="indeterminate" />
    </Box>
  );
}
