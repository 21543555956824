import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core';

const styles = () => ({
  root: {
    display: 'block',
  },
});

function AssetModule({ classes, className }) {
  return (
    <div className={clsx(classes.root, className)}>
      <span>AssetModule</span>
    </div>
  );
}

AssetModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

AssetModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(AssetModule);
