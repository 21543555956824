import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { withStyles, Typography, List } from '@material-ui/core';
import Ingredient from './Ingredient';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 0, 2),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2, 0, 2),
    },
    '@media print': {
      display: 'block',
    },
  },
  title: {
    marginBottom: 0,
    padding: theme.spacing(2, 0, 0),
  },
  subtitle: {
    padding: theme.spacing(4, 0, 0),
  },
  PreparationSection: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    '@media print': {
      display: 'block',
    },
  },
  ingredient: {
    flexBasis: '25%',
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  preparationSteps: {
    marginLeft: theme.spacing(0),
    flexBasis: '75%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
    },
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  stepsList: {
    padding: theme.spacing(2, 0),
    '@media print': {
      padding: '8px 0',
    },
  },
  list: {
    '& > ol': {
      display: 'block',
      listStyle: 'none',
      counterReset: 'item',
      paddingBottom: '2rem',
      '& > li': {
        counterIncrement: 'item',
        position: 'relative',
        '@media print': {
          pageBreakInside: 'avoid',
        },
      },
      '& >li:before ': {
        color: theme.palette.category.outlined.text,
        fontSize: '1.1rem',
        position: 'absolute',
        top: '0',
        fontWeight: 'bold',
        left: '-40px',
        display: 'block',
        marginRight: '20px',
        content: 'counter(item)',
        background: theme.palette.category.outlined.background,
        width: '26px',
        height: '26px',
        textAlign: 'center',
      },
    },
    '@media print': {
      '& > ol': {
        '& >li:before ': {
          color: 'black',
          background: 'white',
        },
      },
    },
  },
  richtextListItem: {},
});

function PreparationSectionModule({ classes, className, data, richText: RichText }) {
  const { t } = useTranslation();
  const preparationSteps = get(data, 'preparationSteps');
  const title = get(data, 'title');

  return (
    <section className={clsx(classes.root, className)}>
      {title && (
        <Typography className={classes.title} variant="h2">
          {title}
        </Typography>
      )}
      <div className={classes.PreparationSection}>
        <Ingredient className={classes.ingredient} richText={RichText} data={data} />
        <div className={classes.preparationSteps}>
          <Typography className={classes.subtitle} variant="h3">
            {t('PreparationSteps.title')}
          </Typography>
          <List className={classes.stepsList}>
            <RichText
              textData={preparationSteps}
              className={classes.list}
              classes={{
                listItem: classes.richtextListItem,
                preparationStepModule: classes.richtextListItem,
                paragraph: classes.richtextListItem,
              }}
            />
          </List>
        </div>
      </div>
    </section>
  );
}

PreparationSectionModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
};

PreparationSectionModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
};

export default withStyles(styles)(PreparationSectionModule);
