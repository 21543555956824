import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, isEqual, isNil, size, isNull, join } from 'lodash';
import { withStyles } from '@material-ui/core';

import withSecondaryTheme from './withSecondaryTheme';

import RichText from '../../../shared/RichText';

import BackgroundImage from './BackgroundImage';
import FontLoader from './FontLoader';
import { contentfulContentTypes } from '../../../../constants';
import Visible from '../../../shared/Visible';

export function calcWidthWithoutMargin(marginArray, maxWidth = '100%') {
  if (isEmpty(marginArray)) {
    return maxWidth;
  }
  const length = size(marginArray);
  let marginLeft = '0';
  let marginRight = '0';

  if (length === 1) {
    marginLeft = get(marginArray, '0');
    marginRight = get(marginArray, '0');
  }
  if (length === 2 || length === 3) {
    marginLeft = get(marginArray, '1');
    marginRight = get(marginArray, '1');
  }
  if (length === 4) {
    marginLeft = get(marginArray, '3');
    marginRight = get(marginArray, '1');
  }
  return `calc(${maxWidth} - (${marginLeft} + ${marginRight}))`;
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: ({ columnData }) => {
      const content = get(columnData, 'content.raw');
      return isEmpty(content) ? '-1' : '2';
    },
    position: 'relative',
    flexGrow: '0',
    flexShrink: '0',
    maxWidth: ({ columnData }) => {
      const marginMobile = get(columnData, 'columnStyle.marginMobile');
      return calcWidthWithoutMargin(marginMobile);
    },
    flexBasis: '100%',
    minHeight: ({ rowData, columnData }) => {
      const totalColumns = size(get(rowData, 'columns'));
      if (get(columnData, 'backgroundImage.cloudinaryImage.0.public_id') && totalColumns >= 2) {
        return '270px';
      }
      return '0px';
    },
    backgroundColor: ({ columnData }) => {
      const backgroundImage = get(columnData, 'backgroundImage');
      const columnStyle = get(columnData, 'columnStyle');
      const backgroundColor = get(columnStyle, 'backgroundColor');
      if (!isNil(backgroundImage)) {
        return 'transparent';
      }
      if (!isNil(backgroundColor)) {
        return backgroundColor;
      }
      return theme.palette.background.defaultColumn;
    },
    padding: ({ rowData, columnData }) => {
      const totalColumns = size(get(rowData, 'columns'));
      const fullWidth = get(rowData, 'fullWidth');

      const contentType = get(columnData, 'content.references.0.__typename');

      const mobilePaddingTop = get(columnData, 'columnStyle.mobilePaddingTop');
      const mobilePaddingRight = get(columnData, 'columnStyle.mobilePaddingRight');
      const mobilePaddingBottom = get(columnData, 'columnStyle.mobilePaddingBottom');
      const mobilePaddingLeft = get(columnData, 'columnStyle.mobilePaddingLeft');

      const isHeroImage = isEqual(contentType, contentfulContentTypes.HeroImageModule);
      const isContentList = isEqual(contentType, contentfulContentTypes.ContentListModule);
      const isLatestArticle = isEqual(contentType, contentfulContentTypes.LatestArticlesModule);

      if (
        !isEmpty(mobilePaddingTop) ||
        !isEmpty(mobilePaddingRight) ||
        !isEmpty(mobilePaddingBottom) ||
        !isEmpty(mobilePaddingLeft)
      ) {
        return `${mobilePaddingTop || '0'} ${mobilePaddingRight || '0'} ${mobilePaddingBottom || '0'} ${
          mobilePaddingLeft || '0'
        }`;
      }

      if (totalColumns <= 1 && isHeroImage) {
        return '0';
      }

      if (fullWidth) {
        return theme.spacing(2, 0);
      }

      if (totalColumns <= 1 && (isContentList || isLatestArticle)) {
        return theme.spacing(2, 2);
      }

      return theme.spacing(2, 2);
    },

    margin: ({ columnData }) => {
      const marginMobile = get(columnData, 'columnStyle.marginMobile');
      if (!isEmpty(marginMobile)) {
        const margins = join(marginMobile, ' ');
        return margins;
      }
      return '0';
    },

    [theme.breakpoints.down('xs')]: {
      order: ({ columnData }) => {
        const order = get(columnData, 'mobileOrder');
        if (!isNull(order)) {
          return `${order}`;
        }
        return '0';
      },
    },

    [theme.breakpoints.up('sm')]: {
      maxWidth: ({ rowData, columnData }) => {
        const totalColumns = size(get(rowData, 'columns'));
        const marginDesktop = get(columnData, 'columnStyle.marginDesktop');

        if (totalColumns >= 2) {
          return calcWidthWithoutMargin(marginDesktop, '50%');
        }
        return calcWidthWithoutMargin(marginDesktop, '100%');
      },
      minHeight: ({ rowData, columnData }) => {
        const totalColumns = size(get(rowData, 'columns'));
        if (get(columnData, 'backgroundImage.cloudinaryImage.0.public_id') && totalColumns >= 2) {
          return '480px';
        }
        return '0px';
      },
      flexBasis: ({ rowData }) => {
        const totalColumns = size(get(rowData, 'columns'));

        if (totalColumns >= 2) {
          return '50%';
        }
        return '100%';
      },
      padding: theme.spacing(5, 2),

      margin: ({ columnData }) => {
        const marginDesktop = get(columnData, 'columnStyle.marginDesktop');
        if (!isEmpty(marginDesktop)) {
          const margins = join(marginDesktop, ' ');
          return margins;
        }
        return '0';
      },
    },
    [theme.breakpoints.up('md')]: {
      padding: ({ rowData, columnData }) => {
        const totalColumns = size(get(rowData, 'columns'));
        const fullWidth = get(rowData, 'fullWidth');

        const contentType = get(columnData, 'content.references.0.__typename');

        const desktopPaddingTop = get(columnData, 'columnStyle.desktopPaddingTop');
        const desktopPaddingRight = get(columnData, 'columnStyle.desktopPaddingRight');
        const desktopPaddingBottom = get(columnData, 'columnStyle.desktopPaddingBottom');
        const desktopPaddingLeft = get(columnData, 'columnStyle.desktopPaddingLeft');

        const isHeroImage = isEqual(contentType, contentfulContentTypes.HeroImageModule);
        const isContentList = isEqual(contentType, contentfulContentTypes.ContentListModule);
        const isLatestArticle = isEqual(contentType, contentfulContentTypes.LatestArticlesModule);

        if (
          !isEmpty(desktopPaddingTop) ||
          !isEmpty(desktopPaddingRight) ||
          !isEmpty(desktopPaddingBottom) ||
          !isEmpty(desktopPaddingLeft)
        ) {
          return `${desktopPaddingTop || '0'} ${desktopPaddingRight || '0'} ${desktopPaddingBottom || '0'} ${
            desktopPaddingLeft || '0'
          }`;
        }
        if (totalColumns <= 1 && isHeroImage) {
          return '0';
        }
        if (fullWidth) {
          return theme.spacing(3, 0);
        }
        if (totalColumns <= 1 && (isContentList || isLatestArticle)) {
          return theme.spacing(3, 3);
        }
        if (totalColumns === 2) {
          return '80px 50px';
        }
        return theme.spacing(6, 3);
      },

      maxWidth: ({ rowData, columnData }) => {
        const marginDesktop = get(columnData, 'columnStyle.marginDesktop');

        const totalColumns = size(get(rowData, 'columns'));
        const isHeroImage = get(columnData, 'content.references.0.__typename') === 'ContentfulHeroImageModule';
        if (totalColumns <= 1 && isHeroImage) {
          return calcWidthWithoutMargin(marginDesktop, '100%');
        }
        if (isEqual(totalColumns, 2)) {
          return calcWidthWithoutMargin(marginDesktop, '50%');
        }
        if (isEqual(totalColumns, 3)) {
          return calcWidthWithoutMargin(marginDesktop, '33.3%');
        }
        if (isEqual(totalColumns, 4)) {
          return calcWidthWithoutMargin(marginDesktop, '25%');
        }
        return calcWidthWithoutMargin(marginDesktop, '100%');
      },
      flexBasis: ({ rowData }) => {
        const totalColumns = size(get(rowData, 'columns'));
        if (isEqual(totalColumns, 2)) {
          return '50%';
        }
        if (isEqual(totalColumns, 3)) {
          return '33.3%';
        }
        if (isEqual(totalColumns, 4)) {
          return '25%';
        }
        return '100%';
      },
    },
  },

  content: {},
});

function ContentColumn({ classes, className, pageData, rowData, columnData }) {
  const content = get(columnData, 'content');

  const rowBodyFont = get(rowData, 'customRowStyle.bodyFontFamily');
  const rowHeadingFont = get(rowData, 'customRowStyle.headingFontFamily');

  const columnBodyFont = get(columnData, 'columnStyle.bodyFontFamily');
  const columnHeadingFont = get(columnData, 'columnStyle.headingFontFamily');

  let loadFont = false;
  if (!isEmpty(rowBodyFont) || !isEmpty(rowHeadingFont) || !isEmpty(columnBodyFont) || !isEmpty(columnHeadingFont)) {
    loadFont = true;
  }

  const rowIdentifier = get(columnData, 'identifier');

  return (
    <>
      <Visible visible={loadFont}>
        <FontLoader rowData={rowData} columnData={columnData} />
      </Visible>
      <div className={clsx(classes.root, className)} id={rowIdentifier}>
        <RichText pageData={pageData} textData={content} className={classes.content} />
        <BackgroundImage pageData={pageData} rowData={rowData} columnData={columnData} />
      </div>
    </>
  );
}

ContentColumn.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  columnData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rowData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ContentColumn.defaultProps = {
  classes: {},
  className: null,
  columnData: null,
  rowData: null,
  pageData: null,
};

export default withSecondaryTheme(withStyles(styles)(ContentColumn));
