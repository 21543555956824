import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import PreparationSectionModule from '../viinimaa';

const styles = theme => ({
  title: {
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 3),
    },
    '@media print': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  ingredient: {
    flexBasis: '50%',
    backgroundColor: '#edf5f8',
    padding: '24px',
    [theme.breakpoints.up('md')]: {
      padding: '50px',
    },
    '@media print': {
      backgroundColor: 'transparent',
      padding: 0,
    },
  },
  preparationSteps: {
    flexBasis: '50%',
    backgroundColor: theme.palette.header.background,
    color: 'white',
    padding: '24px',
    margin: '0',
    [theme.breakpoints.up('md')]: {
      marginLeft: '0',
      padding: '50px',
    },
    '@media print': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      paddingLeft: 0,
    },
  },

  richtextListItem: {
    color: 'white',
    '@media print': {
      color: theme.palette.text.primary,
    },
  },
  list: {
    color: 'white',
    '& > ol': {
      listStyle: 'decimal outside none',
      paddingLeft: '20px',
      '& >li:before ': {
        color: 'inherit',
        left: '0',
        marginRight: '0',
        content: 'none',
        background: 'transparent',
        width: '0',
        height: '0',
      },
    },
    '@media print': {
      color: theme.palette.text.primary,
    },
  },
});

function SkagerrakPreparationSectionModule({ classes, className, ...otherProps }) {
  return (
    <PreparationSectionModule
      {...otherProps}
      classes={{
        ingredient: classes.ingredient,
        preparationSteps: classes.preparationSteps,
        list: classes.list,
        richtextListItem: classes.richtextListItem,
        title: classes.title,
      }}
    />
  );
}

SkagerrakPreparationSectionModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
};

SkagerrakPreparationSectionModule.defaultProps = {
  classes: {},
  className: null,
};

export default withStyles(styles)(SkagerrakPreparationSectionModule);
