import { compact, get, head, intersection, isEmpty, isString, split, map } from 'lodash';

import { settings } from '../sites';

export const languageCodeFromIETF = ietf => {
  if (isEmpty(ietf) || !isString(ietf)) {
    return null;
  }

  return head(split(ietf, '-'));
};

export const getLanguageFromPathname = pathname => {
  const { defaultLanguageCode } = settings.localization;

  if (isEmpty(pathname) || !isString(pathname)) {
    return defaultLanguageCode;
  }

  const fromPath = get(
    intersection([head(compact(split(pathname, '/')))], map(settings.channels, 'languageCode')),
    '0',
    defaultLanguageCode,
  );
  return fromPath;
};
