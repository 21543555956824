import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Container, withStyles } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import { WarningOutlined } from '@material-ui/icons';
import RichText from '../../../shared/RichText';

const styles = theme => ({
  contaier: {},
  root: {
    ...theme.palette.siteNotifications,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    '@media print': {
      display: 'none',
    },
  },
  borderRadius: '8px',
  richText: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.siteNotifications.color,
  },
  text: {
    color: theme.palette.siteNotifications.color,
    margin: theme.spacing(0, 1),
    fontSize: '0.875rem',
  },
});

function SiteNotifications({ classes, className, pageData }) {
  const description = get(pageData, 'mainTheme.siteNotification.description');
  if (isEmpty(description)) {
    return null;
  }
  return (
    <Container maxWidth="lg" className={classes.contaier}>
      <div className={clsx(classes.root, className)}>
        <WarningOutlined className={classes.icon} />
        <RichText textData={description} className={classes.richText} classes={{ paragraph: classes.text }} />
      </div>
    </Container>
  );
}

SiteNotifications.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

SiteNotifications.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(SiteNotifications);
