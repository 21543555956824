import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { get, isEmpty, toLower } from 'lodash';
import { useTheme } from '@material-ui/core';
import SecondaryThemeProvider from '../../../../themes/SecondaryThemeProvider';

function withSecondaryTheme(Component) {
  return props => {
    const rowData = get(props, 'rowData');
    const columnData = get(props, 'columnData');
    const theme = useTheme();

    const customTheme = useMemo(() => {
      const rowBodyFontColor = get(rowData, 'customRowStyle.bodyFontColor');
      const rowBodyFontFamily = get(rowData, 'customRowStyle.bodyFontFamily.cssFontFamilyIdentifier');
      const rowHeadingFontColor = get(rowData, 'customRowStyle.headingFontColor');
      const rowHeadingFontFamily = get(rowData, 'customRowStyle.headingFontFamily.cssFontFamilyIdentifier');
      const rowLinkFontColor = get(rowData, 'customRowStyle.linkFontColor');
      const columnBodyFontColor = get(columnData, 'columnStyle.bodyFontColor');
      const columnBodyFontFamily = get(columnData, 'columnStyle.bodyFontFamily.cssFontFamilyIdentifier');
      const columnHeadingFontColor = get(columnData, 'columnStyle.headingFontColor');
      const columnHeadingFontFamily = get(columnData, 'columnStyle.headingFontFamily.cssFontFamilyIdentifier');
      const columnLinkFontColor = get(columnData, 'columnStyle.linkFontColor');
      let textAlign = toLower(get(columnData, 'textAlign.0'));

      if (isEmpty(textAlign)) {
        textAlign = 'left';
      }

      let bodyFontColor = rowBodyFontColor;
      if (!isEmpty(columnBodyFontColor)) {
        bodyFontColor = columnBodyFontColor;
      }

      let bodyFontFamily = rowBodyFontFamily;
      if (!isEmpty(columnBodyFontFamily)) {
        bodyFontFamily = columnBodyFontFamily;
      }

      let headingFontColor = rowHeadingFontColor;
      if (!isEmpty(columnHeadingFontColor)) {
        headingFontColor = columnHeadingFontColor;
      }

      let headingFontFamily = rowHeadingFontFamily;
      if (!isEmpty(columnHeadingFontFamily)) {
        headingFontFamily = columnHeadingFontFamily;
      }
      // if (isEmpty(headingFontFamily)) {
      //   headingFontFamily = theme.palette.headingFontFamily.fontFamily;
      // }

      let linkFontColor = rowLinkFontColor;
      if (!isEmpty(columnLinkFontColor)) {
        linkFontColor = columnLinkFontColor;
      }

      return {
        overrides: {
          MuiLink: {
            root: {
              textAlign,
              ...(linkFontColor ? { color: linkFontColor } : {}),
              ...(bodyFontFamily ? { fontFamily: bodyFontFamily } : {}),
            },
          },
          MuiTypography: {
            body1: {
              textAlign,
              ...(bodyFontColor ? { color: bodyFontColor } : {}),
              ...(bodyFontFamily ? { fontFamily: bodyFontFamily } : {}),
              [theme.breakpoints.up('md')]: {
                ...(bodyFontColor ? { color: bodyFontColor } : {}),
                ...(bodyFontFamily ? { fontFamily: bodyFontFamily } : {}),
              },
            },
            body2: {
              textAlign,
              ...(bodyFontColor ? { color: bodyFontColor } : {}),
              ...(bodyFontFamily ? { fontFamily: bodyFontFamily } : {}),
              [theme.breakpoints.up('md')]: {
                ...(bodyFontColor ? { color: bodyFontColor } : {}),
                ...(bodyFontFamily ? { fontFamily: bodyFontFamily } : {}),
              },
            },
            subtitle1: {
              ...(bodyFontColor ? { color: bodyFontColor } : {}),
              ...(bodyFontFamily ? { fontFamily: bodyFontFamily } : {}),
            },
            subtitle2: {
              ...(bodyFontColor ? { color: bodyFontColor } : {}),
              ...(bodyFontFamily ? { fontFamily: bodyFontFamily } : {}),
            },
            overline: {
              ...(bodyFontColor ? { color: bodyFontColor } : {}),
              ...(bodyFontFamily ? { fontFamily: bodyFontFamily } : {}),
            },
            caption: {
              ...(bodyFontColor ? { color: bodyFontColor } : {}),
              ...(bodyFontFamily ? { fontFamily: bodyFontFamily } : {}),
            },
            h1: {
              textAlign,
              ...(headingFontColor ? { color: headingFontColor } : {}),
              ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              [theme.breakpoints.up('md')]: {
                ...(headingFontColor ? { color: headingFontColor } : {}),
                ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              },
            },
            h2: {
              textAlign,
              ...(headingFontColor ? { color: headingFontColor } : {}),
              ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              [theme.breakpoints.up('md')]: {
                ...(headingFontColor ? { color: headingFontColor } : {}),
                ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              },
            },
            h3: {
              textAlign,
              ...(headingFontColor ? { color: headingFontColor } : {}),
              ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              [theme.breakpoints.up('md')]: {
                ...(headingFontColor ? { color: headingFontColor } : {}),
                ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              },
            },
            h4: {
              textAlign,
              ...(headingFontColor ? { color: headingFontColor } : {}),
              ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              [theme.breakpoints.up('md')]: {
                ...(headingFontColor ? { color: headingFontColor } : {}),
                ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              },
            },
            h5: {
              textAlign,
              ...(headingFontColor ? { color: headingFontColor } : {}),
              ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              [theme.breakpoints.up('md')]: {
                ...(headingFontColor ? { color: headingFontColor } : {}),
                ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              },
            },
            h6: {
              textAlign,
              ...(headingFontColor ? { color: headingFontColor } : {}),
              ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              [theme.breakpoints.up('md')]: {
                ...(headingFontColor ? { color: headingFontColor } : {}),
                ...(headingFontFamily ? { fontFamily: headingFontFamily } : {}),
              },
            },
          },
        },
      };
    }, [rowData, columnData, theme.breakpoints]);

    return (
      <SecondaryThemeProvider customTheme={customTheme}>
        <Component {...props} />
      </SecondaryThemeProvider>
    );
  };
}

withSecondaryTheme.propTypes = {
  data: PropTypes.object,
};

withSecondaryTheme.defaultProps = {
  data: null,
};

export default withSecondaryTheme;
