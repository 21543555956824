import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// import { useTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { withStyles, Divider, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';

const styles = theme => ({
  root: {
    display: 'block',
    marginTop: '5px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 1),
    },
  },
  accordion: {
    backgroundColor: theme.palette.background.default,

    boxShadow: 'none',
  },
  accordionSummary: {
    padding: '0px',
    marginTop: '0',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
  expandIcon: {
    backgroundColor: theme.palette.common.lighterGray,
    borderRadius: '0',
    marginRight: '0px',
    padding: '7px',
    color: theme.palette.link.active,
    '&:hover': {},
    transform: 'none',
    transition: 'none',
  },
  icon: { margin: '0' },
  filtersHeader: {
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  label: {
    padding: theme.spacing(0, 1),
    color: theme.palette.link.active,
  },
});

function FiltersDropDown({ classes, className, children, title, openByDefault }) {
  const { t } = useTranslation();
  const [accordionOpen, setAccordionOpen] = React.useState(false);

  const handleTitleClick = React.useCallback(() => {
    setAccordionOpen(prevAccordionOpen => !prevAccordionOpen);
  }, []);

  React.useEffect(() => {
    setAccordionOpen(openByDefault);
  }, [openByDefault]);

  return (
    <div className={clsx(classes.root, className)}>
      <Accordion classes={{ root: classes.accordion }} className={classes.heading} expanded={accordionOpen}>
        <AccordionSummary
          onClick={handleTitleClick}
          className={classes.accordionSummary}
          classes={{ expandIcon: classes.expandIcon, content: classes.filtersHeader }}
          expandIcon={<TuneIcon />}>
          {title}
          <Typography variant="body1" className={classes.label}>
            {t('ProductSearch.filterHeader.label')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
      <Divider />
    </div>
  );
}

FiltersDropDown.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.element,
  title: PropTypes.element,
  openByDefault: PropTypes.bool,
};

FiltersDropDown.defaultProps = {
  classes: {},
  className: null,
  children: null,
  title: null,
  openByDefault: false,
};

export default withStyles(styles)(FiltersDropDown);
