import { useCallback, useState } from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import { navigate } from 'gatsby';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

export const useLocationFilters = () => {
  const { state, pathname } = useLocation();
  const [locationFilters, setLocationFilters] = useState(() => {
    return isNil(get(state, 'filters')) ? {} : get(state, 'filters');
  });

  const updateLocationFilters = useCallback(
    nextFilters => {
      let filters = {};
      setLocationFilters(prevFilters => {
        if (!isNil(nextFilters)) {
          filters = merge({}, prevFilters, nextFilters);
        }
        return filters;
      });
      navigate(pathname, { replace: true, state: { filters } });
    },
    [pathname],
  );

  return {
    locationFilters,
    updateLocationFilters,
  };
};
