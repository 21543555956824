import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core';

import ListElementCard from '../../../shared/ListElementCard';

const styles = theme => ({
  root: {},
  thumbnailImage: {},
});

function ProductListElement({ classes, className, data, richText: RichText, layoutType }) {
  const pageType = get(data, 'pageReference.__typename');
  const productData = get(data, 'pageReference.relatedProduct');
  const fullPath = get(productData, 'fullPath');
  const categories = get(productData, 'categories');

  let image = get(data, 'image');
  if (isEmpty(image)) {
    image = get(productData, 'heroImage');
  }

  let title = get(data, 'title');
  if (isEmpty(title)) {
    title = get(productData, 'name');
  }

  let description = get(data, 'description', '');
  if (isEmpty(description)) {
    description = get(data, 'pageReference.description', '');
  }
  if (isEmpty(description)) {
    description = get(productData, 'attributes.short_description.value', '');
  }
  if (isEmpty(description)) {
    description = get(productData, 'attributes.description.value', '');
  }

  if (isEmpty(productData)) {
    return null;
  }

  return (
    <ListElementCard
      className={clsx(classes.root, className)}
      classes={{ image: classes.thumbnailImage }}
      richText={RichText}
      image={image}
      title={title}
      categories={categories}
      description={description}
      fullPath={fullPath}
      layoutType={layoutType}
      pageType={pageType}
    />
  );
}

ProductListElement.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  layoutType: PropTypes.string,
  richText: PropTypes.object,
  slider: PropTypes.bool,
};

ProductListElement.defaultProps = {
  classes: {},
  className: null,
  data: null,
  layoutType: 'normal',
  richText: null,
  slider: false,
};

export default withStyles(styles)(ProductListElement);
