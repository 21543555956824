import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEqual, map } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import LanguageIcon from '@material-ui/icons/Language';
import Link from '../../Link';
import { settings } from '../../../../sites';

const styles = theme => ({
  root: {
    color: theme.languageSelector.color,
    alignItems: 'center',
    display: 'flex',
    margin: theme.languageSelector.margin,
    padding: '15px 0',
  },
  link: {
    ...theme.typography.navigationLink,
    color: theme.languageSelector.color,
    padding: '0 8px',
    textTransform: 'uppercase',
    borderLeft: `${theme.languageSelector.border.width} solid ${theme.languageSelector.border.color}`,
    '&:first-of-type': {
      borderLeft: 'none',
      marginLeft: '5px',
    },
  },
  activeLink: {
    ...theme.typography.navigationLink,
    color: theme.languageSelector.color,
    textDecoration: 'underline',
  },
});

function LanguageSwitcher({ classes, className, pageData, showIcon }) {
  const languageVersions = get(pageData, 'languageVersions');

  if (languageVersions && languageVersions.length > 1) {
    return (
      <div className={clsx(classes.root, className)}>
        {showIcon && <LanguageIcon />}
        {map(languageVersions, languageVersionPage => {
          let url = '/';
          const languageCode = get(languageVersionPage, 'languageCode');
          if (!isEqual(languageCode, settings.localization.defaultLanguageCode)) {
            url = `/${languageCode}`;
          }
          return (
            <Link
              key={languageCode}
              to={get(languageVersionPage, 'fullPath') ? get(languageVersionPage, 'fullPath') : url}
              className={classes.link}
              activeClassName={classes.activeLink}>
              {get(languageVersionPage, 'languageCode')}
            </Link>
          );
        })}
      </div>
    );
  }
  return null;
}

LanguageSwitcher.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.object,
  showIcon: PropTypes.bool,
};

LanguageSwitcher.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  showIcon: true,
};

export const query = graphql`
  fragment LanguageVersionPageFragment on PageUnion {
    ... on ContentfulArticlePage {
      languageCode
      fullPath
    }
    ... on ContentfulAdvancedPage {
      languageCode
      fullPath
    }
    ... on ContentfulLandingPage {
      languageCode
      fullPath
    }
    ... on ContentfulRecipePage {
      languageCode
      fullPath
    }
    ... on ContentfulBrand {
      languageCode
      fullPath
    }
    ... on ContentfulCategory {
      languageCode
      fullPath
    }
    ... on MagentoProduct {
      languageCode
      fullPath
    }
    ... on ContentfulProduct {
      languageCode
      fullPath
    }
  }
`;

export default withStyles(styles)(LanguageSwitcher);
