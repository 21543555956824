import get from 'lodash/get';
import { breakPoints } from '../../../constants';

const imageCollageHeights = {
  desktop: breakPoints.lg / 2 - 40,
  tablet: 600,
};

const imageSizes = {
  sm: {
    desktop: {
      height: Math.round((imageCollageHeights.desktop - 16) * 0.36),
    },
    tablet: {
      height: Math.round(imageCollageHeights.tablet / 3),
    },
    mobile: {
      height: 125,
    },
  },
  md: {
    desktop: {
      height: Math.round((imageCollageHeights.desktop - 16) * 0.64),
    },
    tablet: {
      height: Math.round((imageCollageHeights.tablet / 3) * 2 - 16),
    },
    mobile: {
      height: 200,
    },
  },
  lg: {
    desktop: {
      height: Math.round(imageCollageHeights.desktop),
      width: Math.round(breakPoints.lg / 2) - 32,
    },
    tablet: {
      height: Math.round(imageCollageHeights.tablet),
      width: 724,
    },
    mobile: {
      height: '',
      width: 790,
    },
  },
};

const imageSizesLayout1 = {
  sm: {
    desktop: {
      height: Math.round(imageCollageHeights.desktop / 3),
    },
    tablet: {
      height: Math.round((imageCollageHeights.tablet / 5) * 2), // 300
    },
    mobile: {
      height: 127,
    },
  },
  md: {
    desktop: {
      height: Math.round((imageCollageHeights.desktop / 5) * 2.5),
    },
    tablet: {
      height: (imageCollageHeights.tablet / 5) * 2, // 300
    },
    mobile: {
      height: 270,
    },
  },
  lg: {
    desktop: {
      height: Math.round((imageCollageHeights.desktop / 5) * 3.5),
      width: Math.round(breakPoints.lg / 2) - 32,
    },
    tablet: {
      height: Math.round((imageCollageHeights.desktop / 5) * 3),
      width: '',
    },
    mobile: {
      height: 270,
      width: 800,
    },
  },
};

export const getImageHeight = (imageSize, screenWidth, layoutNumber) => {
  if (layoutNumber === 1) {
    return get(imageSizesLayout1, [imageSize, screenWidth, 'height']);
  }
  return get(imageSizes, [imageSize, screenWidth, 'height']);
};

export const getImageWidth = (imageSize, screenWidth, layoutNumber) => {
  if (layoutNumber === 1) {
    return get(imageSizesLayout1, [imageSize, screenWidth, 'width']);
  }
  return get(imageSizes, [imageSize, screenWidth, 'width']);
};
