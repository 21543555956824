import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import CardWithText from './CardWithText';
import CardWithImageOnly from './CardWithImageOnly';

const styles = theme => ({
  title: {
    fontSize: theme.typography.h5.fontSize,
  },
});

function ContentListElement({ hideText, classes, ...otherProps }) {
  if (hideText) {
    return <CardWithImageOnly {...otherProps} />;
  }

  return <CardWithText {...otherProps} classes={{ title: classes.title }} />;
}
ContentListElement.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  hideText: PropTypes.bool,
};

ContentListElement.defaultProps = {
  classes: {},
  className: null,
  hideText: false,
};

export default withStyles(styles)(ContentListElement);
