/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const styles = () => ({
  root: {},
  icon: { color: 'inherit' },
});

function ExpandIcon({ classes, open, icon = 'arrow' }) {
  switch (icon) {
    case 'arrow':
      return <ExpandMoreIcon className={classes.icon} />;

    case 'plus':
      return open ? <RemoveIcon className={classes.icon} /> : <AddIcon className={classes.icon} />;

    default:
      return <ExpandMoreIcon className={classes.icon} />;
  }
}

function AccordionExpandIcon({ classes, expandIcon, open }) {
  return <ExpandIcon classes={classes.root} icon={expandIcon} open={open} />;
}

AccordionExpandIcon.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  expandIcon: PropTypes.oneOf(['arrow', 'plus']),
  open: PropTypes.bool,
};

AccordionExpandIcon.defaultProps = {
  classes: {},
  className: null,
  expandIcon: 'arrow',
  open: false,
};

export default withStyles(styles)(AccordionExpandIcon);
