import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import map from 'lodash/map';
import isUndefined from 'lodash/isUndefined';
import { ImageList, ImageListItem, withStyles } from '@material-ui/core';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { breakPoints } from '../../../../constants';

import Media from './Media';

const styles = theme => ({
  root: {
    display: 'block',
  },
  gridList: {
    justifyContent: 'center',
  },
  imageListItemRoot: {
    maxWidth: '33%',
    paddingLeft: '15px !important',
    paddingRight: '15px !important',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '26%',
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: '12%',
    },
  },
  tile: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
});

function MediaGalleryModule({ classes, className, data }) {
  const [isMobile, setIsMobile] = useState(true);
  const [cols, setCols] = useState(2);
  const { width } = useWindowSize();

  const galleryImages = get(data, 'galleryImages');
  const usePreview = get(data, 'usePreview');

  useEffect(() => {
    if (!isUndefined(width)) {
      if (width > breakPoints.sm) {
        setIsMobile(false);
      }
      if (width < breakPoints.sm) {
        setIsMobile(true);
        setCols(2);
      }
      if (width > breakPoints.sm && width < breakPoints.md) {
        setCols(3);
      }
      if (width > breakPoints.md) {
        setCols(6);
      }
    }
  }, [width]);

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.root}>
        <ImageList className={classes.gridList} rowHeight="auto" cols={cols} gap={40}>
          {map(galleryImages, (galleryImage, index) => {
            const galleryImageId = get(galleryImage, 'contentful_id');
            const galleryImageKey = `${galleryImageId}-${index}`;
            return (
              <ImageListItem key={galleryImageKey} classes={{ root: classes.imageListItemRoot, item: classes.tile }}>
                <Media
                  data={galleryImage}
                  isMobile={isMobile}
                  usePreview={isNull(usePreview) ? true : usePreview}
                  transformation="BrandLogoImage-IN-MD"
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </div>
    </div>
  );
}

MediaGalleryModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

MediaGalleryModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(MediaGalleryModule);
