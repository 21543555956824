import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaRichText from './viinimaa';
import PhilipsonSoderbergRichText from './philipson-soderberg';

function RichText(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaRichText {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaRichText {...props} />;
  }
  if (isEqual(siteId, siteIds.PhilipsonSoderberg)) {
    return <PhilipsonSoderbergRichText {...props} />;
  }
  if (brandSite) {
    return <ViinimaaRichText {...props} />;
  }

  return null;
}

export default RichText;
