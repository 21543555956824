import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';

import { Link, Typography, withStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ThumbnailImage from '../../../shared/ThumbnailImage';

function encodePhoneNumber(phoneNumber) {
  return encodeURIComponent(replace(phoneNumber, /\s/g, ''));
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: '100%',
    wordBreak: 'break-word',
    hyphens: 'auto',
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up('sm')]: {
      maxWidth: '33.33%',
      flexBasis: '33.33%',
      float: 'left',
    },
  },

  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  text: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 0),
    },
  },
  title: {
    marginBottom: '16px',
  },
  role: {
    marginBottom: '4px',
    color: '#7d7d7d',
    textTransform: 'uppercase',
  },
  button: {
    width: 'fit-content',
    fontWeight: 'bold',
    padding: '12px 24px',

    '&:hover': {
      color: 'white',
    },
  },
});

function ContactPerson({ classes, className, data }) {
  const { t } = useTranslation();
  const name = get(data, 'name');
  const role = get(data, 'role');
  const emailAddress = get(data, 'emailAddress');
  const phoneNumber = get(data, 'phoneNumber');
  const description = get(data, 'description.description');
  const encodedPhoneNumber = useMemo(() => encodePhoneNumber(phoneNumber), [phoneNumber]);

  return (
    <article className={clsx(classes.root, className)}>
      <div className={classes.imageContainer}>
        <ThumbnailImage
          data={data}
          transformation="ContactPersonImage-MD-MD"
          width="auto"
          height="auto"
          circle={false}
        />
      </div>
      <div className={classes.text}>
        <Typography variant="body1" align="left" className={classes.role}>
          {role}
        </Typography>
        <Typography variant="h3" align="left" className={classes.title}>
          {name}
        </Typography>
        {!isEmpty(description) && (
          <Typography variant="body1" align="left" className={classes.title}>
            {description}
          </Typography>
        )}
        <Link href={`tel:${encodedPhoneNumber}`} className={classes.title}>
          {phoneNumber}
        </Link>
        <Button href={`mailto:${emailAddress}`} variant="outlined" className={classes.button}>
          {t('ContactPerson.contactMe')}
        </Button>
      </div>
    </article>
  );
}

ContactPerson.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

ContactPerson.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(ContactPerson);
