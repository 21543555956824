import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash/get';

import { withStyles } from '@material-ui/core';

import Link from '../../../navigation/Link';
import CloudinaryImage from '../../CloudinaryImage';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: `0 3px 0 0`,
    maxHeight: '600px',
    '&:last-of-type': {
      paddingRight: 0,
    },
    flexBasis: ({ elementsPerRowMobile }) => {
      return `calc(100% / ${elementsPerRowMobile})`;
    },
    maxWidth: ({ elementsPerRowMobile }) => {
      return `calc(100% / ${elementsPerRowMobile})`;
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: ({ elementsPerRowTablet }) => {
        return `calc(100% / ${elementsPerRowTablet})`;
      },
      maxWidth: ({ elementsPerRowTablet }) => {
        return `calc(100% / ${elementsPerRowTablet})`;
      },
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: ({ elementsPerRowDesktop }) => {
        return `calc(100% / ${elementsPerRowDesktop})`;
      },
      maxWidth: ({ elementsPerRowDesktop }) => {
        return `calc(100% / ${elementsPerRowDesktop})`;
      },
    },
  },

  imageWrapper: {
    color: theme.palette.text.primary,
    height: '100%',
    position: 'relative',
  },
  imageFull: {
    height: '100%',
    margin: 'auto',
    transition: 'all 500ms ease',
    width: 'auto',
    '&:hover': {
      filter: 'drop-shadow(0 0 4px #5a5a5a)',
      transition: 'all 500ms ease',
    },
  },
});

function CardWithImageOnly({ classes, className, image, fullPath }) {
  const altText = get(image, 'imageAltText');
  const publicId = get(image, 'cloudinaryImage.0.public_id');

  const variants = useMemo(
    () => [
      {
        transformation: 'Linie-ProductSliderImageMobile',
        width: 200,
      },
      {
        transformation: 'Linie-ProductSliderImage',
        width: 300,
      },
    ],
    [],
  );
  return (
    <article className={clsx(classes.root, className)}>
      <Link to={fullPath} className={classes.imageWrapper}>
        <CloudinaryImage
          className={classes.imageFull}
          publicId={publicId}
          variants={variants}
          alt={altText}
          width="100%"
          height="100%"
        />
      </Link>
    </article>
  );
}

CardWithImageOnly.propTypes = {
  hideText: PropTypes.bool,
  classes: PropTypes.object,
  className: PropTypes.string,
  image: PropTypes.object,
  fullPath: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  elementsPerRowDesktop: PropTypes.number,
  elementsPerRowTablet: PropTypes.number,
  elementsPerRowMobile: PropTypes.number,
};

CardWithImageOnly.defaultProps = {
  hideText: false,
  classes: {},
  className: null,
  image: null,
  fullPath: null,
  width: '600',
  height: 'auto',
  elementsPerRowDesktop: 4,
  elementsPerRowTablet: 2,
  elementsPerRowMobile: 1,
};

export default withStyles(styles)(CardWithImageOnly);
