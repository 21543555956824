import React from 'react';
import PropTypes from 'prop-types';

import ListItem from './ListItem';
import GridItem from './GridItem';

function SearchResultItem({ appearance, ...otherProps }) {
  if (appearance === 'list') return <ListItem {...otherProps} />;
  if (appearance === 'grid') return <GridItem {...otherProps} />;
}

SearchResultItem.propTypes = {
  appearance: PropTypes.string,
};

SearchResultItem.defaultProps = {
  appearance: 'list',
};

export default SearchResultItem;
