import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { Formik, Form, Field } from 'formik';
import { withStyles, IconButton, FormControl } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { subscribeNewsletter } from '../../../utils/analyticUtils';

import { useCreateCustobarSubscription } from '../../hooks/useCreateCustobarSubscription';
import { getWindowPathName } from '../../../utils/windowUtils';
import { getUtmCampaignFromPath } from '../../../utils/custobarUtils';

const styles = theme => ({
  root: {},
  form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formField: {
    width: '100%',
    borderRadius: '0',
  },
  arrowButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.custobarSubscriptionForm.background,
    borderRadius: '0',
    height: '41px',
    '&:hover': {
      backgroundColor: theme.palette.custobarSubscriptionForm.hover.background,
    },
    '&:disabled': {
      backgroundColor: theme.palette.custobarSubscriptionForm.disabled.background,
    },
  },
});

function CustobarSubscriptionForm({ classes, data, pageData, subscribeNewsletterPageURL }) {
  const mailingLists = get(data, 'formType.custobarMailingLists');
  const { t } = useTranslation();
  const { mutateAsync: createCustobarSubscription } = useCreateCustobarSubscription();

  const path = getWindowPathName();

  let subscribePage = path;

  if (isEmpty(pageData)) {
    subscribePage = 'popup';
  }
  if (!isEmpty(get(pageData, 'fullPath')) && includes(path, subscribeNewsletterPageURL)) {
    subscribePage = 'newsletter_page';
  }
  if (!isEmpty(pageData) && isEmpty(get(pageData, 'fullPath'))) {
    subscribePage = 'footer';
  }

  const utmCampainID = getUtmCampaignFromPath();

  const handleFormSubmit = useCallback(
    async ({ emailAddress }, { resetForm }) => {
      try {
        await createCustobarSubscription({
          emailAddress,
          mailingLists,
          subscribePage,
          utmCampainID,
          subscribeNewsletterPageURL,
        });
        subscribeNewsletter();
        resetForm();
      } catch (error) {
        console.error(error);
      }
    },
    [createCustobarSubscription, mailingLists, subscribeNewsletterPageURL, subscribePage, utmCampainID],
  );

  const initialValues = useMemo(
    () => ({
      emailAddress: '',
    }),
    [],
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      emailAddress: Yup.string()
        .email(t('CustobarSubscriptionForm.errors.emailAddress.invalid'))
        .required(t('CustobarSubscriptionForm.errors.emailAddress.required')),
    });
  }, [t]);

  return (
    <div className={classes.root}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
        {({ isSubmitting, isSubmitted, isValidating, isValid, errors }) => {
          return (
            <Form>
              <FormControl
                className={classes.form}
                component="fieldset"
                error={Boolean(errors.email)}
                fullWidth
                required>
                <Field
                  id="emailAddress"
                  type="email"
                  name="emailAddress"
                  placeholder={t(`CustobarSubscriptionForm.fields.emailAddress.placeholder`)}
                  title={t(`CustobarSubscriptionForm.fields.emailAddress.placeholder`)}
                  component={TextField}
                  className={classes.formField}
                  disabled={isSubmitted}
                  required
                />
                <IconButton
                  disabled={isSubmitting || !isValid || isValidating}
                  type="submit"
                  color="inherit"
                  className={classes.arrowButton}>
                  <ArrowForwardIcon />
                </IconButton>
              </FormControl>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

CustobarSubscriptionForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageData: PropTypes.object,
  subscribeNewsletterPageURL: PropTypes.string,
};

CustobarSubscriptionForm.defaultProps = {
  classes: {},
  data: null,
  pageData: null,
  subscribeNewsletterPageURL: '',
};

export default withStyles(styles)(CustobarSubscriptionForm);
