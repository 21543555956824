import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';

import { get, split, isEmpty, trim } from 'lodash';
import { withStyles } from '@material-ui/core';
import Visible from '../../../shared/Visible';
import FileFontLoadorFactory from './FileFontLoadorFactory';

const styles = () => ({});

function FontLoader({ classes, className, rowData, columnData }) {
  // body font
  const rowBodyFontFamily = get(rowData, 'customRowStyle.bodyFontFamily.cssFontFamilyIdentifier');
  const rowBodyFontEmbedLink = get(rowData, 'customRowStyle.bodyFontFamily.fontEmbedLink.fontEmbedLink');
  const rowBodyFontFileUrl = get(rowData, 'customRowStyle.bodyFontFamily.fontFile.0.secure_url');
  const rowBodyfontFamilyLink = get(rowData, 'customRowStyle.bodyFontFamily.fontFamilyLink'); // depricated
  const rowBodyFontLink = !isEmpty(rowBodyFontEmbedLink) ? rowBodyFontEmbedLink : rowBodyfontFamilyLink;

  const columnBodyFontFamily = get(columnData, 'columnStyle.bodyFontFamily.cssFontFamilyIdentifier');
  const columnBodyFontEmbedLink = get(columnData, 'columnStyle.bodyFontFamily.fontEmbedLink.fontEmbedLink');
  const columnBodyFontFileUrl = get(columnData, 'columnStyle.bodyFontFamily.fontFile.0.secure_url');
  const columnBodyfontFamilyLink = get(columnData, 'columnStyle.bodyFontFamily.fontFamilyLink'); // depricated
  const columnBodyFontLink = !isEmpty(columnBodyFontEmbedLink) ? columnBodyFontEmbedLink : columnBodyfontFamilyLink;

  const bodyFontFamilyLink = !isEmpty(columnBodyFontLink) ? columnBodyFontLink : rowBodyFontLink;
  const bodyFontFileUrl = !isEmpty(columnBodyFontFileUrl) ? columnBodyFontFileUrl : rowBodyFontFileUrl;
  const bodyFontFamily = !isEmpty(columnBodyFontFamily) ? columnBodyFontFamily : rowBodyFontFamily;
  const bodyFontFaceName = useMemo(() => {
    const mainFont = split(bodyFontFamily, ',')[0];
    return trim(mainFont, "'");
  }, [bodyFontFamily]);

  // heading font
  const rowHeadingFontFamily = get(rowData, 'customRowStyle.headingFontFamily.cssFontFamilyIdentifier');
  const rowHeadingFontEmbedLink = get(rowData, 'customRowStyle.headingFontFamily.fontEmbedLink.fontEmbedLink');
  const rowHeadingFontFileUrl = get(rowData, 'customRowStyle.headingFontFamily.fontFile.0.secure_url');
  const rowHeadingfontFamilyLink = get(rowData, 'customRowStyle.headingFontFamily.fontFamilyLink'); // depricated
  const rowHeadingFontLink = !isEmpty(rowHeadingFontEmbedLink) ? rowHeadingFontEmbedLink : rowHeadingfontFamilyLink;

  const columnHeadingFontFamily = get(columnData, 'columnStyle.headingFontFamily.cssFontFamilyIdentifier');
  const columnHeadingFontEmbedLink = get(columnData, 'columnStyle.headingFontFamily.fontEmbedLink.fontEmbedLink');
  const columnHeadingFontFileUrl = get(columnData, 'columnStyle.headingFontFamily.fontFile.0.secure_url');
  const columnHeadingfontFamilyLink = get(columnData, 'columnStyle.headingFontFamily.fontFamilyLink'); // depricated
  const columnHeadingFontLink = !isEmpty(columnHeadingFontEmbedLink)
    ? columnHeadingFontEmbedLink
    : columnHeadingfontFamilyLink;

  const headingFontFamilyLink = !isEmpty(columnHeadingFontLink) ? columnHeadingFontLink : rowHeadingFontLink;
  const headingFontFileUrl = !isEmpty(columnHeadingFontFileUrl) ? columnHeadingFontFileUrl : rowHeadingFontFileUrl;
  const headingFontFamily = !isEmpty(columnHeadingFontFamily) ? columnHeadingFontFamily : rowHeadingFontFamily;
  const headingFontFaceName = useMemo(() => {
    const mainFont = split(headingFontFamily, ',')[0];
    return trim(mainFont, "'");
  }, [headingFontFamily]);

  const BodyFileFontLoador = FileFontLoadorFactory(bodyFontFaceName, bodyFontFileUrl);
  const HeadingFileFontLoador = FileFontLoadorFactory(headingFontFaceName, headingFontFileUrl);

  return (
    <div className={clsx(classes.root, className)}>
      <Visible hidden={!isEmpty(bodyFontFileUrl) || isEmpty(bodyFontFamilyLink)}>
        <Helmet>
          <link href={bodyFontFamilyLink} rel="stylesheet" />
        </Helmet>
      </Visible>

      <Visible hidden={!isEmpty(headingFontFileUrl) || isEmpty(headingFontFamilyLink)}>
        <Helmet>
          <link href={headingFontFamilyLink} rel="stylesheet" />
        </Helmet>
      </Visible>

      <Visible hidden={isEmpty(bodyFontFileUrl)}>
        <BodyFileFontLoador />
      </Visible>
      <Visible hidden={isEmpty(headingFontFileUrl)}>
        <HeadingFileFontLoador />
      </Visible>
    </div>
  );
}
FontLoader.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  columnData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rowData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loadFromFile: PropTypes.bool,
};

FontLoader.defaultProps = {
  classes: {},
  className: null,
  columnData: null,
  rowData: null,
  loadFromFile: false,
};

export default withStyles(styles)(FontLoader);
