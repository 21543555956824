import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { Container, withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '0',
    right: '0',
    transform: 'translate(-0%, -50%)',
    // color: 'white',
    zIndex: '20',
  },
  contentContainer: {
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  contentContainerCenter: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },

  description: {
    alignSelf: 'flex-end',
    padding: '20px',
    maxHeight: '500px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
    },
  },
  descriptionLeft: {
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
  descriptionCenter: {
    backgroundColor: 'transparent',
    [theme.breakpoints.up('sm')]: {},
  },

  alignCenter: {
    textAlign: 'center',
    color: ({ textColor }) => {
      if (textColor) {
        return textColor;
      }
      return null;
    },
  },
  alignLeft: {
    textAlign: 'left',
    color: ({ textColor }) => {
      if (textColor) {
        return textColor;
      }
      return null;
    },
  },
});

function HeroDescription({ classes, className, data, richText: RichText, descriptionPosition }) {
  const description = get(data, 'description');

  return (
    <div className={clsx(classes.root, className)}>
      <Container
        maxWidth="lg"
        className={clsx(
          classes.contentContainer,
          isEqual(descriptionPosition, 'center') ? classes.contentContainerCenter : null,
        )}>
        <div
          className={clsx(
            classes.description,
            isEqual(descriptionPosition, 'center') ? classes.descriptionCenter : classes.descriptionLeft,
          )}>
          {isEqual(descriptionPosition, 'center') && (
            <RichText
              textData={description}
              classes={{
                heading1: classes.alignCenter,
                heading2: classes.alignCenter,
                heading3: classes.alignCenter,
                heading4: classes.alignCenter,
                heading5: classes.alignCenter,
                heading6: classes.alignCenter,
                paragraph: classes.alignCenter,
                root: classes.richTextRoot,
              }}
            />
          )}
          {isEqual(descriptionPosition, 'left') && (
            <RichText
              textData={description}
              classes={{
                heading1: classes.alignLeft,
                heading2: classes.alignLeft,
                heading3: classes.alignLeft,
                heading4: classes.alignLeft,
                heading5: classes.alignLeft,
                heading6: classes.alignLeft,
                paragraph: classes.alignLeft,
                root: classes.richTextRoot,
              }}
            />
          )}
        </div>
      </Container>
    </div>
  );
}

HeroDescription.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.object,
  descriptionPosition: PropTypes.oneOf(['left', 'center']),
  textColor: PropTypes.string,
};

HeroDescription.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
  descriptionPosition: 'left',
  textColor: null,
};

export default withStyles(styles)(HeroDescription);
