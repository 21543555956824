import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map, last, isEmpty, includes, keys, size, isUndefined } from 'lodash';
import { withStyles, FormControl, FormLabel, CircularProgress, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { filterProducts, viewProducts } from '../../../../../utils/analyticUtils';
import { useFetchProductsByFilters } from '../../../../hooks/useFetchProductsByFilters';
import { useLocationFilters } from '../../../../hooks/useLocationFilters';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { breakPoints } from '../../../../../constants';

import Visible from '../../../Visible';
import ProductCard from '../../ProductCard';
import FilterInputSelect from '../FilterInputSelect';
import FilterInputRange from '../FilterInputRange';
import FiltersDropDown from '../FiltersDropDown';

const styles = theme => ({
  root: {},
  total: {
    marginBottom: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  search: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '200px',
      marginRight: theme.spacing(2),
    },
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  clear: {
    marginBottom: theme.spacing(2),
  },
  progress1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '400px',
    margin: theme.spacing(2, 0),
  },
  progress2: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  products: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  productCard: {
    justifyContent: 'flex-start',
  },
  productCards: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  more: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

function ProductSearch({ classes, className, channelData, defaultFilters }) {
  const [isMobile, setIsMobile] = useState(true);
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const { locationFilters, updateLocationFilters } = useLocationFilters(defaultFilters);
  const {
    data: productData,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useFetchProductsByFilters(channelData, defaultFilters, locationFilters);

  const hasProducts = useMemo(() => !isEmpty(get(productData, 'pages.0.products')), [productData]);
  const filterInputs = useMemo(() => get(productData, `pages.0.filters`), [productData]);

  useEffect(() => {
    viewProducts(get(last(get(productData, 'pages')), 'products'));
  }, [productData]);

  useEffect(() => {
    if (!isUndefined(width)) {
      if (width > breakPoints.md) {
        setIsMobile(false);
      }
      if (width < breakPoints.md) {
        setIsMobile(true);
      }
    }
    return () => {
      return null;
    };
  }, [width]);

  const handleFilterChange = useCallback(
    event => {
      const filterId = get(event, 'target.name');
      const filterValue = get(event, 'target.value');
      updateLocationFilters({ [filterId]: filterValue });
      filterProducts();
    },
    [updateLocationFilters],
  );

  const handleFormSubmit = useCallback(event => {
    event.preventDefault();
  }, []);

  const handleClearClick = useCallback(() => {
    updateLocationFilters(null);
    filterProducts();
  }, [updateLocationFilters]);

  const handleMoreClick = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className={clsx(classes.root, className)}>
      <Visible visible={!isMobile}>
        <Typography variant="h2" className={classes.total}>
          {`${get(productData, 'pages.0.totalCount', '0')} ${t(`ProductSearch.products.total.label`)}`}
        </Typography>
      </Visible>
      <div className={classes.search}>
        <Visible visible={!isMobile}>
          <form className={classes.form} onSubmit={handleFormSubmit}>
            <Button variant="outlined" className={classes.clear} onClick={handleClearClick}>
              {t(`ProductSearch.actions.clear.label`)}
            </Button>
            <Visible visible={isFetching}>
              <div className={classes.progress1}>
                <CircularProgress />
              </div>
            </Visible>
            <Visible visible={!isFetching}>
              <FormControl className={classes.formControl}>
                <FormLabel htmlFor="monopoly_price">{t(`ProductSearch.filters.monopoly_price.label`)}</FormLabel>
                <FilterInputRange
                  name="price"
                  value={get(locationFilters, 'price', [])}
                  onChange={handleFilterChange}
                  disabled
                />
              </FormControl>
              {map(filterInputs, filterInput => {
                const filterId = get(filterInput, 'attribute_code');
                const filterOptions = get(filterInput, 'options');
                const hasOptions = !isEmpty(filterOptions);
                const isDefaultFilter = includes(keys(defaultFilters), filterId);
                return (
                  <Visible key={filterId} visible={hasOptions && !isDefaultFilter}>
                    <FormControl className={classes.formControl}>
                      <FormLabel htmlFor={filterId}>{t(`ProductSearch.filters.${filterId}.label`)}</FormLabel>
                      <FilterInputSelect
                        name={filterId}
                        value={get(locationFilters, filterId, '')}
                        options={filterOptions}
                        onChange={handleFilterChange}
                      />
                    </FormControl>
                  </Visible>
                );
              })}
            </Visible>
            <Button variant="outlined" className={classes.clear} onClick={handleClearClick}>
              {t(`ProductSearch.actions.clear.label`)}
            </Button>
          </form>
        </Visible>
        <Visible visible={isMobile}>
          <FiltersDropDown
            title={
              <Typography variant="h2" className={classes.total}>
                {`${get(productData, 'pages.0.totalCount', '0')} ${t(`ProductSearch.products.total.label`)}`}
              </Typography>
            }>
            <form className={classes.form} onSubmit={handleFormSubmit}>
              <Button variant="outlined" className={classes.clear} onClick={handleClearClick}>
                {t(`ProductSearch.actions.clear.label`)}
              </Button>
              <Visible visible={isFetching}>
                <div className={classes.progress1}>
                  <CircularProgress />
                </div>
              </Visible>
              <Visible visible={!isFetching}>
                <FormControl className={classes.formControl}>
                  <FormLabel htmlFor="monopoly_price">{t(`ProductSearch.filters.monopoly_price.label`)}</FormLabel>
                  <FilterInputRange
                    name="price"
                    value={get(locationFilters, 'monopoly_price', [])}
                    onChange={handleFilterChange}
                    disabled
                  />
                </FormControl>
                {map(filterInputs, filterInput => {
                  const filterId = get(filterInput, 'attribute_code');
                  const filterOptions = get(filterInput, 'options');
                  const hasOptions = !isEmpty(filterOptions);
                  const isDefaultFilter = includes(keys(defaultFilters), filterId);
                  return (
                    <Visible key={filterId} visible={hasOptions && !isDefaultFilter}>
                      <FormControl className={classes.formControl}>
                        <FormLabel htmlFor={filterId}>{t(`ProductSearch.filters.${filterId}.label`)}</FormLabel>
                        <FilterInputSelect
                          name={filterId}
                          value={get(locationFilters, filterId, '')}
                          options={filterOptions}
                          onChange={handleFilterChange}
                        />
                      </FormControl>
                    </Visible>
                  );
                })}
              </Visible>
              <Button variant="outlined" className={classes.clear} onClick={handleClearClick}>
                {t(`ProductSearch.actions.clear.label`)}
              </Button>
            </form>
          </FiltersDropDown>
        </Visible>
        <div className={classes.products}>
          <Visible visible={!isFetching && !hasProducts}>
            <Typography className={classes.more}>{t(`ProductSearch.products.empty.text`)}</Typography>
          </Visible>
          {map(get(productData, 'pages'), (productPage, pageIndex) => {
            const productCount = size(get(productPage, 'products'));
            return (
              <React.Fragment key={pageIndex}>
                {map(get(productPage, 'products'), product => (
                  <ProductCard
                    key={get(product, 'id')}
                    productData={product}
                    classes={{ root: productCount < 2 ? classes.productCard : classes.productCards }}
                  />
                ))}
              </React.Fragment>
            );
          })}
          <Visible visible={isFetching}>
            <div className={classes.progress2}>
              <CircularProgress />
            </div>
          </Visible>
          <Visible visible={!isFetching && hasProducts && hasNextPage}>
            <div className={classes.more}>
              <Button variant="outlined" onClick={handleMoreClick}>
                {t(`ProductSearch.products.more.label`)}
              </Button>
            </div>
          </Visible>
        </div>
      </div>
    </div>
  );
}

ProductSearch.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  channelData: PropTypes.object,
  defaultFilters: PropTypes.object,
};

ProductSearch.defaultProps = {
  classes: {},
  className: null,
  channelData: null,
  defaultFilters: {},
};

export default withStyles(styles)(ProductSearch);
