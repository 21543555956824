import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';

import { Divider, Typography, withStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';

import Link from '../../../navigation/Link';
import Visible from '../../Visible';
import ThumbnailImage from '../../ThumbnailImage';
import Categories from '../../../page/Categories';
import Button from '../../Button';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,

    wordBreak: 'break-word',
    hyphens: 'auto',
    padding: theme.spacing(3, 1),

    flexBasis: ({ elementsPerRowMobile, gap }) => {
      if (isEmpty(gap)) {
        return `calc(100%  / ${elementsPerRowMobile})`;
      }
      const totalGap = (elementsPerRowMobile - 1) * gap;
      return `calc((100% - ${totalGap}px) / ${elementsPerRowMobile})`;
    },
    maxWidth: ({ elementsPerRowMobile, gap }) => {
      if (isEmpty(gap)) {
        return `calc(100%  / ${elementsPerRowMobile})`;
      }
      const totalGap = (elementsPerRowMobile - 1) * gap;
      return `calc((100% - ${totalGap}px) / ${elementsPerRowMobile})`;
    },

    [theme.breakpoints.up('sm')]: {
      flexBasis: ({ elementsPerRowTablet, gap }) => {
        if (isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowTablet})`;
        }
        const totalGap = (elementsPerRowTablet - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowTablet})`;
      },
      maxWidth: ({ elementsPerRowTablet, gap }) => {
        if (isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowTablet})`;
        }
        const totalGap = (elementsPerRowTablet - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowTablet})`;
      },
      padding: theme.spacing(3, 1.5),
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: ({ elementsPerRowDesktop, gap }) => {
        if (isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowDesktop})`;
        }
        const totalGap = (elementsPerRowDesktop - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowDesktop})`;
      },
      maxWidth: ({ elementsPerRowDesktop, gap }) => {
        if (isEmpty(gap)) {
          return `calc(100%  / ${elementsPerRowDesktop})`;
        }
        const totalGap = (elementsPerRowDesktop - 1) * gap;
        return `calc((100% - ${totalGap}px) / ${elementsPerRowDesktop})`;
      },
      padding: theme.spacing(3, 1.5),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageWrapper: {
    color: theme.palette.text.primary,
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: ' space-between',
    backgroundColor: '#fbf3e9',
    padding: '0 12px 12px 12px',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: '0 24px 24px 24px',
    },
  },

  title: {
    color: '#333333',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3, 0, 2, 0),
    },
  },
  image: {},
  divider: {
    backgroundColor: theme.palette.common.lightGray,
    marginBottom: '6px',
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  shortDescription: {
    fontSize: '1rem',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  linkButton: {
    width: 'auto',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'flex-cennter',
  },
  imageTitle: {},
  listItemText: {},
  dividerSmall: {},
  listItem: {},
  imagesOnlyTitle: {},
  imageFull: {},
});

function CardWithText({
  classes,
  className,
  richText: RichText,
  image,
  title,
  categories,
  description,
  fullPath,
  transformation,
  width,
  height,
  hideImage,
  hideTitle,
  hideDescription,
  hideDivider,
  hideCategories,
  roundImage,
  endIcon,
  startIcon,
}) {
  const { t } = useTranslation();

  const linkButtonText = t('ContentList.ReadMoreLinkButton.default');

  const linkStyles = isEmpty(fullPath) ? { textDecoration: 'none' } : {};

  return (
    <article className={clsx(classes.root, className)}>
      <header to={fullPath} className={classes.header}>
        <Link to={fullPath} className={classes.imageWrapper} style={linkStyles}>
          <Visible hidden={hideImage}>
            <ThumbnailImage
              className={classes.image}
              data={image}
              transformation={transformation}
              width={width}
              height={height}
              circle={roundImage}
            />
          </Visible>
        </Link>
      </header>
      <div className={classes.content}>
        <Visible hidden={hideTitle}>
          <Link to={fullPath}>
            <Typography variant="h3" className={classes.title} align="center">
              {title}
            </Typography>
          </Link>
        </Visible>

        <Visible hidden={hideDivider}>
          <Divider className={classes.divider} />
        </Visible>
        <Visible hidden={hideCategories}>
          <Categories categoriesData={categories} className={classes.tags} />
        </Visible>
        <Visible hidden={hideDescription}>
          <Visible visible={isObject(description) && !isEmpty(description)}>
            {description && <RichText textData={description} className={classes.shortDescription} />}
          </Visible>
          <Visible visible={isString(description) && !isEmpty(description)}>
            <Typography variant="body2" className={classes.shortDescription}>
              {description}
            </Typography>
          </Visible>
        </Visible>
        <Visible hidden={isEmpty(fullPath)}>
          <Button
            to={fullPath}
            className={classes.linkButton}
            variant="contained"
            startIcon={startIcon}
            endIcon={endIcon}>
            {linkButtonText}
          </Button>
          {}
        </Visible>
      </div>
    </article>
  );
}

CardWithText.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  richText: PropTypes.any,
  image: PropTypes.object,
  title: PropTypes.string,
  categories: PropTypes.array,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullPath: PropTypes.string,
  pageType: PropTypes.string,
  transformation: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  elementsPerRowDesktop: PropTypes.number,
  elementsPerRowTablet: PropTypes.number,
  elementsPerRowMobile: PropTypes.number,
  hideImage: PropTypes.bool,
  hideTitle: PropTypes.bool,
  hideDescription: PropTypes.bool,
  hideDivider: PropTypes.bool,
  hideCategories: PropTypes.bool,
  roundImage: PropTypes.bool,
  gap: PropTypes.number,
  endIcon: PropTypes.element,
  startIcon: PropTypes.element,
};

CardWithText.defaultProps = {
  classes: {},
  className: null,
  image: null,
  richText: '',
  title: null,
  categories: null,
  description: '',
  fullPath: null,
  pageType: null,
  transformation: 'CardImage-MD-MD',
  width: '100%',
  height: '230',
  hideImage: false,
  hideTitle: false,
  hideDescription: true,
  hideDivider: false,
  hideCategories: false,
  elementsPerRowDesktop: 4,
  elementsPerRowTablet: 2,
  elementsPerRowMobile: 2,
  roundImage: false,
  gap: null,
  endIcon: null,
  startIcon: <InfoOutlined />,
};

export default withStyles(styles)(CardWithText);
