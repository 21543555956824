import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { camelCase, get, includes, toLower } from 'lodash';
import { withStyles } from '@material-ui/core';
import { Twitter, LinkedIn, YouTube, Facebook, Email, Instagram } from '@material-ui/icons';

import { getWindowHref } from '../../../../utils/windowUtils';

import IconButton from '../../../navigation/IconButton';

const styles = () => ({
  root: {
    color: 'inherit',
    '@media print': {
      display: 'none',
    },
  },
  icon: {
    fontSize: '2rem',
    color: 'inherit',
  },
});

function Icon({ classes, title }) {
  switch (title) {
    case 'twitter': {
      return <Twitter className={classes.icon} />;
    }
    case 'linkedIn': {
      return <LinkedIn className={classes.icon} />;
    }
    case 'instagram': {
      return <Instagram className={classes.icon} />;
    }
    case 'youtube': {
      return <YouTube className={classes.icon} />;
    }
    case 'facebook': {
      return <Facebook className={classes.icon} />;
    }
    case 'email': {
      return <Email className={classes.icon} />;
    }
    default:
      return <Email className={classes.icon} />;
  }
}

Icon.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
};

Icon.defaultProps = {
  classes: {},
  title: null,
};

function SocialMediaElementModule({ classes, className, data }) {
  const title = camelCase(get(data, 'title'));
  let socialMediaLink = get(data, 'socialMediaLink');

  if (includes(toLower(title), 'share')) {
    socialMediaLink = `${socialMediaLink}${getWindowHref()}`;
  }

  return (
    <IconButton to={socialMediaLink} target="_blank" className={clsx(classes.root, className)}>
      <Icon title={title} classes={classes} />
    </IconButton>
  );
}

SocialMediaElementModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

SocialMediaElementModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(SocialMediaElementModule);
