import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { withStyles } from '@material-ui/core';

import RichText from '../../../shared/RichText';

const styles = () => ({
  root: {
    display: 'block',
  },
  content: {},
  container: {},
});

function Content({ classes, className, pageData }) {
  const content = get(pageData, 'content');

  return (
    <div className={clsx(classes.root, className)}>
      <RichText textData={content} pageData={pageData} addContainer />
    </div>
  );
}

Content.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Content.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(Content);
