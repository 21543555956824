import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaLatestArticlesModule from './viinimaa';

function LatestArticlesModule(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaLatestArticlesModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaLatestArticlesModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return <ViinimaaLatestArticlesModule gap="40" {...props} />;
  }
  if (isEqual(siteId, siteIds.OpAnderson)) {
    return <ViinimaaLatestArticlesModule elementsPerRowMobile={2} arrowButtonVariant="contained" {...props} />;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <ViinimaaLatestArticlesModule elementsPerRowMobile={2} arrowButtonVariant="contained" {...props} />;
  }
  if (isEqual(siteId, siteIds.WineworldFinland)) {
    return <ViinimaaLatestArticlesModule elementsPerRowMobile={2} arrowButtonVariant="contained" {...props} />;
  }
  if (brandSite) {
    return <ViinimaaLatestArticlesModule {...props} />;
  }

  return null;
}

export default LatestArticlesModule;
