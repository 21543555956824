import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles, ClickAwayListener } from '@material-ui/core';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { FiberManualRecord } from '@material-ui/icons';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import Link from '../../navigation/Link';
import Visible from '../Visible';

const styles = theme => ({
  root: {
    position: 'absolute',
    display: 'block',
    transform: 'translate(-50%,50%)',
    bottom: ({ spotLinkData }) => {
      const linksVerticalCoordinateMobile = get(spotLinkData, 'verticalCoordinateMobile');

      if (!isEmpty(linksVerticalCoordinateMobile)) {
        return linksVerticalCoordinateMobile;
      }
      return '0';
    },
    left: ({ spotLinkData }) => {
      const linksHorizontalCoordinateMobile = get(spotLinkData, 'horizontalCoordinateMobile');

      if (!isEmpty(linksHorizontalCoordinateMobile)) {
        return linksHorizontalCoordinateMobile;
      }
      return '0';
    },
    [theme.breakpoints.up('md')]: {
      bottom: ({ spotLinkData }) => {
        const linksVerticalCoordinateDesktop = get(spotLinkData, 'verticalCoordinateDesktop');

        if (!isEmpty(linksVerticalCoordinateDesktop)) {
          return linksVerticalCoordinateDesktop;
        }
        return '0';
      },
      left: ({ spotLinkData }) => {
        const linksHorizontalCoordinateDesktop = get(spotLinkData, 'horizontalCoordinateDesktop');

        if (!isEmpty(linksHorizontalCoordinateDesktop)) {
          return linksHorizontalCoordinateDesktop;
        }
        return '0';
      },
    },
    '@media print': {
      display: 'none',
    },
  },
  wrapper: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '50%',
    padding: '0',

    '&:hover #_dotIcon': {
      transform: 'scale(0.8)',
    },
  },

  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)',
    },

    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
    },

    ' 100%': {
      transform: ' scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
  },

  outerDot: {
    width: '35px',
    height: '35px',
    backgroundColor: 'rgba(84, 84, 84,0.6)',
    borderRadius: '50%',
    position: 'relative',
    '&:hover ': {
      border: 'solid 1px white',
    },

    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',

    boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
    transform: 'scale(1)',
    animation: '$pulse 2s infinite',
  },
  innerDot: {
    ...theme.imageSpotLink.innerDot,
    fontSize: '1.4rem',
    position: 'absolute',
    transition: 'all 0.2s ease',
  },
  caption: {
    padding: theme.spacing(1),
    '& > p, a, span, h2, h3, h4, h5': {
      color: theme.palette.text.primary,
    },
  },
});

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.default,
      maxWidth: 220,
      boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.16)',
      padding: '0',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.background.default,
    },
  }),
);

// eslint-disable-next-line react/prop-types
function DotOnImage({ classes, className, data, spotLinkData, richText: RichText }) {
  const [open, setOpen] = React.useState(false);

  const linksUrl = get(spotLinkData, 'url');

  const linksContent = get(spotLinkData, 'content');
  const openLinkTo = get(data, 'openLinkTo');
  if (isEmpty(linksUrl) && isEmpty(linksContent)) {
    return null;
  }
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(prev => !prev);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <HtmlTooltip
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="bottom-start"
            title={<div>{linksContent && <RichText textData={linksContent} className={classes.caption} />}</div>}>
            <div className={classes.animate}>
              <Visible visible={isEmpty(linksContent)}>
                <Link to={linksUrl} target={!openLinkTo ? '_blank' : ''} className={classes.wrapper}>
                  <div className={classes.outerDot}>
                    <FiberManualRecord className={classes.innerDot} id="_dotIcon" />
                  </div>
                </Link>
              </Visible>
              <Visible visible={!isEmpty(linksContent)}>
                <button type="button" className={classes.wrapper} onClick={handleTooltipOpen}>
                  <div className={classes.outerDot}>
                    <FiberManualRecord className={classes.innerDot} id="_dotIcon" />
                  </div>
                </button>
              </Visible>
            </div>
          </HtmlTooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
}

DotOnImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  spotLinkData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

DotOnImage.defaultProps = {
  classes: {},
  className: null,
  spotLinkData: null,
  data: null,
};

export default withStyles(styles)(DotOnImage);
