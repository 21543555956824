import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box, Card, CardActionArea, ListItemIcon, Typography, withStyles } from '@material-ui/core';

import ImageMissing from './ImageMissing';
import Price from '../../SearchResultItemPrice';

const styles = theme => ({
  root: {
    boxShadow: 'none',
    width: '100%',
  },
  actionArea: {
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: theme.spacing(2, 0),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  thumbnailImage: {
    boxSizing: 'content-box',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 3, 0, 0),
    width: ({ imageHeight }) => {
      return `${imageHeight}px`;
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2, 0, 0),
    },
  },
  textContainer: {
    flexGrow: 1,
  },
  countryOfOrigin: {
    color: theme.palette.common.mediumGray,
  },
  title: {},
});

function ListItem({ classes, imageHeight, item, placeholderImage, settings }) {
  if (!item) return null;

  const imageUrl = get(item, 'imageUrl') || get(settings, ['klevu_uc_userOptions', 'noImageUrl']) || placeholderImage;
  const name = get(item, 'name');
  const url = get(item, 'url');
  const countryOfOrigin = get(item, 'country_of_origin');

  const shortDescription = get(item, 'shortDesc');

  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.actionArea} href={url}>
        <ListItemIcon className={classes.thumbnailImage}>
          {imageUrl ? (
            <img src={imageUrl} alt={name} width="auto" height={imageHeight} />
          ) : (
            <ImageMissing height={imageHeight} />
          )}
        </ListItemIcon>
        <Box className={classes.textContainer}>
          <Typography variant="h4" className={classes.title} style={{ marginBottom: '5px' }}>
            {name}
          </Typography>
          {countryOfOrigin ? (
            <Typography variant="body2" color="secondary" className={classes.countryOfOrigin}>
              {countryOfOrigin}
            </Typography>
          ) : (
            <Typography variant="body2">{shortDescription}</Typography>
          )}

          <Price item={item} settings={settings} />
        </Box>
      </CardActionArea>
    </Card>
  );
}

ListItem.propTypes = {
  classes: PropTypes.object,
  imageHeight: PropTypes.number,
  item: PropTypes.object,
  placeholderImage: PropTypes.string,
  settings: PropTypes.object,
};

ListItem.defaultProps = {
  classes: {},
  imageHeight: 80,
  item: {
    currency: '',
    imageUrl: '',
    name: '',
    salePrice: '',
  },
  placeholderImage: '',
  settings: {},
};

export default withStyles(styles)(ListItem);
