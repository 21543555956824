import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { withStyles } from '@material-ui/core';

import ProductSearch from '../../../shared/Products/ProductSearch';
import { isSSR } from '../../../../utils/windowUtils';
import { breakPoints } from '../../../../constants';

const styles = theme => ({
  root: {},
  minusMargin: {
    [theme.breakpoints.up('1000')]: {
      margin: '0 -100px',
    },
    [theme.breakpoints.up('1240')]: {
      margin: '0 -220px',
    },
  },
  noMinusMargin: {
    margin: '0',
  },
});

function SearchModule({ classes, className, pageData }) {
  const contentListRef = useRef(null);
  const [enableMinusMargin, setEnableMinusMargin] = useState(true);

  const primaryChannel = get(pageData, 'primaryChannel');

  const setMargin = () => {
    const moduleWidth = get(contentListRef, 'current.clientWidth');
    if (moduleWidth > breakPoints.lg) {
      setEnableMinusMargin(false);
    }
  };

  useEffect(() => {
    if (!isSSR()) {
      setMargin();
      window.addEventListener('resize', setMargin);
    }
    return () => {};
  }, [contentListRef]);

  return (
    <div
      className={clsx(classes.root, className, enableMinusMargin ? classes.minusMargin : classes.noMinusMargin)}
      ref={contentListRef}>
      <ProductSearch channelData={primaryChannel} />
    </div>
  );
}

SearchModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

SearchModule.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  data: null,
};

export default withStyles(styles)(SearchModule);
