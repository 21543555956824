import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { get, isEqual, isNil, merge } from 'lodash';
import { ThemeProvider } from '@material-ui/core';

import { siteIds } from '../constants';
import { siteId } from '../sites';

import viinimaaSecondaryThemes from './viinimaa/secondaryThemes';
import folkofolkSecondaryThemes from './folkofolk/secondaryThemes';

function getSecondaryTheme(secondaryThemeType) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return get(viinimaaSecondaryThemes, secondaryThemeType);
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return get(folkofolkSecondaryThemes, secondaryThemeType);
  }

  return null;
}

function SecondaryThemeProvider({ children, secondaryThemeType, customTheme }) {
  const secondaryTheme = getSecondaryTheme(secondaryThemeType);

  const getTheme = useCallback(
    primaryTheme => {
      return merge({}, primaryTheme, secondaryTheme, customTheme);
    },
    [secondaryTheme, customTheme],
  );

  if (isNil(secondaryTheme) && isNil(customTheme)) {
    return children;
  }

  return <ThemeProvider theme={getTheme}>{children}</ThemeProvider>;
}

SecondaryThemeProvider.propTypes = {
  children: PropTypes.node,
  secondaryThemeType: PropTypes.string,
  customTheme: PropTypes.object,
};

SecondaryThemeProvider.defaultProps = {
  children: null,
  secondaryThemeType: null,
  customTheme: null,
};

export default SecondaryThemeProvider;
