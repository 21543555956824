import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Typography, withStyles } from '@material-ui/core';
import { get } from 'lodash';

const styles = () => ({
  root: {},
});

function PreparationStepModule({ classes, className, data }) {
  return (
    <div className={clsx(classes.root, className)}>
      <Typography>{get(data, 'preparationStep')}</Typography>
    </div>
  );
}

PreparationStepModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.object,
};

PreparationStepModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: PropTypes.object,
};

export default withStyles(styles)(PreparationStepModule);
