import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { get, has, includes, isEmpty, isEqual, replace, toNumber } from 'lodash';
import { useTheme } from '@material-ui/core';
import SecondaryThemeProvider from '../../../themes/SecondaryThemeProvider';
import FontLoader from './FontLoader';

const calculatedFontSize = (value, multiplier) => {
  let unit;
  let size;

  if (!value) {
    return null;
  }
  if (includes(value, 'rem')) {
    size = replace(value, 'rem', '');
    unit = 'rem';
  } else if (includes(value, 'em')) {
    size = replace(value, 'em', '');
    unit = 'em';
  } else if (includes(value, 'px')) {
    size = replace(value, 'px', '');
    unit = 'px';
  }
  const calculatedValue = toNumber(size) * multiplier;
  return `${calculatedValue}${unit}`;
};

function withSecondaryTheme(Component) {
  return props => {
    let pageType;

    const data = get(props, 'data');
    if (has(data, 'contentfulLandingPage')) {
      pageType = 'contentfulLandingPage';
    } else if (has(data, 'contentfulAdvancedPage')) {
      pageType = 'contentfulAdvancedPage';
    } else if (has(data, 'contentfulArticlePage')) {
      pageType = 'contentfulArticlePage';
    } else if (has(data, 'contentfulRecipePage')) {
      pageType = 'contentfulRecipePage';
    } else if (has(data, 'contentfulCategoryPage')) {
      pageType = 'contentfulArticlePage';
    } else if (has(data, 'contentfulCategory')) {
      pageType = 'contentfulCategory';
    } else if (has(data, 'contentfulBrand')) {
      pageType = 'contentfulBrand';
    } else if (has(data, 'magentoProduct')) {
      pageType = 'magentoProduct';
    } else if (has(data, 'contentfulCampaign')) {
      pageType = 'contentfulCampaign';
    }

    const mainTheme = get(props, `data.${pageType}.mainTheme`);
    const themeStyles = get(mainTheme, 'themeStyles');

    const alternativeTheme = get(props, `data.${pageType}.alternativeTheme`);
    const alternativeThemeStyles = get(alternativeTheme, 'themeStyles');

    const alternativeThemeType = get(alternativeTheme, 'themeType.0');
    const hasSubThemeStyles = !isEmpty(alternativeThemeStyles) && isEqual(alternativeThemeType, 'Sub-theme');

    const theme = useTheme();

    let fontsThemeStyles = themeStyles;
    if (hasSubThemeStyles) {
      if (
        !isEmpty(get(alternativeThemeStyles, 'fontFamilyText')) &&
        !isEmpty(get(alternativeThemeStyles, 'fontFamilyTitle'))
      ) {
        fontsThemeStyles = {
          ...themeStyles,
          fontFamilyText: get(alternativeThemeStyles, 'fontFamilyText'),
          fontFamilyTitle: get(alternativeThemeStyles, 'fontFamilyTitle'),
        };
      } else if (!isEmpty(get(alternativeThemeStyles, 'fontFamilyText'))) {
        fontsThemeStyles = {
          ...themeStyles,
          fontFamilyText: get(alternativeThemeStyles, 'fontFamilyText'),
        };
      } else if (!isEmpty(get(alternativeThemeStyles, 'fontFamilyTitle'))) {
        fontsThemeStyles = {
          ...themeStyles,
          fontFamilyTitle: get(alternativeThemeStyles, 'fontFamilyTitle'),
        };
      }
    }

    const customTheme = useMemo(() => {
      let primaryBrandColor = get(themeStyles, 'primaryBrandColor');
      if (hasSubThemeStyles && !isEmpty(get(alternativeThemeStyles, 'primaryBrandColor'))) {
        primaryBrandColor = get(alternativeThemeStyles, 'primaryBrandColor');
      }

      let linkColor = get(themeStyles, 'linkColor');
      if (hasSubThemeStyles && !isEmpty(get(alternativeThemeStyles, 'linkColor'))) {
        linkColor = get(alternativeThemeStyles, 'linkColor');
      }

      let titleColor = get(themeStyles, 'titleColor');
      if (hasSubThemeStyles && !isEmpty(get(alternativeThemeStyles, 'titleColor'))) {
        titleColor = get(alternativeThemeStyles, 'titleColor');
      }

      let textColor = get(themeStyles, 'textColor');
      if (hasSubThemeStyles && !isEmpty(get(alternativeThemeStyles, 'textColor'))) {
        textColor = get(alternativeThemeStyles, 'textColor');
      }

      let fontSizeText = get(themeStyles, 'fontSizeText');
      if (hasSubThemeStyles && !isEmpty(get(alternativeThemeStyles, 'fontSizeText'))) {
        fontSizeText = get(alternativeThemeStyles, 'fontSizeText');
      }

      let fontSizeTitle = get(themeStyles, 'fontSizeTitle');
      if (hasSubThemeStyles && !isEmpty(get(alternativeThemeStyles, 'fontSizeTitle'))) {
        fontSizeTitle = get(alternativeThemeStyles, 'fontSizeTitle');
      }

      let fontFamilyText = get(themeStyles, 'fontFamilyText.cssFontFamilyIdentifier');
      if (hasSubThemeStyles && !isEmpty(get(alternativeThemeStyles, 'fontFamilyText.cssFontFamilyIdentifier'))) {
        fontFamilyText = get(alternativeThemeStyles, 'fontFamilyText.cssFontFamilyIdentifier');
      }

      let fontFamilyTitle = get(themeStyles, 'fontFamilyTitle.cssFontFamilyIdentifier');
      if (hasSubThemeStyles && !isEmpty(get(alternativeThemeStyles, 'fontFamilyTitle.cssFontFamilyIdentifier'))) {
        fontFamilyTitle = get(alternativeThemeStyles, 'fontFamilyTitle.cssFontFamilyIdentifier');
      }

      return {
        imageSpotLink: {
          innerDot: {
            ...(primaryBrandColor ? { color: primaryBrandColor } : { color: 'white' }),
          },
        },
        overrides: {
          MuiLink: {
            root: {
              ...(linkColor ? { color: linkColor } : {}),
              ...(fontFamilyText ? { fontFamily: fontFamilyText } : {}),
            },
          },
          MuiTypography: {
            body1: {
              ...(fontSizeText ? { fontSize: fontSizeText } : {}),
              ...(textColor ? { color: textColor } : {}),
              ...(fontFamilyText ? { fontFamily: fontFamilyText } : {}),
              [theme.breakpoints.up('md')]: {
                ...(fontSizeText ? { fontSize: fontSizeText } : {}),
                ...(textColor ? { color: textColor } : {}),
                ...(fontFamilyText ? { fontFamily: fontFamilyText } : {}),
              },
            },
            body2: {
              ...(fontSizeText ? { fontSize: calculatedFontSize(fontSizeText, 0.8) } : {}),
              ...(textColor ? { color: textColor } : {}),
              ...(fontFamilyText ? { fontFamily: fontFamilyText } : {}),
              [theme.breakpoints.up('md')]: {
                ...(fontSizeText ? { fontSize: calculatedFontSize(fontSizeText, 0.8) } : {}),
                ...(textColor ? { color: textColor } : {}),
                ...(fontFamilyText ? { fontFamily: fontFamilyText } : {}),
              },
            },
            subtitle1: {
              ...(textColor ? { color: textColor } : {}),
              ...(fontFamilyText ? { fontFamily: fontFamilyText } : {}),
            },
            subtitle2: {
              ...(textColor ? { color: textColor } : {}),
              ...(fontFamilyText ? { fontFamily: fontFamilyText } : {}),
            },
            overline: {
              ...(textColor ? { color: textColor } : {}),
              ...(fontFamilyText ? { fontFamily: fontFamilyText } : {}),
            },
            caption: {
              ...(textColor ? { color: textColor } : {}),
              ...(fontFamilyText ? { fontFamily: fontFamilyText } : {}),
            },
            h1: {
              ...(titleColor ? { color: titleColor } : {}),
              ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
              ...(fontSizeTitle ? { fontSize: fontSizeTitle } : {}),
              [theme.breakpoints.up('md')]: {
                ...(titleColor ? { color: titleColor } : {}),
                ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
                ...(fontSizeTitle ? { fontSize: fontSizeTitle } : {}),
              },
            },
            h2: {
              ...(titleColor ? { color: titleColor } : {}),
              ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
              ...(fontSizeTitle ? { fontSize: calculatedFontSize(fontSizeTitle, 0.7) } : {}),
              [theme.breakpoints.up('md')]: {
                ...(titleColor ? { color: titleColor } : {}),
                ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
                ...(fontSizeTitle ? { fontSize: calculatedFontSize(fontSizeTitle, 0.7) } : {}),
              },
            },
            h3: {
              ...(titleColor ? { color: titleColor } : {}),
              ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
              ...(fontSizeTitle ? { fontSize: calculatedFontSize(fontSizeTitle, 0.6) } : {}),
              [theme.breakpoints.up('md')]: {
                ...(titleColor ? { color: titleColor } : {}),
                ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
                ...(fontSizeTitle ? { fontSize: calculatedFontSize(fontSizeTitle, 0.6) } : {}),
              },
            },
            h4: {
              ...(titleColor ? { color: titleColor } : {}),
              ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
              ...(fontSizeTitle ? { fontSize: calculatedFontSize(fontSizeTitle, 0.5) } : {}),
              [theme.breakpoints.up('md')]: {
                ...(titleColor ? { color: titleColor } : {}),
                ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
                ...(fontSizeTitle ? { fontSize: calculatedFontSize(fontSizeTitle, 0.5) } : {}),
              },
            },
            h5: {
              ...(titleColor ? { color: titleColor } : {}),
              ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
              ...(fontSizeTitle ? { fontSize: calculatedFontSize(fontSizeTitle, 0.4) } : {}),
              [theme.breakpoints.up('md')]: {
                ...(titleColor ? { color: titleColor } : {}),
                ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
                ...(fontSizeTitle ? { fontSize: calculatedFontSize(fontSizeTitle, 0.4) } : {}),
              },
            },
            h6: {
              ...(titleColor ? { color: titleColor } : {}),
              ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
              ...(fontSizeText ? { fontSize: fontSizeText } : {}),
              [theme.breakpoints.up('md')]: {
                ...(titleColor ? { color: titleColor } : {}),
                ...(fontFamilyTitle ? { fontFamily: fontFamilyTitle } : {}),
                ...(fontSizeText ? { fontSize: fontSizeText } : {}),
              },
            },
          },
          MuiButton: {
            containedPrimary: {
              ...(primaryBrandColor ? { backgroundColor: primaryBrandColor } : {}),
              '&:hover': {
                ...(primaryBrandColor ? { borderColor: primaryBrandColor } : {}),
                '@media (hover: none)': {
                  ...(primaryBrandColor ? { borderColor: primaryBrandColor } : {}),
                },
              },
            },
            outlinedPrimary: {
              ...(primaryBrandColor ? { borderColor: primaryBrandColor } : {}),
              ...(primaryBrandColor ? { color: primaryBrandColor } : {}),
              '&:hover': {
                ...(primaryBrandColor ? { backgroundColor: primaryBrandColor } : {}),
                ...(primaryBrandColor ? { borderColor: primaryBrandColor } : {}),
                '@media (hover: none)': {
                  ...(primaryBrandColor ? { backgroundColor: primaryBrandColor } : {}),
                },
              },
            },
          },
        },
      };
    }, [alternativeThemeStyles, hasSubThemeStyles, theme.breakpoints, themeStyles]);

    return (
      <SecondaryThemeProvider customTheme={customTheme}>
        <FontLoader themeStylesData={fontsThemeStyles} />
        <Component {...props} />
      </SecondaryThemeProvider>
    );
  };
}

withSecondaryTheme.propTypes = {
  data: PropTypes.object,
};

withSecondaryTheme.defaultProps = {
  data: null,
};

export default withSecondaryTheme;
