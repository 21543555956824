import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, split, includes, intersection } from 'lodash';
import { withStyles } from '@material-ui/core';
import CloudinaryImage from '../../../CloudinaryImage';

const styles = () => ({
  root: {},

  awards: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    padding: '8px 24px',
    '@media print': {
      paddingRight: 0,
    },
  },
  image: {
    margin: '8px 0px',
    '@media print': {
      height: 'auto',
      maxWidth: '40px',
    },
  },
});

function ProductLabels({ className, classes, productData, awardImagesData, awards, height, width, ...otherProps }) {
  let labelsData = get(productData, 'attributes.viinimaa_labels.value');

  if (isEmpty(labelsData)) {
    labelsData = get(productData, 'attributes.folk_o_folk_labels.value');
  }
  if (isEmpty(labelsData)) {
    return null;
  }
  let allAwards = [labelsData];
  if (includes(labelsData, ',')) {
    allAwards = split(labelsData, ',');
  }
  const hasAwards = intersection(allAwards, awards);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isEmpty(hasAwards) && (
        <div {...otherProps} className={clsx(classes.awards, className)}>
          <CloudinaryImage
            className={clsx(classes.image)}
            publicId={get(awardImagesData, '0.value')}
            transformation="ProductClassification-SM-SM"
            alt={get(awardImagesData, '0.key')}
            title={get(awardImagesData, '0.key')}
            width={width}
            height={height}
          />
        </div>
      )}
    </>
  );
}

ProductLabels.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
  awardImagesData: PropTypes.array,
  awards: PropTypes.array,
  height: PropTypes.string,
  width: PropTypes.string,
};

ProductLabels.defaultProps = {
  classes: {},
  className: null,
  productData: {},
  awardImagesData: {},
  awards: [],
  height: '80',
  width: '80',
};

export default withStyles(styles)(ProductLabels);
