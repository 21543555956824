import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Close } from '@material-ui/icons';
import { Dialog, DialogContent, Button, DialogTitle, Typography, withStyles } from '@material-ui/core';
import { get, isEmpty, isEqual, isNumber, includes, camelCase, startsWith, filter } from 'lodash';
import { useCookies } from 'react-cookie';

import { usePopUp } from '../../../context/PopUpContext';
import { getWindowLocationSearch, getWindowPathName } from '../../../../utils/windowUtils';
import { contentfulContentTypes } from '../../../../constants';
import { getUtmCampaignFromPath } from '../../../../utils/custobarUtils';

import Visible from '../../../shared/Visible';

const styles = theme => ({
  root: {},
  richText: {
    fontSize: '0.9rem !important',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem !important',
    },
  },
  closeButton: {
    padding: theme.spacing(1),
    minWidth: '44px',
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2, 2, 2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  title: {
    paddingRight: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(0, 3, 4, 3),
  },
});

const SUBSCRIBED = 'cb=';
const quizPage = 'quiz';

// eslint-disable-next-line react/prop-types
function PopUpModule({ classes, className, data, richText: RichText }) {
  const [cookies, setCookie] = useCookies(['ShowNewsLetterPopUpOnlyOnce']);

  const { isPopUpOpen, closePopUp, openPopUp, hasBeenClosed } = usePopUp();
  const [isClosed, setIsClosed] = useState(false);
  const [popUpHasBeenShowedCookie, setPopUpHasBeenShowedCookie] = useState('0');

  const globalPopUp = get(data, 'mainTheme.globalPopUp');
  const urlExclusions = get(globalPopUp, 'urlExclusions');
  const content = get(globalPopUp, 'content');
  const references = get(content, 'references');

  const path = getWindowPathName();
  const locationSearch = getWindowLocationSearch();
  const hasSubscribed = includes(locationSearch, SUBSCRIBED);
  const utmCampainID = getUtmCampaignFromPath();

  const isQuizPage = useMemo(() => includes(utmCampainID, quizPage), [utmCampainID]);

  const isNewsLetterPopUp = useMemo(() => {
    const containsCustobarSubscription = filter(references, reference => {
      if (isEqual(get(reference, 'internal.type'), contentfulContentTypes.FormModule)) {
        const formType = get(reference, 'formType.title');
        const type = camelCase(formType);
        if (startsWith(type, 'custobarSubscription')) {
          return true;
        }
      }
      return false;
    });
    return !isEmpty(containsCustobarSubscription);
  }, [references]);

  const disablePopup = useMemo(() => {
    return !isEmpty(
      filter(urlExclusions, url => {
        const _url = new URL(url);
        return includes(path, _url.pathname);
      }),
    );
  }, [path, urlExclusions]);

  const title = get(globalPopUp, 'title');
  const showOnlyOnce = get(globalPopUp, 'showOnlyOnce.0');
  const visibilityDelay = get(globalPopUp, 'visibilityDelay');

  useEffect(() => {
    if (isNewsLetterPopUp && hasSubscribed) {
      setCookie('ShowNewsLetterPopUpOnlyOnce', 1, { path: '/', maxAge: 31536000 });
      closePopUp('newsLetterPopUp', showOnlyOnce);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubscribed, isNewsLetterPopUp]);

  useEffect(() => {
    setIsClosed(hasBeenClosed);
    setPopUpHasBeenShowedCookie(cookies.ShowNewsLetterPopUpOnlyOnce);
  }, [hasBeenClosed, cookies.ShowNewsLetterPopUpOnlyOnce]);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        if (isEqual(showOnlyOnce, 'Yes')) {
          if (!isEqual(popUpHasBeenShowedCookie, '1')) {
            openPopUp('newsLetterPopUp');
          }
        } else if (!isClosed) {
          openPopUp('newsLetterPopUp');
        }
      },
      isNumber(visibilityDelay) ? visibilityDelay * 1000 : 0,
    );
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popUpHasBeenShowedCookie, isClosed, showOnlyOnce, visibilityDelay]);

  const isDialogOpen = isPopUpOpen('newsLetterPopUp');

  const closeDialog = useCallback(() => {
    if (isEqual(showOnlyOnce, 'Yes') && !isEqual(popUpHasBeenShowedCookie, '1')) {
      setCookie('ShowNewsLetterPopUpOnlyOnce', 1, { path: '/', maxAge: 31536000 });
    }
    if (!isEqual(showOnlyOnce, 'Yes') && !isEqual(popUpHasBeenShowedCookie, '0')) {
      setCookie('ShowNewsLetterPopUpOnlyOnce', 0, { path: '/', maxAge: 31536000 });
    }
    closePopUp('newsLetterPopUp', showOnlyOnce);
  }, [closePopUp, popUpHasBeenShowedCookie, setCookie, showOnlyOnce]);

  if (isNewsLetterPopUp && isQuizPage) {
    return null;
  }
  if (isEmpty(globalPopUp)) {
    return null;
  }
  if (isEqual(showOnlyOnce, 'Yes') && isEqual(popUpHasBeenShowedCookie, '1')) {
    return null;
  }
  if (isNewsLetterPopUp && hasSubscribed) {
    return null;
  }

  return (
    <Visible hidden={disablePopup}>
      <Dialog
        className={clsx(classes.root, className)}
        maxWidth="md"
        open={isDialogOpen}
        onClose={closeDialog}
        fullWidth
        scroll="body">
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
          <Button className={classes.closeButton} onClick={closeDialog}>
            <Close />
          </Button>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <RichText textData={content} className={classes.richText} />
        </DialogContent>
      </Dialog>
    </Visible>
  );
}

PopUpModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

PopUpModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(PopUpModule);
