import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaPreparationSectionModule from './viinimaa';
import SkagerrakPreparationSectionModule from './skagerrak';

function PreparationSectionModule(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaPreparationSectionModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaPreparationSectionModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Skagerrak)) {
    return <SkagerrakPreparationSectionModule {...props} />;
  }
  if (brandSite) {
    return <ViinimaaPreparationSectionModule {...props} />;
  }

  return null;
}

export default PreparationSectionModule;
