import { createTheme } from '@material-ui/core/styles';

import { secondaryThemeTypes } from '../../constants';

import primaryTheme, { colors } from './primaryTheme';

const anoraLightBlueTheme = createTheme(primaryTheme, {
  palette: {
    background: {
      default: colors.lightBlue,
      transparent: colors.lightBlueTransparent,
    },
  },
});

const anoraTransparentTheme = createTheme(primaryTheme, {
  palette: {
    background: {
      default: 'transparent',
    },
  },
});

const secondaryThemes = {
  [secondaryThemeTypes.AnoraLightBlue]: anoraLightBlueTheme,
  [secondaryThemeTypes.AnoraTransparent]: anoraTransparentTheme,
};

export default secondaryThemes;
