import React from 'react';
import PropTypes from 'prop-types';

import { Toolbar, withStyles } from '@material-ui/core';

import get from 'lodash/get';
import Logo from '../../../navigation/Logo';
import AppBar from '../Toolbar';

const styles = theme => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
  navbar: {
    justifyContent: 'center',
    minHeight: theme.palette.header.minHeight,
    borderBottom: 'none',
    backgroundColor: 'transparent',
    backgroundImage: ({ backgroundImage }) => {
      if (!backgroundImage) {
        return 'none';
      }
      return backgroundImage;
    },
    borderBottomWidth: '0',
    borderBottomColor: theme.palette.footer.border.color,
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.palette.header.paddingYDesktop,
      paddingRight: theme.palette.header.paddingYDesktop,
    },
  },

  logo: {
    padding: '0 24px 0 24px',
    marginBottom: '-24px',
  },
});

function Header({ classes, pageData }) {
  const header = get(pageData, 'mainTheme.header');

  if (header) {
    const headerElements = get(header, 'headerElements');
    const showLogo = !!headerElements.includes('Show logo');

    return (
      <div>
        <AppBar className={classes.root} pageData={pageData} position="absolute">
          <Toolbar className={classes.navbar}>
            {showLogo && <Logo pageData={pageData} className={classes.logo} width="160" />}
          </Toolbar>
        </AppBar>
      </div>
    );
  }

  return null;
}

Header.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
};

Header.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  backgroundColor: null,
  backgroundImage: 'none',
};

export default withStyles(styles)(Header);
