import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

import get from 'lodash/get';
import includes from 'lodash/includes';

import { getWindowPathName, isSSR } from '../../../../utils/windowUtils';
import { pageBrowseEvent } from '../../../../utils/custobarUtils';
import { contentfulContentTypes } from '../../../../constants';
import { viewCampaign } from '../../../../utils/analyticUtils';

function PageBrowseTracker({ pageData }) {
  const [cookies] = useCookies(['cb']);
  if (isSSR()) {
    return null;
  }

  const pageType = get(pageData, 'internal.type');
  const pathName = getWindowPathName();
  const token = get(window, 'cstbr.customerIdentification.customer_token', false);
  const cbCustomerToken = cookies.cb ? cookies.cb.token : false;

  if (token && cbCustomerToken && !includes(pathName, 'utm_medium')) {
    pageBrowseEvent(pathName, pageData);
  }

  if (pageType === contentfulContentTypes.CampaignPage) {
    viewCampaign(pageData);
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

PageBrowseTracker.propTypes = {
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

PageBrowseTracker.defaultProps = {
  pageData: null,
};

export default memo(PageBrowseTracker);
