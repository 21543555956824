import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, split, last, drop, join } from 'lodash';
import { withStyles } from '@material-ui/core';

import ListElementCard from '../../../shared/ListElementCard';

const styles = () => ({
  root: {},
});

const getPublicId = url => {
  const publicId = join(drop(split(last(split(url, ',')), '/')), '/');
  return publicId;
};

function SearchResultListElement({ classes, className, data, ...otherProps }) {
  const fullPath = get(data, 'url');
  const categories = get(data, 'categories_', null);
  const pageType = get(data, '__typename');
  const description = get(data, 'shortDesc');
  const imageURL = get(data, 'image');

  let title = get(data, 'name');
  if (isEmpty(title)) {
    title = get(data, 'menuTitle');
  }

  const imageData = {
    cloudinaryImage: [{ public_id: getPublicId(imageURL) }],
    imageAltText: title,
    imageTitleText: title,
  };

  return (
    <ListElementCard
      {...otherProps}
      className={clsx(classes.root, className)}
      image={imageData}
      title={title}
      categories={categories}
      description={description}
      fullPath={fullPath}
      pageType={pageType}
      elementsPerRowDesktop={3}
      elementsPerRowTablet={2}
      elementsPerRowMobile={1}
    />
  );
}

SearchResultListElement.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  slider: PropTypes.bool,
};

SearchResultListElement.defaultProps = {
  classes: {},
  className: null,
  data: null,
  slider: false,
};

export default withStyles(styles)(SearchResultListElement);
