import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import FolkofolkShareButtons from './folkofolk';

function ShareButtons(props) {
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkShareButtons {...props} />;
  }

  return null;
}

export default ShareButtons;
