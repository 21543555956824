import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Menu } from '@material-ui/icons';

import { IconButton, withStyles } from '@material-ui/core';
import { useDrawer } from '../../../context/DrawerContext';

const styles = () => ({
  root: {
    border: '2px solid #611717',
    borderRadius: '0',
  },
  icon: {
    fontSize: '1.5rem',
    color: '#611717',
  },
});

function HamburgerMenu({ classes, className }) {
  const { openDrawer } = useDrawer();
  const handleMenuClick = useCallback(() => {
    openDrawer('MobileNavigationDrawer');
  }, [openDrawer]);

  return (
    <IconButton
      edge="end"
      aria-label="Menu"
      color="inherit"
      className={clsx(classes.root, className)}
      onClick={handleMenuClick}>
      <Menu className={classes.icon} />
    </IconButton>
  );
}

HamburgerMenu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  breakpoint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

HamburgerMenu.defaultProps = {
  classes: {},
  className: null,
  breakpoint: null,
};

export default withStyles(styles)(HamburgerMenu);
