import { get, isEmpty, split, padEnd } from 'lodash';

export function convertToPrice(price) {
  if (!price) {
    return null;
  }
  let price1 = get(split(price, '.', 2), 0, '');
  let price2 = get(split(price, '.', 2), 1, '');
  price1 = isEmpty(price1) ? '0' : price1;
  price2 = padEnd(price2.substring(0, 2), 2, '00');
  return `${price1},${price2}`;
}
