import React from 'react';
import PropTypes from 'prop-types';

import { isEqual } from 'lodash';
import { Slide, useScrollTrigger } from '@material-ui/core';

function HiddenOnScroll({ children }) {
  const isSSR = isEqual(typeof window, 'undefined');
  const target = isSSR ? undefined : window;
  const trigger = useScrollTrigger({ target });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HiddenOnScroll.propTypes = {
  children: PropTypes.node,
};
HiddenOnScroll.defaultProps = {
  children: null,
};

export default HiddenOnScroll;
