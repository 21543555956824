import React from 'react';

import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { siteIds, contentfulContentTypes } from '../../../constants';
import { siteId, brandSite } from '../../../sites';
import ViinimaaSiteNotifications from './viinimaa';

const styles = () => ({
  contaiers: {
    marginTop: '120px',
  },
});

function SiteNotifications({ classes, ...props }) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaSiteNotifications {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaSiteNotifications {...props} />;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    const pageType = get(props, 'pageData.internal.type');
    if (isEqual(pageType, contentfulContentTypes.LandingPage)) {
      return <ViinimaaSiteNotifications classes={{ contaier: classes.contaiers }} {...props} />;
    }
    return null;
  }
  if (brandSite) {
    return <ViinimaaSiteNotifications {...props} />;
  }

  return null;
}

SiteNotifications.propTypes = {
  classes: PropTypes.object,
};

SiteNotifications.defaultProps = {
  classes: {},
};

export default withStyles(styles)(SiteNotifications);
