import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { withStyles } from '@material-ui/core';

import CloudinaryImage from '../../../shared/CloudinaryImage';

const styles = () => ({
  root: {
    height: 'auto',
  },
});

function HeroImage({ classes, className, data, height }) {
  const altText = get(data, 'imageAltText');
  const titleText = get(data, 'imageTitleText');
  const publicId = get(data, 'cloudinaryImage.0.public_id');

  const variants = useMemo(
    () => [
      {
        transformation: 'HeroFullWidthImage-SM-SM',
        width: 768,
      },
      {
        transformation: 'HeroFullWidthImage-MD-MD',
        width: 1024,
      },
      {
        transformation: 'HeroFullWidthImage-MD-LG',
        width: 1024,
      },
      {
        transformation: 'HeroFullWidthImage-LG-LG',
        width: 1440,
      },
      {
        transformation: 'HeroFullWidthImage-XL-LG',
        width: 2048,
      },
      {
        transformation: '',
        width: 3000,
        parameters: {
          c: 'fill',
          h: height,
        },
      },
    ],
    [height],
  );

  return (
    <CloudinaryImage
      className={clsx(classes.root, className)}
      publicId={publicId}
      variants={variants}
      alt={altText}
      title={titleText}
      width="100%"
      height={height}
      element="picture"
    />
  );
}

HeroImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  height: PropTypes.string,
};

HeroImage.defaultProps = {
  classes: {},
  className: null,
  data: null,
  height: '900',
};

export default withStyles(styles)(HeroImage);
