import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, isEqual } from 'lodash';
import { withStyles } from '@material-ui/core';

import Link from '../../Link';
import CloudinaryImage from '../../../shared/CloudinaryImage';

import { settings } from '../../../../sites';

const styles = theme => ({
  root: {
    display: 'block',
    paddingRight: theme.spacing(2),
  },
  image: {
    height: 'auto',
  },
  link: {},
});

function ViinimaaLogo({ classes, className, onLogoClick, pageData, width, imgSrc }) {
  const languageCode = get(pageData, 'languageCode', '');
  const defaultLanguage = settings.localization.defaultLanguageCode;

  const fullPath =
    get(pageData, 'breadcrumbs.0.fullPath') || `/${defaultLanguage !== languageCode ? languageCode : ''}`;

  let logoImage = get(pageData, 'mainTheme.themeStyles.logo');
  const alternativeThemeLogoImage = get(pageData, 'alternativeTheme.themeStyles.logo');
  const alternativeThemeType = get(pageData, 'alternativeTheme.themeType.0');
  const isSubTheme = isEqual(alternativeThemeType, 'Sub-theme');

  if (!isEmpty(alternativeThemeLogoImage) && isSubTheme) {
    logoImage = alternativeThemeLogoImage;
  }

  const cloudinaryImage = get(logoImage, ['cloudinaryImage', 0]);

  const originalHeight = get(cloudinaryImage, 'height', 0);
  const originalWidth = get(cloudinaryImage, 'width', 0);

  const relatedHeight = Math.ceil((originalHeight / originalWidth) * width);

  const logoPublicID = get(logoImage, 'cloudinaryImage.0.public_id');
  const imageFormat = get(logoImage, 'cloudinaryImage.0.format');
  const altText = get(logoImage, 'imageAltText', 'Logo image');
  const titleText = get(logoImage, 'imageTitleText', 'Logo image');
  const openLinkTo = get(logoImage, 'openLinkTo');
  const handleLogoClick = useCallback(() => {
    onLogoClick();
  }, [onLogoClick]);

  const isCloudinaryImage = logoPublicID && isEmpty(imgSrc);
  return (
    <div className={clsx(classes.root, className)}>
      <Link to={fullPath} onClick={handleLogoClick} className={classes.link} target={!openLinkTo ? '_blank' : ''}>
        {isCloudinaryImage && (
          <CloudinaryImage
            publicId={logoPublicID}
            variants={[{ width }]}
            alt={altText}
            title={titleText}
            width={width}
            height={relatedHeight}
            format={isEqual(imageFormat, 'svg') ? 'svg' : 'auto'}
            className={classes.image}
            gravity=""
          />
        )}
        {imgSrc && <img src={imgSrc} alt={altText} title={titleText} width={width} height={relatedHeight} />}
      </Link>
    </div>
  );
}

ViinimaaLogo.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.string,
  onLogoClick: PropTypes.func,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  imgSrc: PropTypes.string,
};

ViinimaaLogo.defaultProps = {
  classes: {},
  className: null,
  width: '136',
  onLogoClick: () => {},
  pageData: null,
  imgSrc: null,
};

export default withStyles(styles)(ViinimaaLogo);
