import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaB2BLink from './viinimaa';

function B2BLink(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaB2BLink {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaB2BLink {...props} />;
  }

  return null;
}

export default B2BLink;
