import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { withStyles, Typography } from '@material-ui/core';
import Event from './Event';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    marginBottom: '40px',
  },
});

function EventCalendarModule({ classes, className, data, richText: RichText }) {
  const title = get(data, 'title');
  const events = get(data, 'events');

  if (isEmpty(Event)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Typography variant="h2" align="center" className={classes.title}>
        {title}
      </Typography>
      {map(events, event => {
        const id = get(event, 'id');
        return <Event data={event} key={id} richText={RichText} />;
      })}
    </div>
  );
}

EventCalendarModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.object,
};

EventCalendarModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
};

export default withStyles(styles)(EventCalendarModule);
