import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function calculateSize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // if (typeof window !== `undefined`) {
    calculateSize();
    const handleWindowResize = debounce(calculateSize, 500);

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
    // }
  }, []);
  return windowSize;
}
