import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import join from 'lodash/join';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { withStyles } from '@material-ui/core';

import ContentColumns from '../../ContentColumns';
import BackgroundImage from '../../../shared/BackgroundImage';

import { calcWidthWithoutMargin } from '../../ContentColumns/viinimaa/ContentColumn';

const styles = theme => ({
  root: {
    zIndex: '1',
    position: 'relative',
    overflow: 'hidden',
    maxWidth: ({ rowData }) => {
      const marginMobile = get(rowData, 'customRowStyle.marginMobile');
      return calcWidthWithoutMargin(marginMobile);
    },
    backgroundColor: ({ rowData }) => {
      const backgroundImage = get(rowData, 'backgroundImage');
      const backgroundColor = get(rowData, 'backgroundColor');
      const customRowStyle = get(rowData, 'customRowStyle');
      const customRowStyleBackgroundColor = get(customRowStyle, 'backgroundColor');
      if (!isNil(backgroundImage)) {
        return 'transparent';
      }
      if (!isNil(backgroundColor)) {
        return backgroundColor;
      }
      if (isNil(backgroundColor) && !isNil(customRowStyleBackgroundColor)) {
        return customRowStyleBackgroundColor;
      }
      return theme.palette.background.defaultRow;
    },
    padding: ({ rowData }) => {
      const mobilePaddingTop = get(rowData, 'customRowStyle.mobilePaddingTop');
      const mobilePaddingRight = get(rowData, 'customRowStyle.mobilePaddingRight');
      const mobilePaddingBottom = get(rowData, 'customRowStyle.mobilePaddingBottom');
      const mobilePaddingLeft = get(rowData, 'customRowStyle.mobilePaddingLeft');
      return `${mobilePaddingTop || '0'} ${mobilePaddingRight || '0'} ${mobilePaddingBottom || '0'} ${
        mobilePaddingLeft || '0'
      }`;
    },

    margin: ({ rowData }) => {
      const marginMobile = get(rowData, 'customRowStyle.marginMobile');
      if (!isEmpty(marginMobile)) {
        const margins = join(marginMobile, ' ');
        return margins;
      }
      return '0';
    },

    [theme.breakpoints.up('md')]: {
      padding: ({ rowData }) => {
        const desktopPaddingTop = get(rowData, 'customRowStyle.desktopPaddingTop');
        const desktopPaddingRight = get(rowData, 'customRowStyle.desktopPaddingRight');
        const desktopPaddingBottom = get(rowData, 'customRowStyle.desktopPaddingBottom');
        const desktopPaddingLeft = get(rowData, 'customRowStyle.desktopPaddingLeft');

        return `${desktopPaddingTop || '0'} ${desktopPaddingRight || '0'} ${desktopPaddingBottom || '0'} ${
          desktopPaddingLeft || '0'
        }`;
      },

      margin: ({ rowData }) => {
        const marginDesktop = get(rowData, 'customRowStyle.marginDesktop');
        if (!isEmpty(marginDesktop)) {
          const margins = join(marginDesktop, ' ');
          return margins;
        }
        return '0';
      },
      maxWidth: ({ rowData }) => {
        const marginMobile = get(rowData, 'customRowStyle.marginDesktop');
        return calcWidthWithoutMargin(marginMobile);
      },
    },
  },
});

function ContentRow({ classes, className, pageData, rowData }) {
  const rowIdentifier = get(rowData, 'identifier');

  const altText = get(rowData, 'backgroundImage.imageAltText');
  const publicId = get(rowData, 'backgroundImage.cloudinaryImage.0.public_id');

  return (
    <div className={clsx(classes.root, className)} id={rowIdentifier}>
      <ContentColumns pageData={pageData} rowData={rowData} />
      <BackgroundImage altText={altText} publicId={publicId} />
    </div>
  );
}

ContentRow.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rowData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ContentRow.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  rowData: null,
};

export default withStyles(styles)(ContentRow);
