import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

// eslint-disable-next-line import/no-cycle
import CommonOrderFormModule from './common';

function OrderFormModule(props) {
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <CommonOrderFormModule {...props} />;
  }

  return null;
}

export default OrderFormModule;
