/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PlayArrow from '@material-ui/icons/PlayArrow';

import { withStyles } from '@material-ui/core';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: '3',
  },
  palyButton: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    width: '100px',
    backgroundColor: 'white',
    borderRadius: '50%',

    position: 'absolute',
    left: '50%',
    top: '45%',
    transform: 'translate(-50%, -50%)',
  },
  playArrow: {
    color: '#2e2e2e',
    fontSize: '50px',
  },
});

function PlayButton({ classes, className, videoRef, playOnLoad }) {
  const [visible, setVisible] = useState(playOnLoad);

  const handleClick = () => {
    const isPlaying =
      videoRef.current.videoRef.current.currentTime > 0 &&
      !videoRef.current.videoRef.current.paused &&
      !videoRef.current.videoRef.current.ended &&
      videoRef.current.videoRef.current.readyState > videoRef.current.videoRef.current.HAVE_CURRENT_DATA;
    if (isPlaying) {
      videoRef.current.videoRef.current.pause();
    } else {
      videoRef.current.videoRef.current.play();
    }
    setVisible(prev => !prev);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={clsx(classes.root, className)} onClick={handleClick}>
      {!visible && (
        <div className={classes.palyButton}>
          <PlayArrow fontSize="large" color="primary" className={classes.playArrow} />
        </div>
      )}
    </div>
  );
}

PlayButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  videoRef: PropTypes.object,
  playOnLoad: PropTypes.bool,
};

PlayButton.defaultProps = {
  classes: {},
  className: null,
  videoRef: false,
  playOnLoad: true,
};

export default withStyles(styles)(PlayButton);
