import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Toolbar, withStyles } from '@material-ui/core';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import HamburgerMenu from '../../../navigation/HumburgerMenu';

import { viinimaaNavigationBreakpoint, siteMaintenanceMode } from '../../../../constants';

import TopNavigation from '../../../navigation/TopNavigation';
import Logo from '../../../navigation/Logo';
import AppBar from '../Toolbar';
import MobileNavigation from '../../../navigation/MobileNavigation';
import Subscription from '../../../navigation/Subscription';
import LanguageSwitcher from '../../../navigation/LanguageSwitcher';
import RichText from '../../../shared/RichText';

let navigationBreakpoint = viinimaaNavigationBreakpoint;

const styles = theme => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
  navbar: {
    ...theme.palette.header.backgroundGradient,
    zIndex: '1',
    minHeight: theme.palette.header.minHeight,
    background: theme.palette.header.background,
    paddingLeft: theme.palette.header.paddingYMobile,
    paddingRight: theme.palette.header.paddingYMobile,
    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      paddingLeft: theme.palette.header.paddingYDesktop,
      paddingRight: theme.palette.header.paddingYDesktop,
    },
    '@media print': {
      borderBottom: 'none',
    },
  },
  logo: {
    display: 'flex',
    padding: '8px 16px 8px 0',
    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      display: 'none',
    },
  },
  navigation: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      display: ({ mobileMood }) => {
        return mobileMood ? 'none' : 'flex';
      },
    },
    '@media print': {
      display: 'none',
    },
  },
  linkswrapper: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
  },
  languageSwitcher: {
    display: 'none',
    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      display: ({ showLanguageSwitcher }) => {
        if (showLanguageSwitcher) {
          return 'flex';
        }
        return 'none';
      },
    },
    '@media print': {
      display: 'none',
    },
  },
  rightSide: {
    display: 'flex',
    paddingRight: '16px',
    '@media print': {
      display: 'none',
    },
  },
  subscriptionIcon: {
    display: 'none',
    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      display: 'inline',
    },
  },

  hamburgerMenu: {
    color: theme.palette.common.white,
    height: '48px',
    padding: '10px',
    width: '48px',
    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      display: ({ mobileMood }) => {
        return mobileMood ? 'inline' : 'none';
      },
    },
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '2rem',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(2),
    '& p': {
      marginBottom: 0,
    },
  },

  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto max-content min-content min-content',
    borderBottom: theme.palette.header.border.style,
    borderBottomColor: theme.palette.footer.border.color,

    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      gridTemplateColumns: '10% 80% 10%',
    },

    '@media print': {
      borderBottom: 'none',
    },
  },
});

function Header({ classes, pageData, backgroundColor }) {
  const header = get(pageData, 'mainTheme.header');

  if (header) {
    const maintenanceMode = get(pageData, 'mainTheme.maintenanceMode');
    const isMaintenanceModeActive = isEqual(maintenanceMode, siteMaintenanceMode.ActiveMode);
    const headerElements = get(header, 'headerElements');
    const showLogo = !!headerElements.includes('Show logo');
    const showNavigation = !!includes(headerElements, 'Show navigation');
    const showNewsletterSubscription = !!headerElements.includes('Show newsletter subscription');
    const headerContent = get(header, 'headerContent');

    return (
      <div>
        <AppBar className={classes.root} pageData={pageData} color={backgroundColor}>
          <Toolbar className={clsx(classes.navbar, classes.gridContainer)}>
            <div>{showLogo && <Logo pageData={pageData} className={classes.logo} />}</div>
            {!isMaintenanceModeActive && showNavigation && (
              <TopNavigation pageData={pageData} className={classes.navigation} />
            )}

            <div className={classes.linkswrapper}>
              <LanguageSwitcher className={classes.languageSwitcher} pageData={pageData} />
              {!isMaintenanceModeActive && showNewsletterSubscription && (
                <Subscription className={classes.subscriptionIcon} />
              )}

              {headerContent && <RichText pageData={pageData} textData={headerContent} className={classes.content} />}
            </div>

            <div className={classes.hamburgerMenuWrapper}>
              {showNavigation && <HamburgerMenu className={classes.hamburgerMenu} />}
            </div>
          </Toolbar>
        </AppBar>
        {showNavigation && <MobileNavigation pageData={pageData} />}
      </div>
    );
  }

  return null;
}

Header.propTypes = {
  backgroundColor: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showLanguageSwitcher: PropTypes.bool,
  mobileMood: PropTypes.bool,
};

Header.defaultProps = {
  backgroundColor: 'transparent',
  classes: {},
  className: null,
  pageData: null,
  showLanguageSwitcher: false,
  mobileMood: false,
};

export default withStyles(styles)(Header);
