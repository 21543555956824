import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { Drawer, IconButton, Toolbar, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { siteMaintenanceMode } from '../../../../constants';
import { useDrawer } from '../../../context/DrawerContext';
import RichText from '../../../shared/RichText';

import Logo from '../../Logo';
import NavigationElements from '../../NavigationElements';
import LanguageSwitcher from '../../LanguageSwitcher';
import KoskenkorvaLogoWhite from '../../../../images/koskenkorva/Koskenkorva_logo_white.png';

const styles = theme => ({
  root: {},
  toolbar: {
    padding: '12px 12px 12px 24px',
    justifyContent: 'flex-start',
    minHeight: '90px',
  },
  logoLink: { display: 'flex' },
  iconButton: {
    padding: '14px',
    position: 'absolute',
    backgroundColor: theme.palette.mobileNavigation.xIconBackground,
    top: 0,
    right: 0,
    margin: '24px',
    '&:hover': {
      backgroundColor: theme.palette.mobileNavigation.xIconBackgroundHover,
    },
  },
  icon: {
    fontSize: '1.2rem',
    color: 'white',
  },
  subNavigationTitleBar: {
    textTransform: 'uppercase',
  },
  subNavigationTitle: {
    fontFamily: 'HeroicCondensed-Bold',
    fontSize: '2.5rem',
    fontWeight: 'bold',
  },

  content: {
    overflow: 'auto',
    justifyContent: 'center',
    paddingTop: '30px',
    paddingBottom: '88px',
  },
  navElement: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  listItem: {
    borderBottom: 'none',
    marginTop: theme.spacing(2),
  },
  listItemBottom: {
    marginLeft: '24px',
    width: '32px',
    height: '3px',
    backgroundColor: theme.palette.mobileNavigation.navigationElementBorder,
  },
  listItemTextRight: {
    textAlign: 'left',
    padding: '0',
  },
  link: {
    fontFamily: 'HeroicCondensed-Bold',
    padding: '12px 12px 3px 24px',
    fontSize: '1.75rem',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.header.navigation.activeLink,
    },
  },

  iconWrapper: {
    marginTop: '10px',
  },
  BackIconWrapper: {
    marginTop: '20px',
  },
  label: {
    fontFamily: 'HeroicCondensed-Bold',
    fontSize: '1.75rem',
    fontWeight: 'bold',
    color: theme.palette.mobileNavigation.text,
  },
  languageSwitcher: {
    color: theme.palette.mobileNavigation.text,
  },
  spacing: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
  },
  socialMedia: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    backgroundColor: theme.palette.mobileNavigation.subNavigationTitleBackground,
    color: 'white',
    padding: theme.spacing(2),
    position: 'absolute',
    bottom: '0',
    width: '100%',

    '& p': {
      marginBottom: 0,
    },
  },
});

function MobileNavigation({ classes, className, pageData }) {
  const { closeDrawer, isDrawerOpen } = useDrawer();
  const drawerOpen = isDrawerOpen('MobileNavigationDrawer');

  const maintenanceMode = get(pageData, 'mainTheme.maintenanceMode');
  const isMaintenanceModeActive = isEqual(maintenanceMode, siteMaintenanceMode.ActiveMode);

  const header = get(pageData, 'mainTheme.header');
  const headerContent = get(header, 'headerContent');

  const navigationElements = useMemo(
    () => get(pageData, 'mainTheme.navigation.flattenedNavigationElements'),
    [pageData],
  );

  const handleNavigationElementClick = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const handleLogoClick = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  return (
    <Drawer anchor="right" className={clsx(classes.root, className)} open={drawerOpen} onClose={closeDrawer}>
      <Toolbar className={classes.toolbar}>
        <Logo
          width="200"
          onLogoClick={handleLogoClick}
          classes={{ link: classes.logoLink }}
          pageData={pageData}
          imgSrc={KoskenkorvaLogoWhite}
        />
        <IconButton aria-label="Close" className={classes.iconButton} onClick={closeDrawer} v>
          <Close className={classes.icon} />
        </IconButton>
      </Toolbar>
      <div className={classes.content}>
        {!isMaintenanceModeActive && (
          <NavigationElements
            data={navigationElements}
            onNavigationElementClick={handleNavigationElementClick}
            classes={{
              listItem: classes.listItem,
              listItemBottom: classes.listItemBottom,
              root: classes.navElement,
              listItemTextRight: classes.listItemTextRight,
              link: classes.link,
              iconWrapper: classes.iconWrapper,
              label: classes.label,
              BackIconWrapper: classes.BackIconWrapper,
              subNavigationTitleBar: classes.subNavigationTitleBar,
              subNavigationTitle: classes.subNavigationTitle,
            }}
          />
        )}
        <LanguageSwitcher
          pageData={pageData}
          className={clsx(classes.languageSwitcher, classes.spacing)}
          classes={{ link: classes.languageSwitcher }}
        />
      </div>
      {headerContent && <RichText pageData={pageData} textData={headerContent} className={classes.socialMedia} />}
    </Drawer>
  );
}

MobileNavigation.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.object,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

MobileNavigation.defaultProps = {
  classes: {},
  className: {},
  pageData: null,
};

export default withStyles(styles)(MobileNavigation);
