import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';

import { Link, Typography, Divider, withStyles } from '@material-ui/core';

import ThumbnailImage from '../../../shared/ThumbnailImage';

function encodePhoneNumber(phoneNumber) {
  return encodeURIComponent(replace(phoneNumber, /\s/g, ''));
}

const styles = theme => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '50%',
    flexBasis: '50%',
    wordBreak: 'break-word',
    hyphens: 'auto',

    padding: theme.spacing(3, 2),
    [theme.breakpoints.up('sm')]: {
      maxWidth: '33.33%',
      flexBasis: '33.33%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '25%',
      flexBasis: '25%',
    },
  },

  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  text: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 0),
    },
  },
  name: {
    marginBottom: '10px',
    textTransform: 'uppercase',
    fontWeight: '600',
  },

  role: {
    fontSize: '12px',
    marginBottom: '10px',
    color: '#9D5812',
    textTransform: 'uppercase',
    fontWeight: '700',
  },
  divider: { marginBottom: '16px', width: '100%', height: '2px', backgroundColor: '#C7A17A' },

  description: {
    marginBottom: '16px',
  },
  contact: {
    marginBottom: '8px',
    color: '#9D5812',
  },
});

function ContactPerson({ classes, className, data }) {
  const name = get(data, 'name');
  const role = get(data, 'role');
  const emailAddress = get(data, 'emailAddress');
  const phoneNumber = get(data, 'phoneNumber');
  const description = get(data, 'description.description');
  const encodedPhoneNumber = useMemo(() => encodePhoneNumber(phoneNumber), [phoneNumber]);

  return (
    <article className={clsx(classes.root, className)}>
      <div className={classes.imageContainer}>
        <ThumbnailImage data={data} transformation="ContactPersonImage-MD-MD" width="auto" height="auto" circle />
      </div>
      <div className={classes.text}>
        <Typography variant="body1" align="left" className={classes.name}>
          {name}
        </Typography>
        <Typography variant="body1" align="left" className={classes.role}>
          {role}
        </Typography>
        <Divider className={classes.divider} />
        {!isEmpty(description) && (
          <Typography variant="body1" align="left" className={classes.description}>
            {description}
          </Typography>
        )}
        {!isEmpty(encodedPhoneNumber) && (
          <Link href={`tel:${encodedPhoneNumber}`} className={classes.contact}>
            {phoneNumber}
          </Link>
        )}
        {!isEmpty(emailAddress) && (
          <Link href={`mailto:${emailAddress}`} className={classes.contact}>
            {emailAddress}
          </Link>
        )}
      </div>
    </article>
  );
}

ContactPerson.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

ContactPerson.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(ContactPerson);
