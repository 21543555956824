import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import CardWithText from '../CardWithText';
import CardWithImageOnly from '../CardWithImageOnly';

const styles = {
  root: {},
  header: {},
  imageWrapper: {},
  imageFull: {},
  title: {},
  imagesOnlyTitle: {},
  listItem: {},
  listItemText: {},
  image: {},
  imageTitle: {},
  divider: {},
  dividerSmall: {},
  tags: {},
  shortDescription: {},
  linkButton: {},
};

function ContentListElement({ hideText, ...otherProps }) {
  if (hideText) {
    return <CardWithImageOnly {...otherProps} />;
  }

  return <CardWithText {...otherProps} />;
}
ContentListElement.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  hideText: PropTypes.bool,
};

ContentListElement.defaultProps = {
  classes: {},
  className: null,
  hideText: false,
};

export default withStyles(styles)(ContentListElement);
