import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { AppBar, withStyles } from '@material-ui/core';

import HiddenOnScroll from '../../shared/HiddenOnScroll';
import PopUpModule from '../../module/PopUpModule';
import RichText from '../../shared/RichText';

const styles = theme => ({
  root: {
    backgroundColor: 'transparent',
    zIndex: '2000',

    '@media print': {
      backgroundColor: 'transparent',
    },
  },
  appBar: {
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.drawer + 1,
    '@media print': {
      backgroundColor: 'transparent',
      transform: 'none !important',
      visibility: 'visible !important',
    },
  },
});

function Toolbar({ classes, className, children, pageData, color, ...otherProps }) {
  return (
    <div className={clsx(classes.root, className)}>
      <HiddenOnScroll>
        <AppBar component="header" position="fixed" className={classes.appBar} color={color} {...otherProps}>
          <div>{children}</div>
        </AppBar>
      </HiddenOnScroll>
      <PopUpModule data={pageData} richText={RichText} />
    </div>
  );
}

Toolbar.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Toolbar.defaultProps = {
  classes: {},
  className: null,
  children: null,
  color: 'primary',
  pageData: null,
};

export default withStyles(styles)(Toolbar);
