import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Header from '../skagerrak';

const styles = theme => ({
  content: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  hyperlink: {
    color: '#2e0a2b',
    fontWeight: 'bold',
    padding: '8px 24px',
  },
  hamburgerMenu: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navigation: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  socialMedia: {},
  emptyDiv: {
    display: 'none',
  },
});

function WineworldFinlandHeader({ classes, ...otherProps }) {
  return (
    <Header
      classes={{
        hyperlink: classes.hyperlink,
        socialMedia: classes.socialMedia,
        emptyDiv: classes.emptyDiv,
        hamburgerMenu: classes.hamburgerMenu,
        content: classes.content,
        navigation: classes.navigation,
      }}
      {...otherProps}
    />
  );
}

WineworldFinlandHeader.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
};

WineworldFinlandHeader.defaultProps = {
  classes: {},
  className: null,
};

export default withStyles(styles)(WineworldFinlandHeader);
