import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map, concat, compact, isEmpty, isEqual, filter } from 'lodash';
import { withStyles } from '@material-ui/core';

import Button from '../../../Button';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '@media print': {
      marginLeft: '-8px',
    },
  },
  categoryButton: {
    backgroundColor: ({ variant }) => {
      if (isEqual(variant, 'page')) {
        return theme.palette.common.white;
      }
      return theme.palette.category.outlined.background;
    },
    '@media print': {
      backgroundColor: 'transparent',
      color: 'black',
      marginRight: 0,
      '&:after': {
        display: 'inline-block',
        content: '/',
        width: '30px',
        height: '40px',
      },
    },
    color: theme.palette.category.outlined.text,
    fontSize: '12px',
    marginRight: theme.spacing(2),
    marginBottom: '4px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    background: 'none',
    padding: theme.spacing(1),
    border: '0px',
    '&:hover': {
      color: theme.palette.category.hoverText,
      border: '0px',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
});

function filterCategories(categories) {
  return filter(categories, category => {
    return !isEmpty(get(category, 'fullPath'));
  });
}

function ProductCategories({ classes, className, productData, variant }) {
  const categories = useMemo(() => {
    const productCategories = filterCategories(get(productData, 'categories'));
    const countryCategories = filterCategories(get(productData, 'countryCategories'));
    if (isEqual(variant, 'page')) {
      return compact(concat([], countryCategories, productCategories));
    }
    return compact(productCategories);
  }, [productData, variant]);

  if (isEmpty(categories)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      {map(categories, (category, index) => {
        const categoryId = get(category, 'id');
        const categoryKey = `${categoryId}-${index}`;
        const title = get(category, 'title');
        const fullPath = get(category, 'fullPath');

        return (
          <Button key={categoryKey} to={fullPath} size="small" className={classes.categoryButton}>
            {title}
          </Button>
        );
      })}
    </div>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['page', 'card']),
  productData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ProductCategories.defaultProps = {
  classes: {},
  className: null,
  variant: 'page',
  productData: null,
};

export default withStyles(styles)(ProductCategories);
