import { Settings } from 'luxon';

import { useEffect } from 'react';
import { useLocation } from '@reach/router';

import { getLanguageFromPathname } from '../../utils/langUtils';

const useInitLuxon = () => {
  const { pathname } = useLocation();
  const languageCode = getLanguageFromPathname(pathname);

  useEffect(() => {
    Settings.defaultLocale = languageCode;
  }, [languageCode]);
};

export default useInitLuxon;
