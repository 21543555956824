import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, replace } from 'lodash';
import { Helmet } from 'react-helmet';

function AnalyticsModule({ data }) {
  const headSection = get(data, '0.headSection.headSection');
  const bodySection = get(data, '0.bodySection.bodySection');
  const _script = replace(replace(headSection, '<script>', ''), '</script>', '');

  if (isEmpty(data)) {
    return null;
  }

  return (
    <>
      {!isEmpty(headSection) && (
        <Helmet>
          <script>{_script}</script>
        </Helmet>
      )}
      {!isEmpty(bodySection) && <div dangerouslySetInnerHTML={{ __html: bodySection }} />}
    </>
  );
}

AnalyticsModule.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

AnalyticsModule.defaultProps = {
  data: null,
};

export default AnalyticsModule;
